export const CopyLinkIcon = ({ size = 16, color = "#3375F5", ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.99341 11.6666H11.0001C13.0134 11.6666 14.6667 10.0199 14.6667 7.99992C14.6667 5.98659 13.0201 4.33325 11.0001 4.33325H9.99341"
        stroke={color}
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.99992 4.33325H4.99992C2.97992 4.33325 1.33325 5.97992 1.33325 7.99992C1.33325 10.0133 2.97992 11.6666 4.99992 11.6666H5.99992"
        stroke={color}
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.33325 8H10.6666"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const DoubleBoxIcon = ({ size = 14, color = "#3375F5", ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.6084 7.875H2.39175C1.51675 7.875 1.16675 8.24833 1.16675 9.17583V11.5325C1.16675 12.46 1.51675 12.8333 2.39175 12.8333H11.6084C12.4834 12.8333 12.8334 12.46 12.8334 11.5325V9.17583C12.8334 8.24833 12.4834 7.875 11.6084 7.875Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.52508 1.16675H2.39175C1.51675 1.16675 1.16675 1.54008 1.16675 2.46758V4.82425C1.16675 5.75175 1.51675 6.12508 2.39175 6.12508H7.52508C8.40008 6.12508 8.75008 5.75175 8.75008 4.82425V2.46758C8.75008 1.54008 8.40008 1.16675 7.52508 1.16675Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const TrashIcon = ({ size = 22, color = "#96A0AA", ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Frame 2383">
        <g id="Vector">
          <path
            d="M19 5.53819C16.04 5.24486 13.0622 5.09375 10.0933 5.09375C8.33333 5.09375 6.57333 5.18264 4.81333 5.36042L3 5.53819"
            fill={color}
          />
          <path
            d="M19 5.53819C16.04 5.24486 13.0622 5.09375 10.0933 5.09375C8.33333 5.09375 6.57333 5.18264 4.81333 5.36042L3 5.53819"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g id="Vector_2">
          <path
            d="M7.88867 4.64L8.08423 3.47556C8.22645 2.63111 8.33312 2 9.83534 2H12.1642C13.6665 2 13.782 2.66667 13.9153 3.48444L14.1109 4.64"
            fill={color}
          />
          <path
            d="M7.88867 4.64L8.08423 3.47556C8.22645 2.63111 8.33312 2 9.83534 2H12.1642C13.6665 2 13.782 2.66667 13.9153 3.48444L14.1109 4.64"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <path
          id="Vector_3"
          d="M17.0889 8.34668L16.5111 17.2978C16.4134 18.6933 16.3334 19.7778 13.8534 19.7778H8.14669C5.66669 19.7778 5.58669 18.6933 5.48891 17.2978L4.91113 8.34668"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_4"
          d="M9.51562 14.8887H12.4756"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_5"
          d="M8.77783 11.333H13.2223"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

export const EditIcon = ({ size = 20, color = "#292D32", ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="vuesax/linear/edit">
        <g id="vuesax/linear/edit_2">
          <g id="edit">
            <path
              id="Vector"
              d="M9.1665 1.66699H7.49984C3.33317 1.66699 1.6665 3.33366 1.6665 7.50033V12.5003C1.6665 16.667 3.33317 18.3337 7.49984 18.3337H12.4998C16.6665 18.3337 18.3332 16.667 18.3332 12.5003V10.8337"
              stroke={color}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_2"
              d="M13.3666 2.51639L6.7999 9.08306C6.5499 9.33306 6.2999 9.82472 6.2499 10.1831L5.89157 12.6914C5.75823 13.5997 6.3999 14.2331 7.30823 14.1081L9.81657 13.7497C10.1666 13.6997 10.6582 13.4497 10.9166 13.1997L17.4832 6.63306C18.6166 5.49972 19.1499 4.18306 17.4832 2.51639C15.8166 0.849722 14.4999 1.38306 13.3666 2.51639Z"
              stroke={color}
              strokeWidth="1.5"
              strokeMiterlimit={10}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector_3"
              d="M12.4248 3.45801C12.9831 5.44967 14.5415 7.00801 16.5415 7.57467"
              stroke={color}
              strokeWidth="1.5"
              strokeMiterlimit={10}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export const ArrowRightIcon = ({ size = 24, color = "#252528", ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.6799 14.62L14.2399 12.06L11.6799 9.5"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 12.0596H14.17"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 4C16.42 4 20 7 20 12C20 17 16.42 20 12 20"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const NextRightIcon = ({ size = 24, color = "#3375F5", ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="vuesax/bold/arrow-right">
        <g id="vuesax/bold/arrow-right_2">
          <g id="arrow-right">
            <path
              id="Vector"
              d="M15.1999 10.4899L13.2299 8.51993L10.0199 5.30993C9.33993 4.63993 8.17993 5.11993 8.17993 6.07993V12.3099V17.9199C8.17993 18.8799 9.33993 19.3599 10.0199 18.6799L15.1999 13.4999C16.0299 12.6799 16.0299 11.3199 15.1999 10.4899Z"
              fill={color}
            />
          </g>
        </g>
      </g>
    </svg>
  )
}


export const WarningIcon = ({ size = 20, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      className="text-red-600"
      viewBox="0 0 20 20"
      fill="currentColor"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export const WhatsAppIcon = ({ size = 16, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="#128C7E"
      version="1.1"
      viewBox="0 0 308 308"
      xmlSpace="preserve"
    >
      <g>
        <path d="M227.904 176.981c-.6-.288-23.054-11.345-27.044-12.781-1.629-.585-3.374-1.156-5.23-1.156-3.032 0-5.579 1.511-7.563 4.479-2.243 3.334-9.033 11.271-11.131 13.642-.274.313-.648.687-.872.687-.201 0-3.676-1.431-4.728-1.888-24.087-10.463-42.37-35.624-44.877-39.867-.358-.61-.373-.887-.376-.887.088-.323.898-1.135 1.316-1.554 1.223-1.21 2.548-2.805 3.83-4.348a140.77 140.77 0 011.812-2.153c1.86-2.164 2.688-3.844 3.648-5.79l.503-1.011c2.344-4.657.342-8.587-.305-9.856-.531-1.062-10.012-23.944-11.02-26.348-2.424-5.801-5.627-8.502-10.078-8.502-.413 0 0 0-1.732.073-2.109.089-13.594 1.601-18.672 4.802C90 87.918 80.89 98.74 80.89 117.772c0 17.129 10.87 33.302 15.537 39.453.116.155.329.47.638.922 17.873 26.102 40.154 45.446 62.741 54.469 21.745 8.686 32.042 9.69 37.896 9.69h.001c2.46 0 4.429-.193 6.166-.364l1.102-.105c7.512-.666 24.02-9.22 27.775-19.655 2.958-8.219 3.738-17.199 1.77-20.458-1.348-2.216-3.671-3.331-6.612-4.743z"></path>
        <path d="M156.734 0C73.318 0 5.454 67.354 5.454 150.143c0 26.777 7.166 52.988 20.741 75.928L.212 302.716a3.998 3.998 0 004.999 5.096l79.92-25.396c21.87 11.685 46.588 17.853 71.604 17.853C240.143 300.27 308 232.923 308 150.143 308 67.354 240.143 0 156.734 0zm0 268.994c-23.539 0-46.338-6.797-65.936-19.657a3.996 3.996 0 00-3.406-.467l-40.035 12.726 12.924-38.129a4.002 4.002 0 00-.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677 0-65.543 53.754-118.867 119.826-118.867 66.064 0 119.812 53.324 119.812 118.867.001 65.535-53.746 118.851-119.811 118.851z"></path>
      </g>
    </svg>
  );
}

export const DefaultImage = ({ size = 25, color = "#252528", ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 25 25"
      {...props}
    >
      <path
        stroke="#17191C"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M22.583 17.667l-3.26-7.615c-1.104-2.583-3.136-2.687-4.5-.229l-1.969 3.552c-1 1.802-2.864 1.958-4.156.344l-.23-.292c-1.343-1.687-3.239-1.479-4.208.448L2.47 17.47c-1.26 2.5.562 5.448 3.354 5.448h13.291c2.709 0 4.532-2.76 3.47-5.25zM7.26 8.334a3.125 3.125 0 100-6.25 3.125 3.125 0 000 6.25z"
      ></path>
    </svg>
  );
}

export const UploadIcon = ({ size = 15 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 14 15"
    >
      <path
        stroke="#3375F5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5.25 10.417v-3.5L4.083 8.083M5.25 6.917l1.167 1.166"
      ></path>
      <path
        stroke="#3375F5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12.833 6.333V9.25c0 2.917-1.166 4.083-4.083 4.083h-3.5c-2.917 0-4.083-1.166-4.083-4.083v-3.5c0-2.917 1.166-4.083 4.083-4.083h2.917"
      ></path>
      <path
        stroke="#3375F5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12.833 6.333H10.5C8.75 6.333 8.167 5.75 8.167 4V1.667l4.666 4.666z"
      ></path>
    </svg>
  );
}

export const ArrowRightIconDefault = ({ size = 24, color = "#3375F5", ...props }) => (<svg
  xmlns="http://www.w3.org/2000/svg"
  width={size}
  height={size}
  fill="none"
  viewBox="0 0 24 25"
  {...props}
>
  <path
    stroke={color}
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeMiterlimit="10"
    strokeWidth="1.5"
    d="M14.43 6.43l6.07 6.07-6.07 6.07M3.5 12.5h16.83"
  ></path>
</svg>)

export const SearchIcon = ({ size = 15, color = "#292D32" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 14 14"
  >
    <g
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      opacity="0.3"
    >
      <path d="M6.708 12.25a5.542 5.542 0 100-11.083 5.542 5.542 0 000 11.083zM12.833 12.833l-1.166-1.166"></path>
    </g>
  </svg>
)

export const BreadcrumbArrowIcon = ({ size = 10, color = "#9ca3af", ...props}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    aria-hidden="true"
    viewBox="0 0 6 10"
    {...props}
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M1 9l4-4-4-4"
    ></path>
  </svg>
)