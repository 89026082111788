import { Fragment } from "react";

function LoadingPageComponent(props) {
  if (!props.isLoading) return;

  return (
    <Fragment>
      <div className="fixed inset-0 bg-black opacity-70 z-[3]"></div>
      <div className="fixed inset-0 z-[4]">
        <div className="absolute left-1/2 top-[40%] flex justify-center items-center gap-3">
          <span className="loading loading-spinner text-white w-12"></span>
          <span className="text-white text-2xl">Loading...</span>
        </div>
      </div>
    </Fragment>
  );
}

export default LoadingPageComponent;