import { Fragment } from "react";

function TableComponent(props) {
  const colSpan = props.columns?.reduce((total, column) => total + (column.subColumns ? column.subColumns.length : 1), 0) || 1;

  function renderColumns() {
    if (!props?.columns?.length) return "";

    return (
      <>
        <tr className="border-b border-blue-200">
          {props.columns?.map((column, index) => {
            if (!column?.title) return <Fragment key={index} />;
            return (
              <th key={index}
                rowSpan={column?.subColumns ? 1 : 2}
                colSpan={column.subColumns ? column.subColumns.length : 1}
                scope="col"
                className={`py-3 px-6 ${index === 0 ? 'rounded-tl-md' : ''} ${index === props.columns.length - 1 ? 'rounded-tr-md' : ''}`}
              >
                {column.title}
              </th>
            )
          })}
        </tr>
        <tr className="border-b border-blue-200">
          {props.columns?.map((column) => {
            if (!column?.subColumns) return <Fragment key={column.key} />;
            return column.subColumns.map((subColumn, subIndex) => (
              <th key={subIndex} scope="col" className="py-3 px-6 rounded-tl-md">
                {subColumn.title}
              </th>
            ));
          })}
        </tr>
      </>
    )
  }

  function renderRowColumn(item, itemIndex) {
    return props.columns?.map((column, index) => {
      if (typeof column?.render === "function") {
        return (
          <td key={`${index}-${itemIndex}`} style={{ maxWidth: column.width }} className={`py-4 px-6 whitespace-wrap text-${column?.align ?? 'center'}`}>
            {column.render(item[column?.key] || "-", item, itemIndex)}
          </td>
        );
      }
      if (column?.subColumns) {
        return column.subColumns.map((subColumn, subIndex) => {
          if (typeof subColumn.render === "function") {
            return (
              <td key={`${index}-${subIndex}-${itemIndex}`} style={{ maxWidth: column.width }} className={`py-4 px-6 whitespace-wrap text-${subColumn?.align ?? 'center'}`}>
                {subColumn.render(item[column?.key] || "-", item, itemIndex)}
              </td>
            );
          }
          return (
            <td key={`${index}-${subIndex}-${itemIndex}`} style={{ maxWidth: column.width }} className="py-4 px-6 whitespace-wrap text-center">
              {item[subColumn?.key] || "-"}
            </td>
          );
        });
      }
      return (
        <td key={`${index}-${itemIndex}`} style={{ maxWidth: column.width }} className="py-4 px-6 whitespace-wrap text-center">
          {item[column?.key] || "-"}
        </td>
      );
    });
  }

  function renderRows() {
    if (props.loading) {
      return (
        <tr className="bg-white border-b border-blue-200">
          <td colSpan={colSpan} className="py-4 px-6">
            <div className="flex justify-center items-center gap-3 text-blue-500">
              <span className="loading loading-spinner loading-md"></span>
              <span>Loading...</span>
            </div>
          </td>
        </tr>
      )
    }

    if (props.dataSource?.length) {
      return props.dataSource?.map((item, index) => {
        return (
          <tr key={index} className="bg-white border-b border-blue-200">
            {renderRowColumn(item, index)}
          </tr>
        )
      })
    }
    return (
      <tr className="border-b border-blue-200">
        <td className="py-4 px-6 text-center font-medium w-max" colSpan={colSpan}>
          Data Not Found
        </td>
      </tr>
    )
  }

  return (
    <table className="w-full">
      <thead className="text-xs text-blue-500 bg-blue-50 uppercase">
        {renderColumns()}
      </thead>
      <tbody>{renderRows()}</tbody>
    </table>
  )
}

export default TableComponent;