import { Navigate } from "react-router-dom";
import CacheService from "../app/service/jwt.service";
import { periodLicenseValidator } from "../app/utils/utils";

const AuthenticateRoute = ({ redirectPath = "/login", children }) => {
  const userDetail = CacheService.getLocalStorage("user");
  const ssoToken = CacheService.getSSOToken();
  const localToken = CacheService.getLocalToken();

  if ((!ssoToken && !localToken) || !userDetail?.periodLicense) return <Navigate to={redirectPath} replace />;

  // period license validation routes
  if (userDetail?.levelName?.toLowerCase() !== "superadmin") {
    const rangeOfDay = periodLicenseValidator(userDetail?.periodLicense);

    if (rangeOfDay <= 0) {
      CacheService.destroyToken();
      CacheService.removeLocalStorage("user");
      return <Navigate to={redirectPath} replace />
    };
  }

  return children;
};

export default AuthenticateRoute;