import { Fragment, useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import SurveyConfiguration from "./SurveyConfiguration";
import MenuTreeTheme from "./MenuTreeTheme";
import { useWebContext } from "../../../app/Provider";
import AlertComponent from "../../../app/components/Alert";
import IphoneEmulatorImg from "../../../assets/images/iphone-emulator.svg";
import ThemeJson from "../../../assets/json/theme.json";
import { getDetailMenuTree, generatedUniqueLink, updateMenuTree } from "../../../app/service/api.service";
import LoadingPageComponent from "../../../app/components/LoadingPage";

const activeClass = "bg-[#F2A42C] text-white";
const defaultTheme = {
  fontFamily: "Outfit",
  fontColor: "#000000",
  title: '',
  subtitle: '',
  surveyType: '',
  questionCount: 1,
  thirdParty: '',
  questions: []
}

function Survey() {
  const navigate = useNavigate();
  const { updateActiveMenuByNavigate } = useWebContext();
  const { id } = useParams();
  const [alertOption, setAlertOption] = useState({});
  const [isMenuLoading, setIsMenuLoading] = useState(false);
  const [activeTab, setActiveTab] = useState({ link: activeClass });
  const [theme, setTheme] = useState(defaultTheme);
  const [generatedLoading, setGeneratedLoading] = useState(false);
  const debounceTimeout = useRef(null);

  const [survey, setSurvey] = useState({ template: undefined });

  useEffect(() => {
    console.log(debounceTimeout)
  }, [debounceTimeout]);

  useEffect(() => {
    if (id) {
      onGetDetailMenuTree()
    }
  }, [id]);

  useEffect(() => {
    setTheme(prevTheme => {
      const currentLength = prevTheme.questions.length;
      const requiredLength = prevTheme.questionCount;

      const newQuestions = Array.from({ length: Math.max(0, requiredLength - currentLength) }, (_, index) => ({
        id: currentLength + index + 1,
        text: '',
        visible: true
      }));

      const updatedQuestions = [
        ...prevTheme.questions.map((question, index) => ({
          ...question,
          visible: index < requiredLength
        })),
        ...newQuestions
      ];

      return { ...prevTheme, questions: updatedQuestions };
    });
  }, [theme.questionCount]);

  useEffect(() => {
    if (!survey?.template) return;
    if (JSON.stringify(survey.template) !== JSON.stringify(theme)) {
      setTheme(survey.template);
    }
  }, [JSON.stringify(survey.template)])

  const onGetDetailMenuTree = async () => {
    try {
      setIsMenuLoading(true);
      const response = await getDetailMenuTree(id);
      if (!response.success) throw response.message;

      setIsMenuLoading(false);
      setSurvey(response.data);
    } catch (error) {
      setIsMenuLoading(false);
      console.log("Error on onGenerateMenuLink:", error);
    }
  }

  const onChange = (key) => {
    setActiveTab({ [key]: activeClass });
  }

  const onGeneratedLink = async (generateLink = "") => {
    try {
      const request = {
        category: "survey",
        generateLink: typeof generateLink === "string" ? generateLink : ""
      }

      setGeneratedLoading(true);
      const response = await generatedUniqueLink(request);
      if (!response.success) throw response.message;

      setGeneratedLoading(false);
      setSurvey(response.data);
      setAlertOption({ type: "success", message: response.message });
    } catch (error) {
      setGeneratedLoading(false);
      setAlertOption({ type: "error", message: error });
      console.log("Error on onGeneratedLink:", error);
    }
  }

  const onSubmitConfig = async () => {
    try {
      setIsMenuLoading(true);
      const themeData = {
        ...theme,
        questions: theme.questions.slice(0, theme.questionCount) // Truncate to desired count
      };

      const response = await updateMenuTree({ id: survey.id, theme: themeData });
      if (!response.success) throw response.message;

      defaultTheme.questions = [];
      setIsMenuLoading(false);
      setTheme(defaultTheme);
      navigate('/active-page');
      updateActiveMenuByNavigate('active-page');
    } catch (error) {
      setIsMenuLoading(false);
      setAlertOption({ type: "error", message: error });
      console.log("Error on onSubmitConfig:", error);
    }
  }

  function renderLinkButton(links = []) {
    return links.map((item, index) => {
      if (!item?.id || !item.isActive) return "";
      const otherClass = item?.thumbnail ? " flex items-center gap-6" : "";
      return (
        <div
          key={index}
          className={`w-full p-2 rounded-xl text-sm text-black text-center mb-5 border border-solid${otherClass}`}
          style={{ background: theme.buttonBgColor, borderColor: theme.buttonBorder }}
        >
          {item?.thumbnail &&
            <img src={item.thumbnail} className="w-10 h-10 ml-4 rounded-full object-cover" />
          }
          <div className="truncate" style={{ color: theme.buttonTextColor }}>{item.label}</div>
        </div>
      )
    })
  }

  function renderQuestions() {
    return theme.questions.map((item, index) => {
      if (!item?.id || !item?.visible) return "";
      return (
        <Fragment key={index}>
          <div className="flex gap-4 mb-4">
            <textarea
              placeholder={`Pertanyaan ${index + 1}`}
              className="input w-full bg-transparent border-[#3375F5] text-black"
              onKeyUp={(e) => handleKeyUp(index, e)}
              rows={3}
              style={{ height: 'auto', minHeight: '4em' }}
              defaultValue={item.text}
            />
          </div>
        </Fragment>
      )
    })
  }

  const onEditQuestion = (index, value) => {
    let questions = theme.questions || [];

    const newQuestions = [...questions];
    newQuestions[index].text = value;

    setTheme({ ...theme, questions });
  }

  const handleKeyUp = (index, e) => {
    const value = e.target.value;

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      onEditQuestion(index, value);
    }, 1000);
  };

  const onSetTheme = (key, value) => {
    setTheme({ ...theme, [key]: value });
  }

  return (
    <div className="h-full px-8 pb-8">
      <AlertComponent
        type={alertOption.type}
        message={alertOption.message}
        onClose={() => setAlertOption({ message: "" })}
      />
      <LoadingPageComponent isLoading={isMenuLoading} />
      <div id="back-btn" className="flex items-center gap-2 cursor-pointer" onClick={() => navigate('/design-page')}>
        <AiOutlineArrowLeft size={20} className="text-blue-500" />
        <div className="ml-2 text-black text-2xl font-bold">Survey</div>
      </div>
      <div id="survey-content" className="h-[calc(100%-32px)] flex gap-16 py-6 overflow-x-hidden overflow-y-auto">
        <div id="left-content" className="basis-1/3">
          <div className="mt-5">
            <label className="text-xs">Judul</label>
            <div className="flex gap-4">
              <input
                type="text"
                placeholder="Masukkan Judul"
                className="input w-full bg-transparent border-[#3375F5] text-black"
                value={theme.title}
                onChange={(e) => onSetTheme('title', e.target.value)}
              />
            </div>
          </div>
          <div className="mt-5">
            <label className="text-xs">Subjudul</label>
            <div className="flex gap-4">
              <input
                type="text"
                placeholder="Masukkan Subjudul"
                className="input w-full bg-transparent border-[#3375F5] text-black"
                value={theme.subtitle}
                onChange={(e) => onSetTheme('subtitle', e.target.value)}
              />
            </div>
          </div>
          <div className="mt-10">
            <label className="text-xs">Pertanyaan</label>
            {renderQuestions()}
          </div>
        </div>
        <div id="right-content" className="flex-1 p-[0_8px_8px_8px]">
          <div className="flex items-center rounded-full shadow-lg p-1 text-center">
            <div className={`basis-1/2 ${activeTab.link || "bg-[#F9FAFB]"} rounded-full p-2 cursor-pointer`} onClick={() => onChange("link")}>
              Tautan
            </div>
            <div className={`basis-1/2 ${activeTab.theme || "bg-[#F9FAFB]"} rounded-full p-2 cursor-pointer`} onClick={() => onChange("theme")}>
              Tampilan
            </div>
          </div>
          {activeTab.link &&
            <SurveyConfiguration
              uniqueLink={survey.unique_link}
              theme={theme}
              generatedLoading={generatedLoading}
              onSetTheme={setTheme}
              onGeneratedLink={onGeneratedLink}
            />
          }
          {activeTab.theme &&
            <MenuTreeTheme
              theme={theme}
              onSetTheme={setTheme}
            />
          }
          <button className="btn w-full border-none bg-[#389e0d] mt-10 hover:bg-[#389e0d] hover:opacity-90 text-white" onClick={onSubmitConfig}>
            Published
          </button>
        </div>
      </div>
    </div>
  )
}

export default Survey;