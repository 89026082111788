import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { FaUser, FaMobile, FaMapMarkedAlt, FaMapMarkerAlt, FaHome } from "react-icons/fa";
import { getProvinceList, getCityList, updateProfile } from "../../app/service/api.service";
import AlertComponent from "../../app/components/Alert";
import { EditIcon } from "../../assets/icon/IndexSvgIcon";
import { getBase64 } from './../../app/utils/utils';
import { useWebContext } from "../../app/Provider";

function Profile() {
  const inputFileRef = useRef(undefined);
  const { store, updateStore } = useWebContext();
  const { userDetail } = store;
  const [isLoading, setLoading] = useState(false);
  const [alertOption, setAlertOption] = useState({});
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [photoBase64, setPhotoBase64] = useState(userDetail?.photoBase64 || "");
  const { register, formState: { errors }, setValue, handleSubmit } = useForm({
    defaultValues: {
      fullName: userDetail.name,
      phoneNumber: userDetail.phoneNumber,
      address: userDetail.address
    }
  });

  useEffect(() => {
    getProvinceOptions();
    getCityOptions(userDetail?.provinceId);
  }, [])

  useEffect(() => {
    if (provinceOptions.some(({ id }) => userDetail.provinceId === id)) {
      setValue("province", userDetail.provinceId);
    }
  }, [JSON.stringify(provinceOptions)])

  useEffect(() => {
    if (cityOptions.some(({ id }) => userDetail.cityId === id)) {
      setValue("city", userDetail.cityId);
    }
  }, [JSON.stringify(cityOptions)])

  const getProvinceOptions = async () => {
    try {
      const response = await getProvinceList();
      if (!response?.success) throw response.message;
      setProvinceOptions(response.data);
    } catch (error) {
      setAlertOption({ type: "error", message: error?.message });
    }
  }

  const getCityOptions = async (province) => {
    if (!province) return;
    try {
      const response = await getCityList(province);
      if (!response?.success) throw response.message;
      setCityOptions(response.data);
    } catch (error) {
      setCityOptions([]);
      setAlertOption({ type: "error", message: error?.message });
    }
  }

  const onUpdateProfile = async (data) => {
    try {
      if (isLoading) return;

      const request = {
        photoBase64: photoBase64,
        name: data?.fullName || userDetail.name,
        phoneNumber: data?.phoneNumber || userDetail.phoneNumber,
        province: data?.province || userDetail.provinceId,
        city: data?.city || userDetail.cityId,
        address: data?.address || userDetail.address
      }

      setLoading(true);
      const response = await updateProfile(request);
      if (!response.success) throw response.message;

      setLoading(false);
      setAlertOption({ type: "success", message: response?.message });
      updateStore({ userDetail: response.data });
    } catch (error) {
      setLoading(false);
      setAlertOption({ type: "error", message: error });
      console.log("Error onResetPassword:", error);
    }
  };

  const convertImageToBase64 = async (e) => {
    try {
      const file = e.target.files?.[0];
      if (!file) return;

      const result = await getBase64(file);
      setPhotoBase64(result);
    } catch (error) {
      console.log("error convertImageToBase64: ", error);
    }
  };

  const onUploadPhoto = () => {
    inputFileRef.current.click();
  };

  const onRemovePhoto = (e) => {
    e.stopPropagation();
    inputFileRef.current.value = "";
    setPhotoBase64("");
  }

  function renderProvince() {
    if (!provinceOptions?.length) return "";
    return provinceOptions.map((item, index) => (
      <option key={index} value={item.id}>{item.name}</option>
    ))
  }

  function renderCity() {
    if (!cityOptions?.length) return "";
    return cityOptions.map((item, index) => (
      <option key={index} value={item.id}>{item.name}</option>
    ))
  }

  return (
    <div className="h-full p-6">
      <AlertComponent
        type={alertOption.type}
        message={alertOption.message}
        onClose={() => setAlertOption({ message: "" })}
      />
      <div className="mb-5 text-black text-2xl font-bold">Profile</div>
      <div className="flex gap-10">
        <div className="relative w-40 h-40 grid place-items-center bg-[#F8F8F8] rounded-full text-5xl font-bold cursor-pointer" onClick={onUploadPhoto}>
          {photoBase64 &&
            <img src={photoBase64} alt="user" className="w-40 h-40 rounded-full object-cover" />
          }
          {!photoBase64 &&
            <div className="flex items-center justify-center w-40 h-40 bg-slate-300 rounded-full text-5xl text-white">
              {userDetail?.initial || ""}
            </div>
          }
          <div className="absolute bottom-1 right-1 bg-white p-2 rounded-full drop-shadow">
            <EditIcon size={14} />
          </div>
          <input ref={inputFileRef} type="file" className="hidden" onChange={convertImageToBase64} />
          {photoBase64 &&
            <button
              className={`
                btn btn-outline btn-sm w-full text-[8px] text-[#3375F5] hover:bg-white
                hover:border-[#3375F5] hover:text-[#3375F5] mt-5
              `}
              onClick={onRemovePhoto}
            >
              HAPUS FOTO
            </button>
          }
        </div>
        <form autoComplete="off" className="w-[480px] text-[#0F1628]" onSubmit={handleSubmit(onUpdateProfile)}>
          <div className="mb-5">
            <label className="text-sm">Full Name</label>
            <div className="relative">
              <FaUser className="absolute top-4 left-4 w-4 h-[14px] text-[#5557DB]" />
              <input
                placeholder="Input Full Name"
                className="input w-full bg-transparent border-[#7D818B] pl-[50px] text-sm"
                {...register("fullName", { required: "Fullname is required!" })}
              />
            </div>
            {errors.fullName && <div className="text-red-500 -mb-5 py-1">{errors.fullName?.message}</div>}
          </div>
          <div className="mb-5">
            <label className="text-sm">Phone Number</label>
            <div className="relative">
              <FaMobile className="absolute top-4 left-4 w-4 h-[14px] text-[#5557DB]" />
              <input
                placeholder="Ch. 628***"
                className="input w-full bg-transparent border-[#7D818B] pl-[50px] text-sm"
                {...register("phoneNumber", { required: "Phone number is required!", pattern: { value: /^[0-9]*$/, message: "Invalid phone number!" } })}
              />
            </div>
            {errors.phoneNumber && <div className="text-red-500 -mb-5 py-1">{errors.phoneNumber?.message}</div>}
          </div>
          <div className="lg:flex gap-x-[10px] mb-5">
            <div className="basis-6/12">
              <label className="text-sm">Province</label>
              <div className="relative">
                <FaMapMarkedAlt className="absolute top-4 left-4 w-4 h-4 text-[#5557DB]" />
                <select
                  className="select w-full bg-transparent border-[#7D818B] pl-[50px] text-sm font-normal"
                  disabled={!provinceOptions?.length}
                  {...register("province", { required: "Province is required!", onChange: (e) => {
                    getCityOptions(e.target.value);
                    setValue("city", "");
                  }})}
                >
                  <option value="" hidden>Choose Province</option>
                  {renderProvince()}
                </select>
              </div>
              {errors.province && <div className="text-red-500 -mb-5 py-1">{errors.province?.message}</div>}
            </div>
            <div className="basis-6/12">
              <label className="text-sm">City</label>
              <div className="relative">
                <FaMapMarkerAlt className="absolute top-4 left-4 w-4 h-4 text-[#5557DB]" />
                <select
                  className="select w-full bg-transparent border-[#7D818B] pl-[50px] text-sm font-normal"
                  disabled={!cityOptions?.length}
                  {...register("city", { required: "City is required!" })}
                >
                  <option value="" hidden>Choose City</option>
                  {renderCity()}
                </select>
              </div>
              {errors.city && <div className="text-red-500 -mb-5 py-1">{errors.city?.message}</div>}
            </div>
          </div>
          <div className="mb-5">
            <label className="text-sm">Address</label>
            <div className="relative">
              <FaHome className="absolute top-4 left-4 w-4 h-[14px] text-[#5557DB]" />
              <textarea
                placeholder="Input Address"
                className="textarea w-full pl-[40px] bg-transparent border-[#3375F5] text-black resize-none"
                {...register("address")}
              />
            </div>
          </div>
          <div className="mt-8 mb-28">
            <button
              type="submit"
              className={`btn w-full capitalize bg-[#5557DB] border-none text-sm text-white font-normal hover:opacity-75 hover:bg-[#5557DB]`}
            >
              {isLoading && <span className="loading loading-spinner loading-sm"></span>}
              Update Profile
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Profile;