import { WarningIcon } from "../../assets/icon/IndexSvgIcon";

function DialogComponent(props) {
  if (!props.open) return;

  const onConfirm = (e) => {
    if (typeof (props.onConfirm) === "function") {
      props.onConfirm(e);
    }
  }

  const onClose = () => {
    if (typeof (props.onClose) === "function") {
      props.onClose(false);
    }
  }

  return (
    <div className="fixed inset-0 z-30 overflow-y-auto">
      <div className="fixed inset-0 w-full h-full bg-black opacity-70"></div>
      <div className="flex items-center min-h-screen px-4 py-8">
        <div className="relative w-90 max-w-lg px-4 py-7 mx-auto bg-white rounded-xl shadow-lg">
          <div className="sm:flex gap-3">
            <div className="flex h-max bg-red-100 rounded-full p-1">
              <WarningIcon />
            </div>
            <div className="mr-10">
              <h4 className="text-lg text-left font-medium text-gray-800">
                Apakah Anda Yakin?
              </h4>
              <p className="mt-2 text-left text-[15px] leading-relaxed text-gray-500">
                {props.confirmText || "Ingin Menghapus Data Ini?"}
              </p>
              <div className="items-center gap-4 mt-4 sm:flex">
                <button
                  className="w-full h-10 px-12 py-1 p-2.5 flex items-center gap-3 flex-1 text-white bg-red-600 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2"
                  onClick={onConfirm}
                >
                  {props.isLoading && <span className="loading loading-spinner loading-sm"></span>}
                  {props.confirmTextBtn || "Hapus"}
                </button>
                <button
                  className="w-full h-10 px-12 py-1 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                  onClick={onClose}
                >
                  Batal
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DialogComponent;