import { useEffect, useState } from "react";
import moment from "moment";
import { useWebContext } from "../../app/Provider";
import { getWebPageList, getReports } from "../../app/service/api.service";
import TableComponent from "../../app/components/TableComponent";
import DialogComponent from "../../app/components/DialogComponent";

const monthsDefault = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

function Report() {
  const [isLoading, setIsLoading] = useState(false);
  const [reportList, setReportList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [webPageOption, setWebPageOption] = useState([]);
  const [webPageId, setWebPageId] = useState(1);
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const { store } = useWebContext();
  const userDetail = store.userDetail;

  const columns = [
    { key: "id", title: "id", render: (val, item, index) => index + 1 },
    {
      key: "webpage",
      title: "category",
      render: (item) => item.category
    },
    {
      key: "webpage",
      title: "web page url",
      render: (item) => item.long_url
    },
    { key: "action_visit", title: "daily visit" },
    { key: "action_click", title: "daily click" },
    {
      key: "action_date",
      title: "date",
      render: (item) => moment(item).format("DD-MM-YYYY")
    }
  ]

  useEffect(() => {
    console.log(reportList)
  }, [reportList]);

  
  useEffect(() => {
    onGetWebPageOptions();
  }, []);

  useEffect(() => {
    onGetReports();
  }, [month, webPageId]);

  const onGetWebPageOptions = async () => {
    try {
      const response = await getWebPageList();
      if (!response.success) throw response.message;

      setWebPageOption(response.data);
    } catch (error) {
      setIsLoading(false);
      console.log("Error on onGetWebPageOptions: ", error);
    }
  }

  const onGetReports = async () => {
    try {
      const request = {
        userId: userDetail.userId,
        webPageId: webPageId,
        months: month
      }

      setIsLoading(true);
      const response = await getReports(request);
      if (!response.success) throw response.message;

      setIsLoading(false);
      setReportList(response.data);
    } catch (error) {
      setIsLoading(false);
      console.log("Error on onGetReports: ", error);
    }
  }

  return (
    <div className="p-6">
      <div className="mb-5 text-black text-2xl font-bold">Report</div>
      <div>
        <div className="flex items-center gap-3 mb-5">
          <div className="text-sm font-bold">Month:</div>
          <select className="select select-ghost w-36 bg-transparent border-gray-300 text-sm" value={month} onChange={(e) => setMonth(e.target.value)}>
            {monthsDefault.map((label, index) => (
              <option key={index} value={index + 1}>{label}</option>
            ))}
          </select>
          <div className="text-sm font-bold">Webpage ID:</div>
          <select className="select select-ghost bg-transparent border-gray-300 text-sm" value={webPageId} onChange={(e) => setWebPageId(e.target.value)}>
            <option value="" hidden>Choose Webpage ID</option>
            {webPageOption.map((page, index) => (
              <option key={index} value={page.id}>{page.category} - {page.unique_link}</option>
            ))}
          </select>
        </div>
      </div>
      <TableComponent
        loading={isLoading}
        columns={columns}
        dataSource={reportList}
      />
      <DialogComponent open={openDialog} onClose={setOpenDialog} />
    </div>
  )
}

export default Report;