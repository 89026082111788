import React from 'react';

function CardCount({ title, value, icon, color, borderLeft }) {
  return (
    <div className={`bg-white flex justify-between p-5 rounded-xl border border-gray-300 ${borderLeft}`}>
      <div className="flex flex-col gap-2">
        <div className="font-semibold text-slate-400">{title}</div>
        <div className="font-bold text-black">{value}</div>
      </div>
      <div className={`w-30 h-30 bg-${color}-100 flex justify-center items-center`}>
        {icon}
      </div>
    </div>
  );
};

export default CardCount;