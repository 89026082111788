import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import MenuTreeLink from "./MenuTreeLink";
import MenuTreeTheme from "./MenuTreeTheme";
import { useWebContext } from "../../../app/Provider";
import AlertComponent from "../../../app/components/Alert";
import IphoneEmulatorImg from "../../../assets/images/iphone-emulator.svg";
import ThemeJson from "../../../assets/json/theme.json";
import { getDetailMenuTree, generatedUniqueLink, updateMenuTree } from "../../../app/service/api.service";
import LoadingPageComponent from "../../../app/components/LoadingPage";

const activeClass = "bg-[#F2A42C] text-white";
const defaultTheme = {
  bgColor: ThemeJson[0].bgColor,
  fontFamily: "Outfit",
  fontColor: "#000000",
  buttonBgColor: ThemeJson[0].buttonBgColor,
  buttonBorder: ThemeJson[0].buttonBorder,
  buttonTextColor: ThemeJson[0].buttonTextColor,
  picture: "",
  profileName: "",
  bioDetail: "",
  contents: []
}

function MenuTree() {
  const navigate = useNavigate();
  const { updateActiveMenuByNavigate } = useWebContext();
  const { id } = useParams();
  const [alertOption, setAlertOption] = useState({});
  const [isMenuLoading, setIsMenuLoading] = useState(false);
  const [menuTree, setMenuTree] = useState({ template: undefined });
  const [activeTab, setActiveTab] = useState({ link: activeClass });
  const [theme, setTheme] = useState(defaultTheme);
  const [generatedLoading, setGeneratedLoading] = useState(false);

  useEffect(() => {
    if (id) onGetDetailMenuTree();
  }, [id]);

  useEffect(() => {
    if (!menuTree?.template) return;
    if (JSON.stringify(menuTree.template) !== JSON.stringify(theme)) {
      setTheme(menuTree.template);
    }
  }, [JSON.stringify(menuTree.template)])

  const onGetDetailMenuTree = async () => {
    try {
      setIsMenuLoading(true);
      const response = await getDetailMenuTree(id);
      if (!response.success) throw response.message;

      setIsMenuLoading(false);
      setMenuTree(response.data);
    } catch (error) {
      setIsMenuLoading(false);
      console.log("Error on onGenerateMenuLink:", error);
    }
  }

  const onChange = (key) => {
    setActiveTab({ [key]: activeClass });
  }

  const onGeneratedLink = async (generateLink = "") => {
    try {
      const request = {
        category: "menutree",
        generateLink: typeof generateLink === "string" ? generateLink : ""
      }

      setGeneratedLoading(true);
      const response = await generatedUniqueLink(request);
      if (!response.success) throw response.message;

      setGeneratedLoading(false);
      setMenuTree(response.data);
      setAlertOption({ type: "success", message: response.message });
    } catch (error) {
      setGeneratedLoading(false);
      setAlertOption({ type: "error", message: error });
      console.log("Error on onGeneratedLink:", error);
    }
  }

  const onSubmitConfig = async () => {
    try {
      setIsMenuLoading(true);
      const response = await updateMenuTree({ id: menuTree.id, theme: theme });
      if (!response.success) throw response.message;

      defaultTheme.contents = [];
      setIsMenuLoading(false);
      setTheme(defaultTheme);
      navigate('/active-page');
      updateActiveMenuByNavigate('active-page');
    } catch (error) {
      setIsMenuLoading(false);
      setAlertOption({ type: "error", message: error });
      console.log("Error on onSubmitConfig:", error);
    }
  }

  function renderLinkButton(links = []) {
    return links.map((item, index) => {
      if (!item?.id || !item.isActive) return "";
      const otherClass = item?.thumbnail ? " flex items-center gap-6" : "";
      return (
        <div
          key={index}
          className={`w-full p-2 rounded-xl text-sm text-black text-center mb-5 border border-solid${otherClass}`}
          style={{ background: theme.buttonBgColor, borderColor: theme.buttonBorder }}
        >
          {item?.thumbnail &&
            <img src={item.thumbnail} className="w-10 h-10 ml-4 rounded-full object-cover" />
          }
          <div className="truncate" style={{ color: theme.buttonTextColor }}>{item.label}</div>
        </div>
      )
    })
  }

  function renderContents() {
    return theme.contents.map((item, index) => {
      if (!item?.id || !item.isActive) return "";
      return (
        <Fragment key={index}>
          <div key={index} className="mb-5 text-base truncate max-w-[207px]" style={{ color: theme.fontColor }}>
            {item.header}
          </div>
          {renderLinkButton(item.links)}
        </Fragment>
      )
    })
  }

  return (
    <div className="h-full px-8 pb-8">
      <AlertComponent
        type={alertOption.type}
        message={alertOption.message}
        onClose={() => setAlertOption({ message: "" })}
      />
      <LoadingPageComponent isLoading={isMenuLoading} />
      <div id="back-btn" className="flex items-center gap-2 cursor-pointer" onClick={() => navigate('/design-page')}>
        <AiOutlineArrowLeft size={20} className="text-blue-500" />
        <div className="ml-2 text-black text-2xl font-bold">Menu Tree</div>
      </div>
      <div id="menutree-content" className="h-[calc(100%-32px)] flex gap-16 py-6 overflow-x-hidden overflow-y-auto">
        <div id="left-content" className="flex w-[272px] h-[550px] relative">
          <img src={IphoneEmulatorImg} alt="" className="z-[2]" />
          <div
            className="absolute top-[12px] left-[12px] w-[calc(100%-25px)] h-[calc(100%-25px)] rounded-3xl overflow-y-hidden"
            style={{ fontFamily: theme.fontFamily, background: theme.bgColor }}
          >
            <div className="flex flex-col items-center px-5 pt-14">
              <div className="w-16 h-16 flex justify-center items-center rounded-full bg-[#F8F8F8] text-black text-2xl font-bold mb-3">
                {theme.picture &&
                  <img src={theme.picture} alt={theme.picture} className="w-full h-full object-cover rounded-full overflow-hidden" />
                }
                {!theme.picture && "AD"}
              </div>
              <div className="text-xl" style={{ color: theme.fontColor }}>{theme.profileName}</div>
              <div className="mb-[20%] text-center text-xs" style={{ color: theme.fontColor }}>
                {theme.bioDetail}
              </div>
              {renderContents()}
            </div>
          </div>
        </div>
        <div id="right-content" className="flex-1 p-[0_8px_8px_8px]">
          <div className="flex items-center rounded-full shadow-lg p-1 text-center">
            <div className={`basis-1/2 ${activeTab.link || "bg-[#F9FAFB]"} rounded-full p-2 cursor-pointer`} onClick={() => onChange("link")}>
              Tautan
            </div>
            <div className={`basis-1/2 ${activeTab.theme || "bg-[#F9FAFB]"} rounded-full p-2 cursor-pointer`} onClick={() => onChange("theme")}>
              Tampilan
            </div>
          </div>
          {activeTab.link &&
            <MenuTreeLink
              uniqueLink={menuTree.unique_link}
              theme={theme}
              generatedLoading={generatedLoading}
              onSetTheme={setTheme}
              onGeneratedLink={onGeneratedLink}
            />
          }
          {activeTab.theme &&
            <MenuTreeTheme
              theme={theme}
              onSetTheme={setTheme}
            />
          }
          <button className="btn w-full border-none bg-[#389e0d] mt-10 hover:bg-[#389e0d] hover:opacity-90 text-white" onClick={onSubmitConfig}>
            Published
          </button>
        </div>
      </div>
    </div>
  )
}

export default MenuTree;