import React, { createContext, useContext, useState } from 'react';
import CacheService from './service/jwt.service';
import { userDetailCacheMap } from './utils/dataMap';

const WebContext = createContext();

function WebContextProvider({ children }) {
  const [store, setStore] = useState({ userDetail: CacheService.getLocalStorage("user") || {} });

  const updateStore = (param) => {
    if (param?.userDetail?.email) {
      const userData = userDetailCacheMap({ ...store.userDetail, ...param.userDetail });
      CacheService.saveLocalStorage("user", userData);
      setStore({ ...store, userDetail: userData });
      return;
    }
    setStore(param);
  }

  const updateActiveMenuByNavigate = (key) => {
    setStore({ ...store, activeMenu: key });
  }

  return (
    <WebContext.Provider value={{ store, updateStore, updateActiveMenuByNavigate }}>
      {children}
    </WebContext.Provider>
  );
}

export const useWebContext = () => {
  return useContext(WebContext);
}
export default WebContextProvider;
