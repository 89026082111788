import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FaUser, FaEnvelope, FaMobile, FaMapMarkedAlt, FaMapMarkerAlt, FaLock, FaEye, FaEyeSlash } from "react-icons/fa";
import { userAvailabilitySSO, registerSSO, getProvinceList, getCityList } from "../../app/service/api.service";
import SliderComponent from "../../app/components/Slider";
import AlertComponent from './../../app/components/Alert';
import WebtokooImg from "../../assets/icon/logo/webtokoo-icon.svg";
import LogoImg from "../../assets/icon/logo/webtokoo-logo.svg";
import SocialIcons from "../../assets/json/social.json";

function Registration() {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [alertOption, setAlertOption] = useState({});
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { register, trigger, formState: { errors }, setValue, getValues, handleSubmit } = useForm();

  useEffect(() => {
    getProvinceOptions();
  }, [])

  const getProvinceOptions = async () => {
    try {
      const response = await getProvinceList();
      if (!response?.success) throw response.message;
      setProvinceOptions(response.data);
    } catch (error) {
      setAlertOption({ type: "error", message: error?.message });
    }
  }

  const getCityOptions = async (province) => {
    if (!province) return;
    try {
      const response = await getCityList(province);
      if (!response?.success) throw response.message;
      setCityOptions(response.data);
    } catch (error) {
      setCityOptions([]);
      setAlertOption({ type: "error", message: error?.message });
    }
  }

  const onCheckTermAndCondition = () => {
    setValue("termAndCondition", !getValues("termAndCondition"));
    trigger("termAndCondition");
  }

  const onCheckUserAvaibility = async (email) => {
    try {
      const responseSSOUser = await userAvailabilitySSO(email);
      return !!responseSSOUser?.data?.id;
    } catch (error) {
      return false;
    }
  }

  const onRegisterClick = async (data) => {
    try {
      if (isLoading) return;
      const {
        fullName = "", email = "", businessCategory = "", businessName = "",
        phoneNumber = "", province = 0, city = 0, password = "", confirmPassword = ""
      } = data;

      const requestData = {
        name: fullName,
        email: email,
        business_category: businessCategory,
        business_name: businessName,
        phone: phoneNumber,
        password: password,
        password_confirmation: confirmPassword,
        province: province,
        city: city,
        product_id: 4 // must 5
      };

      setLoading(true);
      const responseSSOUser = await onCheckUserAvaibility(email);
      if (responseSSOUser) throw "User already exist!";

      const responseSSO = await registerSSO(requestData);
      if (responseSSO?.message !== "success") throw responseSSO.message;

      setLoading(false);
      setAlertOption({ type: "success", message: responseSSO?.message });
      setTimeout(() => navigate("/login"), 2000);
    } catch (error) {
      setLoading(false);
      setAlertOption({ type: "error", message: error });
      console.log("Error on onRegisterClick:", error);
    }
  }

  function renderSocialIcon() {
    return SocialIcons.map((item, index) => {
      return (
        <a key={index} href={item.link} target="_blank" rel="noreferrer">
          <img src={require(`../../assets/icon/login/${item.icon}`)} alt={item.alt} />
        </a>
      )
    })
  }

  function renderProvince() {
    if (!provinceOptions?.length) return "";
    return provinceOptions.map((item, index) => (
      <option key={index} value={item.id}>{item.name}</option>
    ))
  }

  function renderCity() {
    if (!cityOptions?.length) return "";
    return cityOptions.map((item, index) => (
      <option key={index} value={item.id}>{item.name}</option>
    ))
  }

  return (
    <div className="h-screen">
      <div className="hidden fixed h-screen lg:w-[380px] lg:bg-[#ED5565] lg:flex lg:flex-col lg:justify-center lg:px-8">
        <img src={WebtokooImg} className="w-12" style={{ filter: "drop-shadow(0 0 10px rgba(255, 255, 255, 0.4))" }} alt="" />
        <SliderComponent />
        <div className="flex">{renderSocialIcon()}</div>
      </div>
      <div className="h-fit lg:w-[calc(100%-380px)] lg:ml-auto select-none">
        <AlertComponent
          type={alertOption.type}
          message={alertOption.message}
          onClose={() => setAlertOption({ message: "" })}
        />
        <div className="py-8 mx-8">
          <Link to="/"><img src={LogoImg} alt="" className="w-24" /></Link>
        </div>
        <div className="flex justify-center">
          <form autoComplete="off" className="w-[480px] text-[#7D818B]" onSubmit={handleSubmit(onRegisterClick)}>
            <div className="text-center mb-6">
              <h3 className="text-4xl text-[#0F1628]">Register</h3>
              <span className="text-base">Lengkapi form untuk membuat akun</span>
            </div>
            <div className="mb-5">
              <label className="text-sm">Nama Lengkap</label>
              <div className="relative">
                <FaUser className="absolute top-4 left-4 w-4 h-4 text-[#5557DB]" />
                <input
                  placeholder="Masukkan Nama Lengkap"
                  className="input w-full bg-transparent border-[#7D818B] pl-[50px] text-sm"
                  {...register("fullName", { required: "Nama Lengkap wajib diisi!" })}
                />
              </div>
              {errors.fullName && <div className="text-sm text-red-500 -mb-5 py-1">{errors.fullName?.message}</div>}
            </div>
            <div className="mb-5">
              <label className="text-sm">Email</label>
              <div className="relative">
                <FaEnvelope className="absolute top-4 left-4 w-4 h-4 text-[#5557DB]" />
                <input
                  placeholder="Masukkan Email"
                  className="input w-full bg-transparent border-[#7D818B] pl-[50px] text-sm"
                  {...register("email", { required: "Email wajib diisi!", pattern: { value: /\S+@\S+\.\S+/, message: "Alamat email tidak sesuai!" } })}
                />
              </div>
              {errors.email && <div className="text-sm text-red-500 -mb-5 py-1">{errors.email?.message}</div>}
            </div>
            <div className="mb-5">
              <label className="text-sm">Kategori Bisnis</label>
              <div className="relative">
                <FaMapMarkedAlt className="absolute top-4 left-4 w-4 h-4 text-[#5557DB]" />
                <select
                  className="select w-full bg-transparent border-[#7D818B] pl-[50px] text-sm font-normal"
                  {...register("businessCategory", { required: "Kategori Bisnis wajib diisi!" })}
                >
                  <option value="" hidden>Pilih Kategori Bisnis</option>
                  <option value="business">Business</option>
                  <option value="personal">Personal</option>
                </select>
              </div>
              {errors.businessCategory && <div className="text-sm text-red-500 -mb-5 py-1">{errors.businessCategory?.message}</div>}
            </div>
            <div className="mb-5">
              <label className="text-sm">Nama Bisnis</label>
              <div className="relative">
                <FaUser className="absolute top-4 left-4 w-4 h-4 text-[#5557DB]" />
                <input
                  placeholder="Masukkan Nama Bisnis"
                  className="input w-full bg-transparent border-[#7D818B] pl-[50px] text-sm"
                  {...register("businessName", { required: "Nama Bisnis wajib diisi!" })}
                />
              </div>
              {errors.businessName && <div className="text-sm text-red-500 -mb-5 py-1">{errors.businessName?.message}</div>}
            </div>
            <div className="mb-5">
              <label className="text-sm">No. Handphone</label>
              <div className="relative">
                <FaMobile className="absolute top-4 left-4 w-4 h-4 text-[#5557DB]" />
                <input
                  placeholder="Ch. 628***"
                  className="input w-full bg-transparent border-[#7D818B] pl-[50px] text-sm"
                  {...register("phoneNumber", { required: "No. Handphone wajib diisi!", pattern: { value: /^[0-9]*$/, message: "No. Handphone tidak sesuai!" } })}
                />
              </div>
              {errors.phoneNumber && <div className="text-sm text-red-500 -mb-5 py-1">{errors.phoneNumber?.message}</div>}
            </div>
            <div className="lg:flex gap-x-[10px] mb-5">
              <div className="basis-6/12">
                <label className="text-sm">Provinsi</label>
                <div className="relative">
                  <FaMapMarkedAlt className="absolute top-4 left-4 w-4 h-4 text-[#5557DB]" />
                  <select
                    className="select w-full bg-transparent border-[#7D818B] pl-[50px] text-sm font-normal"
                    disabled={!provinceOptions?.length}
                    {...register("province", { required: "Provinsi wajib diisi!", onChange: (e) => getCityOptions(e.target.value) })}
                  >
                    <option value="" hidden>Pilih Provinsi</option>
                    {renderProvince()}
                  </select>
                </div>
                {errors.province && <div className="text-sm text-red-500 -mb-5 py-1">{errors.province?.message}</div>}
              </div>
              <div className="basis-6/12">
                <label className="text-sm">Kota</label>
                <div className="relative">
                  <FaMapMarkerAlt className="absolute top-4 left-4 w-4 h-4 text-[#5557DB]" />
                  <select
                    className="select w-full bg-transparent border-[#7D818B] pl-[50px] text-sm font-normal"
                    disabled={!cityOptions?.length}
                    {...register("city", { required: "Kota wajib diisi!" })}
                  >
                    <option value="" hidden>Pilih Kota</option>
                    {renderCity()}
                  </select>
                </div>
                {errors.city && <div className="text-sm text-red-500 -mb-5 py-1">{errors.city?.message}</div>}
              </div>
            </div>
            <div className="mb-5">
              <label className="text-sm">Password</label>
              <div className="relative">
                <FaLock className="absolute top-4 left-4 w-4 h-4 text-[#5557DB]" />
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Masukkan Password"
                  className="input w-full bg-transparent border-[#7D818B] pl-[50px] text-sm"
                  {...register("password", {
                    required: "Password wajib diisi!",
                    validate: (val) => val === getValues("confirmPassword") || "Password tidak sama dengan Konfirmasi Password!",
                    onChange: () => { trigger("password"); trigger("confirmPassword"); }
                  })}
                />
                <span className="absolute top-3 right-4 p-1">
                  {showPassword ?
                    <FaEye className="w-4 h-4 text-[#5557DB] cursor-pointer" onClick={() => setShowPassword(false)} />
                    :
                    <FaEyeSlash className="w-4 h-4 text-[#5557DB] cursor-pointer" onClick={() => setShowPassword(true)} />
                  }
                </span>
              </div>
              {errors.password && <div className="text-sm text-red-500 -mb-5 py-1">{errors.password?.message}</div>}
            </div>
            <div className="mb-5">
              <label className="text-sm">Konfirmasi Password</label>
              <div className="relative">
                <FaLock className="absolute top-4 left-4 w-4 h-4 text-[#5557DB]" />
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Ketik Ulang Password"
                  className="input w-full bg-transparent border-[#7D818B] pl-[50px] text-sm"
                  {...register("confirmPassword", {
                    required: "Konfirmasi Password wajib diisi!",
                    validate: (val) => val === getValues("password") || "Konfirmasi Password tidak sama dengan Password!",
                    onChange: () => { trigger("password"); trigger("confirmPassword"); }
                  })}
                />
                <span className="absolute top-3 right-4 p-1">
                  {showConfirmPassword ?
                    <FaEye className="w-4 h-4 text-[#5557DB] cursor-pointer" onClick={() => setShowConfirmPassword(false)} />
                    :
                    <FaEyeSlash className="w-4 h-4 text-[#5557DB] cursor-pointer" onClick={() => setShowConfirmPassword(true)} />
                  }
                </span>
              </div>
              {errors.confirmPassword && <div className="text-sm text-red-500 -mb-3 py-1">{errors.confirmPassword?.message}</div>}
            </div>
            <div className="flex flex-wrap gap-2 items-center text-sm" onClick={onCheckTermAndCondition}>
              <input
                type="checkbox"
                className="checkbox purple-checkbox border-2 border-[#5557DB] rounded w-4 h-4"
                {...register("termAndCondition", { required: "Syarat dan Ketentuan wajib disetujui!" })}
              />
              <span className="text-sm cursor-pointer">
                Saya telah membaca dan menyetujui
                <Link to="https://awandigital.id/term_condition.html" target="_blank" className="text-[#5557DB]" onClick={(e) => e.stopPropagation()}>
                  &nbsp; syarat dan ketentuan &nbsp;
                </Link>
                yang berlaku.
              </span>
            </div>
            {errors.termAndCondition && <div className="text-sm text-red-500 -mb-5 py-1">{errors.termAndCondition?.message}</div>}
            <div className="mt-8 mb-28">
              <button
                type="submit"
                className={`
                  btn w-full capitalize bg-[#5557DB] border-none text-sm
                  text-white font-normal hover:bg-[#5557DB] hover:opacity-75
                `}
              >
                {isLoading && <span className="loading loading-spinner loading-sm"></span>}
                Register
              </button>
              <div className="mt-5 text-center">Sudah punya akun? &nbsp;<Link to="/login" className="text-[#5557DB]">Login</Link></div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Registration;