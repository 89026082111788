import { Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AiFillLock } from "react-icons/ai";
import MenutreeImg from "../../assets/images/menutree.svg";
import CurriculumVitaeImg from "../../assets/images/curriculum-vitae.svg";
import CatalogImg from "../../assets/images/catalog.svg";
import WebliteImg from "../../assets/images/weblite.svg";
import SurveyImg from "../../assets/images/survey.svg";
import BiographyImg from "../../assets/images/biography.svg";

const WebPageData = [
  {
    title: "Menu Tree",
    image: MenutreeImg,
    link: "menutree",
    isAvailable: true
  },
  {
    title: "Catalog",
    image: CatalogImg,
    link: "catalog",
    isAvailable: true
  },
  {
    title: "Survey",
    image: SurveyImg,
    link: "survey",
    isAvailable: true
  },
  {
    title: "Curriculum Vitae",
    image: CurriculumVitaeImg,
    link: "curriculum-vitae",
    isAvailable: false
  },
  {
    title: "Weblite",
    image: WebliteImg,
    link: "weblite",
    isAvailable: false
  },
  {
    title: "Bio",
    image: BiographyImg,
    link: "biography",
    isAvailable: false
  }
]

function DesignPageList() {
  const navigate = useNavigate();
  const location = useLocation();

  const onClickPage = ({ link, isAvailable }) => {
    if (!isAvailable) return;
    navigate(`${location.pathname}/${link}`);
  }

  function renderCard() {
    return WebPageData.map((data, index) => {
      return (
        <div key={index} className={data.isAvailable ? "cursor-pointer" : "cursor-not-allowed"} onClick={() => onClickPage(data)}>
          <div className="relative h-[232px] flex justify-center bg-[#E9EEF9] rounded-3xl">
            <img src={data.image} alt="" />
            {!data.isAvailable &&
              <Fragment>
                <div className="absolute inset-0 w-full h-full bg-black opacity-75 rounded-3xl"></div>
                <AiFillLock className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white text-6xl" />
              </Fragment>
            }
          </div>
          <div className="pt-4 text-xl font-inter text-center">{data.title}</div>
        </div>
      )
    })
  }

  return (
    <div className="grid grid-cols-[repeat(auto-fit,_minmax(216px,_max-content))] justify-center gap-x-14 gap-y-10 pt-6">
      {renderCard()}
    </div>
  )
}

export default DesignPageList;