import { Fragment, useEffect, useState, useRef } from "react";
import { getBase64 } from "./../../../app/utils/utils";
import { ChromePicker } from "react-color";
import {
  CopyLinkIcon,
  WhatsAppIcon,
  EditIcon,
  DefaultImage,
  UploadIcon,
} from "../../../assets/icon/IndexSvgIcon";

import FontFamily from "../../../assets/json/fontFamily.json";
import TemplateWhiteImage from "../../../assets/images/catalog/template_white.png";
import TemplateBlackImage from "../../../assets/images/catalog/template_black.png";
import SliderComponent from "../../../app/components/SliderComponent";

const activeClass = "border-2";

function CatalogHome(props) {
  const inputLogoFileRef = useRef(undefined);
  const inputBannerFileRef = useRef(undefined);

  const [openTemplate, setOpenTemplate] = useState(false);
  const [activeTemplate, setActiveTemplate] = useState({ white: activeClass });
  const [activeSection, setActiveSection] = useState(null);

  const [showProductBackgroundColor, setShowProductBackgroundColor] =
    useState(false);
  const [showProductTitleColor, setShowProductTitleColor] = useState(false);
  const [showProductDescriptionColor, setShowProductDescriptionColor] =
    useState(false);
  const [showProductColor, setShowProductColor] = useState(false);

  const [showFooterBackgroundColor, setShowFooterBackgroundColor] =
    useState(false);
  const [showFooterTitleColor, setShowFooterTitleColor] = useState(false);
  const [showFooterDescriptionColor, setShowFooterDescriptionColor] =
    useState(false);

  const [uniqueLink, setUniqueLink] = useState("xxxxxxxxxxxxxxxx");

  useEffect(() => {
    const activeTemplateState = { [props.theme.active_theme]: activeClass };
    if (
      JSON.stringify(activeTemplateState) !== JSON.stringify(activeTemplate)
    ) {
      return setActiveTemplate(activeTemplateState);
    }
  }, [props.theme.active_theme]);

  useEffect(() => {
    if (props.uniqueLink) {
      setUniqueLink(props.uniqueLink);
    }
  }, [props.uniqueLink]);

  const onSetTheme = (key, value, parent) => {
    if (!parent) return props.setTheme({ ...props.theme, [key]: value });

    const object = props.theme[parent];
    if (!object || typeof object[key] === "undefined") return;
    object[key] = value;

    return props.setTheme({ ...props.theme, [parent]: object });
  };

  const onCopyLink = (e) => {
    navigator.clipboard.writeText(`https://link.awandigital.id/${uniqueLink}`);
    e.target.parentNode.dataset.tip = "Link copied!";
  };

  const onClickCollapseTemplate = () => {
        setOpenTemplate(!openTemplate);
  };

  const onClickTemplate = (theme, key) => {
    setActiveTemplate({ [key]: activeClass });
    props.setTheme(theme);
  };

  const onClickUploadLogo = () => {
    inputLogoFileRef.current.click();
  };

  const onClickUploadBanner = () => {
    inputBannerFileRef.current.click();
  };

  const onChangeSection = (section) => {
    setActiveSection(section);
  };

  const convertImageToBase64 = async (e, type) => {
    try {
      const files = e.target.files;
      const lengthFile = files.length;

      if (lengthFile > 3) return alert("Maximum upload is 3 file");

      if (type === "banner" && props.theme.banner.length >= 3)
        return alert("Maximum upload is 3 file");

      const base64ConverterPromises = Array.from({ length: lengthFile }).map(
        async (_, index) => {
          const file = files[index];
          const maxSize = 3 * 1024 * 1024; // 3MB in bytes
          if (file.size > maxSize) {
            alert("File size exceeds the limit of 3MB");
            return;
          }

          return await getBase64(file);
        }
      );

      const images = await Promise.all(base64ConverterPromises);

      if (!images.length) return;

      switch (type) {
        case "logo":
          onSetTheme("logo", images[0]);
          break;
        case "banner":
          onSetTheme("banner", [...props.theme.banner, ...images]);
          break;
        default:
          break;
      }
    } catch (error) {
      console.log("error convertImageToBase64: ", error);
    }
  };

  const onRemoveLogo = () => {
    onSetTheme("logo", null);
  };

  const onRemoveBanner = (index) => {
    const banner = props.theme.banner;
    banner.splice(index, 1);

    onSetTheme("banner", banner);
  };

  const renderFontFamily = () => {
    return FontFamily.map((item, index) => (
      <option key={index} value={item} style={{ fontFamily: item }}>
        {item}
      </option>
    ));
  };

  const onShareToWhatsApp = () => {
    const url =
      "https://api.whatsapp.com/send?text=Check%20my%20product%20on%20this%20Link!%20-%20https://link.awandigital.id/OxInzZjAeTimkQL";
    window.open(url);
  };

  function renderGeneratedLinkButton() {
    if (uniqueLink !== "xxxxxxxxxxxxxxxx") {
      return (
        <div
          className="tooltip flex text-[#3375F5] cursor-pointer items-center"
          onClick={onCopyLink}
          data-tip="Copy link"
        >
          <CopyLinkIcon />
          <span className="uppercase text-xs font-normal">Salin Link</span>
        </div>
      );
    }
    return (
      <div
        className="tooltip flex text-[#3375F5] cursor-pointer"
        onClick={props.onGeneratedLink}
        data-tip="Generate link"
      >
        {props.generatedLoading && (
          <span className="loading loading-spinner loading-xs"></span>
        )}
        {!props.generatedLoading && <CopyLinkIcon />}
        <span className="uppercase text-xs font-normal">Generate Link</span>
      </div>
    );
  }

  function renderTemplate() {
    return (
      <div
        id="catalog-template"
        className="rounded-2xl shadow-lg p-5 mt-5 grid gap-4 bg-[#FFF]"
      >
        <div className="flex justify-between items-center cursor-pointer" onClick={onClickCollapseTemplate}>
          <span className="text-[#1E1E1E] font-semibold text-md tracking-wider">
            Template 
          </span>
          <span className="text-[#3375F5] font-normal text-xs tracking-wider uppercase">{!openTemplate ? "buka" : "tutup"}</span>
        </div>
        { openTemplate && (
          <div className="flex justify-center items-center gap-2">
          <img
            src={TemplateWhiteImage}
            alt="template-white"
            width={150}
            onClick={() =>
              onClickTemplate({ ...props.listTheme.white }, "white")
            }
            className={`${
              activeTemplate.white || "border-0"
            } cursor-pointer border-[#3375F5] rounded`}
          />
          <img
            src={TemplateBlackImage}
            alt="template-black"
            width={150}
            onClick={() =>
              onClickTemplate({ ...props.listTheme.black }, "black")
            }
            className={`${
              activeTemplate.black || "border-0"
            } cursor-pointer border-[#3375F5] rounded`}
          />
        </div>
        )}
      </div>
    );
  }

  function renderWebDisplay() {
    return (
      <div className="p-2 w-full">
        <div
          id="catalog-section-logo"
          className={`relative w-10 h-10 grid place-items-center bg-[#EEF1F6] cursor-pointer ${
            !props.theme.logo ? "border border-dashed border-[#3375F5]" : ""
          }`}
          onClick={() => onChangeSection("logo")}
        >
          {props.theme.logo && (
            <img
              src={props.theme.logo}
              alt={props.theme.logo}
              className="w-full h-full object-cover overflow-hidden"
            />
          )}
          {!props.theme.logo && <DefaultImage size={25} />}
          <div className="absolute top-px right-px bg-white p-1 rounded-full drop-shadow">
            <EditIcon size={13} color={"#3375F5"} />
          </div>
        </div>

        <div
          id="catalog-section-banner"
          className={`relative w-full h-64 mt-1 grid place-items-center bg-[#EEF1F6] cursor-pointer ${
            !props.theme.banner.length
              ? "border border-dashed border-[#3375F5]"
              : ""
          }`}
        >
          {props.theme.banner.length > 0 && (
            <SliderComponent images={props.theme.banner} />
          )}
          {!props.theme.banner.length && <DefaultImage size={25} />}
          <div
            className="absolute top-5 right-5 bg-white p-2 rounded-full drop-shadow"
            onClick={() => onChangeSection("banner")}
          >
            <EditIcon size={15} color={"#3375F5"} />
          </div>
        </div>

        <div
          id="catalog-section-product"
          className={`relative w-full grid p-3`}
          style={{ backgroundColor: props.theme.product.background_color }}
        >
          <div
            className={`font-semibold text-md uppercase tracking-wider`}
            style={{
              color: props.theme.product.title_color,
              fontFamily: props.theme.product.title_font,
            }}
          >
            {props.theme.product.title}
          </div>
          <div
            className={`font-normal text-xs my-1`}
            style={{
              color: props.theme.product.description_color,
              fontFamily: props.theme.product.description_font,
            }}
          >
            {props.theme.product.description}
          </div>
          <div className="flex items-center justify-between my-4">
            {Array.from({ length: 4 }).map((_, index) => (
              <div
                className="w-32 grid gap-2 items-center justify-center"
                key={`product-${index}`}
              >
                <div className="w-full h-24 p-10 grid place-items-center bg-[#EEF1F6] rounded">
                  <DefaultImage size={30} />
                </div>
                <div className="w-full grid gap-1 mt-2">
                  <div
                    className={`font-semibold text-xs`}
                    style={{
                      color: props.theme.product.product_color,
                      fontFamily: props.theme.product.product_font,
                    }}
                  >
                    Nama Produk
                  </div>
                  <div
                    className={`font-normal text-xs`}
                    style={{
                      color: props.theme.product.product_color,
                      fontFamily: props.theme.product.product_font,
                    }}
                  >
                    Rp 200.000
                  </div>
                  <div
                    className={`font-semibold text-xs`}
                    style={{
                      color: props.theme.product.product_color,
                      fontFamily: props.theme.product.product_font,
                    }}
                  >
                    Lihat detail
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div
            className="absolute top-3 right-5 bg-white p-2 rounded-full drop-shadow cursor-pointer"
            onClick={() => onChangeSection("produk")}
          >
            <EditIcon size={15} color={"#3375F5"} />
          </div>
        </div>

        <div
          id="catalog-section-footer"
          className={`relative w-full grid py-7 px-5`}
          style={{ backgroundColor: props.theme.footer.background_color }}
        >
          <div
            className={`font-semibold text-md uppercase tracking-wider`}
            style={{
              color: props.theme.footer.title_color,
              fontFamily: props.theme.footer.title_font,
            }}
          >
            {props.theme.footer.title}
          </div>
          <div
            className={`font-normal text-xs my-1`}
            style={{
              color: props.theme.footer.description_color,
              fontFamily: props.theme.footer.description_font,
            }}
          >
            {props.theme.footer.description}
          </div>

          <div
            className="absolute top-3 right-5 bg-white p-2 rounded-full drop-shadow cursor-pointer "
            onClick={() => onChangeSection("footer")}
          >
            <EditIcon size={15} color={"#3375F5"} />
          </div>
        </div>
      </div>
    );
  }

  function renderSectionLogo() {
    return (
      <div
        id="catalog-active-option-logo"
        className="rounded-2xl shadow-lg p-5 mt-5 grid gap-4 bg-[#FFF]"
      >
        <div className="text-[#1E1E1E] font-semibold text-md tracking-wider">
          Image Resource
        </div>
        {props.theme.logo && (
          <div className="relative w-24 h-24 grid place-items-center bg-[#EEF1F6]">
            <img
              src={props.theme.logo}
              alt={props.theme.logo}
              className="w-full h-full object-cover overflow-hidden"
            />
            <div
              className="absolute top-0 right-0 bg-black rounded-full drop-shadow cursor-pointer"
              onClick={onRemoveLogo}
            >
              <span className="text-[#FFF] text-xs p-2">X</span>
            </div>
          </div>
        )}
        <div
          className="flex items-center justify-center gap-1 lw-full h-10 cursor-pointer border border-[#3375F5] rounded"
          onClick={onClickUploadLogo}
        >
          <input
            ref={inputLogoFileRef}
            type="file"
            className="hidden"
            onChange={(e) => convertImageToBase64(e, "logo")}
            accept="image/png, image/jpg, image/jpeg"
          />
          <UploadIcon />
          <span className="text-m font-bold text-[#3375F5]">Upload Image</span>
        </div>
        <span className="text-xs font-normal text-[#3375F5]">
          Format yang didukung: jpeg, .jpg, .png.
        </span>
      </div>
    );
  }

  function renderSectionBanner() {
    return (
      <div
        id="catalog-active-option-banner"
        className="rounded-2xl shadow-lg p-5 mt-5 grid gap-4 bg-[#FFF]"
      >
        <div className="text-[#1E1E1E] font-semibold text-md tracking-wider">
          Image Resource
        </div>
        <div className="flex gap-1">
          {props.theme.banner.map((img, index) => (
            <div
              className="relative w-24 h-24 grid place-items-center bg-[#EEF1F6]"
              key={`banner-${index}`}
            >
              <img
                src={img}
                alt={img}
                className="w-full h-full object-cover overflow-hidden"
              />
              <div
                className="absolute top-0 right-0 bg-black rounded-full drop-shadow cursor-pointer"
                onClick={() => onRemoveBanner(index)}
              >
                <span className="text-[#FFF] text-xs p-2">X</span>
              </div>
            </div>
          ))}
        </div>
        <div
          className="flex items-center justify-center gap-1 w-full h-10 cursor-pointer border border-[#3375F5] rounded"
          onClick={onClickUploadBanner}
        >
          <input
            ref={inputBannerFileRef}
            type="file"
            className="hidden"
            onChange={(e) => convertImageToBase64(e, "banner")}
            accept="image/png, image/jpg, image/jpeg"
            multiple
          />
          <UploadIcon />
          <span className="text-m font-bold text-[#3375F5]">Upload Image</span>
        </div>
        <span className="text-xs font-normal text-[#3375F5]">
          Format yang didukung: jpeg, .jpg, .png.
        </span>
      </div>
    );
  }

  function renderSectionProduct() {
    return (
      <>
        {/* Background */}
        <div
          id="catalog-active-option-product-background"
          className="rounded-2xl shadow-lg p-5 mt-5 grid gap-4 bg-[#FFF]"
        >
          <div className="text-[#1E1E1E] font-semibold text-md tracking-wider capitalize">
            Background
          </div>

          {/* Color Background Section */}
          <div className="grid gap-2">
            <div className="text-[#000] text-xs font-normal">
              Color Background
            </div>
            <div className="relative flex items-center gap-1">
              <div
                className="w-12 h-11 rounded border border-gray-300 cursor-pointer"
                style={{
                  background: props.theme.product.background_color,
                }}
                onClick={() =>
                  setShowProductBackgroundColor(!showProductBackgroundColor)
                }
              />
              {showProductBackgroundColor && (
                <div className="absolute top-11 z-[2]">
                  <div
                    className="fixed top-0 right-0 bottom-0 left-0"
                    onClick={() => setShowProductBackgroundColor(false)}
                  ></div>
                  <ChromePicker
                    color={props.theme.product.background_color}
                    onChange={(color) =>
                      onSetTheme("background_color", color.hex, "product")
                    }
                    onChangeComplete={(color) =>
                      onSetTheme("background_color", color.hex, "product")
                    }
                  />
                </div>
              )}
              <input
                type="text"
                value={props.theme.product.background_color}
                onChange={(e) =>
                  onSetTheme("background_color", e.target.value, "product")
                }
                className="input w-full bg-transparent border-[#3375F5] text-black"
              />
            </div>
          </div>
        </div>

        {/* Title */}
        <div
          id="catalog-active-option-product-title"
          className="rounded-2xl shadow-lg p-5 mt-5 grid gap-4 bg-[#FFF]"
        >
          <div className="text-[#1E1E1E] font-semibold text-md tracking-wider capitalize">
            Judul
          </div>

          {/* Title Text Section */}
          <div className="grid gap-2">
            <div className="text-[#000] text-xs font-normal">Text Judul</div>

            <input
              type="text"
              value={props.theme.product.title}
              onChange={(e) => onSetTheme("title", e.target.value, "product")}
              className="input w-full bg-transparent border-[#3375F5] text-black"
            />
          </div>

          {/* Font Text Section */}
          <div className="grid gap-2">
            <div className="text-[#000] text-xs font-normal">
              Font Text Judul
            </div>

            <select
              name="fontFamily"
              className="select bg-transparent border-[#2F80ED] w-full text-sm"
              style={{ fontFamily: props.theme.product.title_font }}
              value={props.theme.product.title_font}
              onChange={(e) =>
                onSetTheme("title_font", e.target.value, "product")
              }
            >
              <option value="" hidden>
                Choose Font
              </option>
              {renderFontFamily()}
            </select>
          </div>

          {/* Color Text Section */}
          <div className="grid gap-2">
            <div className="text-[#000] text-xs font-normal">
              Color Text Judul
            </div>
            <div className="relative flex items-center gap-1">
              <div
                className="w-12 h-11 rounded border border-gray-300 cursor-pointer"
                style={{
                  background: props.theme.product.title_color,
                }}
                onClick={() => setShowProductTitleColor(!showProductTitleColor)}
              />
              {showProductTitleColor && (
                <div className="absolute top-11 z-[2]">
                  <div
                    className="fixed top-0 right-0 bottom-0 left-0"
                    onClick={() => setShowProductTitleColor(false)}
                  ></div>
                  <ChromePicker
                    color={props.theme.product.title_color}
                    onChange={(color) =>
                      onSetTheme("title_color", color.hex, "product")
                    }
                    onChangeComplete={(color) =>
                      onSetTheme("title_color", color.hex, "product")
                    }
                  />
                </div>
              )}
              <input
                type="text"
                value={props.theme.product.title_color}
                onChange={(e) =>
                  onSetTheme("title_color", e.target.value, "product")
                }
                className="input w-full bg-transparent border-[#3375F5] text-black"
              />
            </div>
          </div>
        </div>

        {/* Description */}
        <div
          id="catalog-active-option-product-description"
          className="rounded-2xl shadow-lg p-5 mt-5 grid gap-4 bg-[#FFF]"
        >
          <div className="text-[#1E1E1E] font-semibold text-md tracking-wider capitalize">
            Description
          </div>

          {/* Title Text Section */}
          <div className="grid gap-2">
            <div className="text-[#000] text-xs font-normal">
              Text Deskripsi
            </div>

            <textarea
              type="text"
              value={props.theme.product.description}
              onChange={(e) =>
                onSetTheme("description", e.target.value, "product")
              }
              className="input w-full h-20 bg-transparent border-[#3375F5] text-black"
              row={10}
            />
          </div>

          {/* Font Text Section */}
          <div className="grid gap-2">
            <div className="text-[#000] text-xs font-normal">
              Font Text Deskripsi
            </div>

            <select
              name="fontFamily"
              className="select bg-transparent border-[#2F80ED] w-full text-sm"
              style={{
                fontFamily: props.theme.product.description_font,
              }}
              value={props.theme.product.description_font}
              onChange={(e) =>
                onSetTheme("description_font", e.target.value, "product")
              }
            >
              <option value="" hidden>
                Choose Font
              </option>
              {renderFontFamily()}
            </select>
          </div>

          {/* Color Text Section */}
          <div className="grid gap-2">
            <div className="text-[#000] text-xs font-normal">
              Color Text Deskripsi
            </div>
            <div className="relative flex items-center gap-1">
              <div
                className="w-12 h-11 rounded border border-gray-300 cursor-pointer"
                style={{
                  background: props.theme.product.description_color,
                }}
                onClick={() =>
                  setShowProductDescriptionColor(!showProductDescriptionColor)
                }
              />
              {showProductDescriptionColor && (
                <div className="absolute top-11 z-[2]">
                  <div
                    className="fixed top-0 right-0 bottom-0 left-0"
                    onClick={() => setShowProductDescriptionColor(false)}
                  ></div>
                  <ChromePicker
                    color={props.theme.product.description_color}
                    onChange={(color) =>
                      onSetTheme("description_color", color.hex, "product")
                    }
                    onChangeComplete={(color) =>
                      onSetTheme("description_color", color.hex, "product")
                    }
                  />
                </div>
              )}
              <input
                type="text"
                value={props.theme.product.description_color}
                onChange={(e) =>
                  onSetTheme("description_color", e.target.value, "product")
                }
                className="input w-full bg-transparent border-[#3375F5] text-black"
              />
            </div>
          </div>
        </div>

        {/* Product */}
        <div
          id="catalog-active-option-product-description"
          className="rounded-2xl shadow-lg p-5 mt-5 grid gap-4 bg-[#FFF]"
        >
          <div className="text-[#1E1E1E] font-semibold text-md tracking-wider capitalize">
            Produk
          </div>

          {/* Font Text Section */}
          <div className="grid gap-2">
            <div className="text-[#000] text-xs font-normal">
              Font Text Produk
            </div>

            <select
              name="fontFamily"
              className="select bg-transparent border-[#2F80ED] w-full text-sm"
              style={{ fontFamily: props.theme.product.product_font }}
              value={props.theme.product.product_font}
              onChange={(e) =>
                onSetTheme("product_font", e.target.value, "product")
              }
            >
              <option value="" hidden>
                Choose Font
              </option>
              {renderFontFamily()}
            </select>
          </div>

          {/* Color Text Section */}
          <div className="grid gap-2">
            <div className="text-[#000] text-xs font-normal">
              Color Text Produk
            </div>
            <div className="relative flex items-center gap-1">
              <div
                className="w-12 h-11 rounded border border-gray-300 cursor-pointer"
                style={{
                  background: props.theme.product.product_color,
                }}
                onClick={() => setShowProductColor(!showProductColor)}
              />
              {showProductColor && (
                <div className="absolute top-11 z-[2]">
                  <div
                    className="fixed top-0 right-0 bottom-0 left-0"
                    onClick={() => setShowProductColor(false)}
                  ></div>
                  <ChromePicker
                    color={props.theme.product.product_color}
                    onChange={(color) =>
                      onSetTheme("product_color", color.hex, "product")
                    }
                    onChangeComplete={(color) =>
                      onSetTheme("product_color", color.hex, "product")
                    }
                  />
                </div>
              )}
              <input
                type="text"
                value={props.theme.product.product_color}
                onChange={(e) =>
                  onSetTheme("product_color", e.target.value, "product")
                }
                className="input w-full bg-transparent border-[#3375F5] text-black"
              />
            </div>
          </div>
        </div>
      </>
    );
  }

  function renderSectionFooter() {
    return (
      <>
        {/* Background */}
        <div
          id="catalog-active-option-footer-background"
          className="rounded-2xl shadow-lg p-5 mt-5 grid gap-4 bg-[#FFF]"
        >
          <div className="text-[#1E1E1E] font-semibold text-md tracking-wider capitalize">
            Background
          </div>

          {/* Color Background Section */}
          <div className="grid gap-2">
            <div className="text-[#000] text-xs font-normal">
              Color Background
            </div>
            <div className="relative flex items-center gap-1">
              <div
                className="w-12 h-11 rounded border border-gray-300 cursor-pointer"
                style={{
                  background: props.theme.footer.background_color,
                }}
                onClick={() =>
                  setShowFooterBackgroundColor(!showFooterBackgroundColor)
                }
              />
              {showFooterBackgroundColor && (
                <div className="absolute top-11 z-[2]">
                  <div
                    className="fixed top-0 right-0 bottom-0 left-0"
                    onClick={() => setShowFooterBackgroundColor(false)}
                  ></div>
                  <ChromePicker
                    color={props.theme.footer.background_color}
                    onChange={(color) =>
                      onSetTheme("background_color", color.hex, "footer")
                    }
                    onChangeComplete={(color) =>
                      onSetTheme("background_color", color.hex, "footer")
                    }
                  />
                </div>
              )}
              <input
                type="text"
                value={props.theme.footer.background_color}
                onChange={(e) =>
                  onSetTheme("background_color", e.target.value, "footer")
                }
                className="input w-full bg-transparent border-[#3375F5] text-black"
              />
            </div>
          </div>
        </div>

        {/* Title */}
        <div
          id="catalog-active-option-footer-title"
          className="rounded-2xl shadow-lg p-5 mt-5 grid gap-4 bg-[#FFF]"
        >
          <div className="text-[#1E1E1E] font-semibold text-md tracking-wider capitalize">
            Judul
          </div>

          {/* Title Text Section */}
          <div className="grid gap-2">
            <div className="text-[#000] text-xs font-normal">Text Judul</div>

            <input
              type="text"
              value={props.theme.footer.title}
              onChange={(e) => onSetTheme("title", e.target.value, "footer")}
              className="input w-full bg-transparent border-[#3375F5] text-black"
            />
          </div>

          {/* Font Text Section */}
          <div className="grid gap-2">
            <div className="text-[#000] text-xs font-normal">
              Font Text Judul
            </div>

            <select
              name="fontFamily"
              className="select bg-transparent border-[#2F80ED] w-full text-sm"
              style={{ fontFamily: props.theme.footer.title_font }}
              value={props.theme.footer.title_font}
              onChange={(e) =>
                onSetTheme("title_font", e.target.value, "footer")
              }
            >
              <option value="" hidden>
                Choose Font
              </option>
              {renderFontFamily()}
            </select>
          </div>

          {/* Color Text Section */}
          <div className="grid gap-2">
            <div className="text-[#000] text-xs font-normal">
              Color Text Judul
            </div>
            <div className="relative flex items-center gap-1">
              <div
                className="w-12 h-11 rounded border border-gray-300 cursor-pointer"
                style={{
                  background: props.theme.footer.title_color,
                }}
                onClick={() => setShowFooterTitleColor(!showFooterTitleColor)}
              />
              {showFooterTitleColor && (
                <div className="absolute top-11 z-[2]">
                  <div
                    className="fixed top-0 right-0 bottom-0 left-0"
                    onClick={() => setShowFooterTitleColor(false)}
                  ></div>
                  <ChromePicker
                    color={props.theme.footer.title_color}
                    onChange={(color) =>
                      onSetTheme("title_color", color.hex, "footer")
                    }
                    onChangeComplete={(color) =>
                      onSetTheme("title_color", color.hex, "footer")
                    }
                  />
                </div>
              )}
              <input
                type="text"
                value={props.theme.footer.title_color}
                onChange={(e) =>
                  onSetTheme("title_color", e.target.value, "footer")
                }
                className="input w-full bg-transparent border-[#3375F5] text-black"
              />
            </div>
          </div>
        </div>

        {/* Description */}
        <div
          id="catalog-active-option-footer-description"
          className="rounded-2xl shadow-lg p-5 mt-5 grid gap-4 bg-[#FFF]"
        >
          <div className="text-[#1E1E1E] font-semibold text-md tracking-wider capitalize">
            Description
          </div>

          {/* Title Text Section */}
          <div className="grid gap-2">
            <div className="text-[#000] text-xs font-normal">
              Text Deskripsi
            </div>

            <textarea
              type="text"
              value={props.theme.footer.description}
              onChange={(e) =>
                onSetTheme("description", e.target.value, "footer")
              }
              className="input w-full h-28 bg-transparent border-[#3375F5] text-black"
              row={10}
            />
          </div>

          {/* Font Text Section */}
          <div className="grid gap-2">
            <div className="text-[#000] text-xs font-normal">
              Font Text Deskripsi
            </div>

            <select
              name="fontFamily"
              className="select bg-transparent border-[#2F80ED] w-full text-sm"
              style={{
                fontFamily: props.theme.footer.description_font,
              }}
              value={props.theme.footer.description_font}
              onChange={(e) =>
                onSetTheme("description_font", e.target.value, "footer")
              }
            >
              <option value="" hidden>
                Choose Font
              </option>
              {renderFontFamily()}
            </select>
          </div>

          {/* Color Text Section */}
          <div className="grid gap-2">
            <div className="text-[#000] text-xs font-normal">
              Color Text Deskripsi
            </div>
            <div className="relative flex items-center gap-5">
              <div
                className="w-12 h-11 rounded border border-gray-300 cursor-pointer"
                style={{
                  background: props.theme.footer.description_color,
                }}
                onClick={() =>
                  setShowFooterDescriptionColor(!showFooterDescriptionColor)
                }
              />
              {showFooterDescriptionColor && (
                <div className="absolute top-11 z-[2]">
                  <div
                    className="fixed top-0 right-0 bottom-0 left-0"
                    onClick={() => setShowFooterDescriptionColor(false)}
                  ></div>
                  <ChromePicker
                    color={props.theme.footer.description_color}
                    onChange={(color) =>
                      onSetTheme("description_color", color.hex, "footer")
                    }
                    onChangeComplete={(color) =>
                      onSetTheme("description_color", color.hex, "footer")
                    }
                  />
                </div>
              )}
              <input
                type="text"
                value={props.theme.footer.description_color}
                onChange={(e) =>
                  onSetTheme("description_color", e.target.value, "footer")
                }
                className="input w-full bg-transparent border-[#3375F5] text-black"
              />
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <Fragment>
      <div id="left-content" className="flex w-2/3">
        {renderWebDisplay()}
      </div>
      <div
        id="right-content"
        className="flex-1 p-2 bg-[#F8FBFF] rounded-ss-2xl h-screen"
      >
        <div id="catalog-action-menus" className="flex-1 text-right">
          <button
            className="flex-1 h-10 w-1/2 px-2 m-1 text-sm text-[#3375F5] transition-colors duration-150 border border-[#3375F5] rounded focus:shadow-outline hover:border-[#3375F5]/90 hover:opacity-90 uppercase"
            onClick={() => props.onClickPreview("home")}
          >
            Preview Design
          </button>
        </div>

        <div
          id="catalog-share-link"
          className="rounded-2xl shadow-lg p-5 mt-5 grid gap-4 bg-[#FFF]"
        >
          <div className="text-[#1E1E1E] font-semibold text-md tracking-wider">
            Share Link
          </div>
          <button
            className="inline-flex items-center justify-center gap-1 h-10 px-5 text-[#128C7E] transition-colors duration-150 border border-[#128C7E] rounded focus:shadow-outline hover:border-[#0b7065]/90 hover:opacity-90"
            onClick={onShareToWhatsApp}
          >
            <span>WhatsApp</span>
            <WhatsAppIcon />
          </button>
          <div className="text-[#3D5A80] font-normal text-xs text-center">
            atau bagikan link
          </div>
          <div className="flex items-center justify-between">
            <span className="text-xs">{`https://link.awandigital.id/${uniqueLink}`}</span>
            {renderGeneratedLinkButton()}
          </div>
        </div>

        {renderTemplate()}

        {activeSection && (
          <div className="mt-5 pb-60">
            <div id="catalog-active-option" className="p-1">
              <span className="text-[#1e1e1e]/[.50]">Section : </span>
              <b className="capitalize">{activeSection}</b>
            </div>

            {activeSection.toLowerCase() === "logo" && renderSectionLogo()}

            {activeSection.toLowerCase() === "banner" && renderSectionBanner()}

            {activeSection.toLowerCase() === "produk" && renderSectionProduct()}

            {activeSection.toLowerCase() === "footer" && renderSectionFooter()}
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default CatalogHome;
