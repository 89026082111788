import React, { Fragment, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FaEnvelope, FaLock, FaEye, FaEyeSlash } from "react-icons/fa";
import { forgotPasswordSSO, resetPasswordSSO, authResetPassword } from "../../app/service/api.service";
import SliderComponent from "../../app/components/Slider";
import AlertComponent from './../../app/components/Alert';
import SocialIcons from "../../assets/json/social.json";
import WebtokooImg from "../../assets/icon/logo/webtokoo-icon.svg";
import LogoImg from "../../assets/icon/logo/webtokoo-logo.svg";

function ForgotPassword() {
  const navigate = useNavigate();
  const { token } = useParams();
  const [searchParams] = useSearchParams();
  const [isLoading, setLoading] = useState(false);
  const [alertOption, setAlertOption] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { register, trigger, formState: { errors }, getValues, handleSubmit } = useForm();

  const onSubmit = (data) => {
    if (isLoading) return;

    if (token && searchParams.get("email")) {
      onSetNewPassword({ ...data, email: searchParams.get("email") });
    } else {
      onForgotPassword(data?.email);
    }
  };

  const onForgotPassword = async (email = "") => {
    try {
      setLoading(true);
      await forgotPasswordSSO(email);

      setLoading(false);
      setAlertOption({ type: "success", message: "Please check your email." });
    } catch (error) {
      setLoading(false);
      setAlertOption({ type: "error", message: error });
      console.log("Error onForgotPassword:", error);
    }
  }

  const onSetNewPassword = async (data) => {
    try {
      const { email, password, confirmPassword } = data;
      const requestSSO = {
        token: token,
        email: email,
        password: password,
        password_confirmation: confirmPassword,
      }

      setLoading(true);
      await resetPasswordSSO(requestSSO);

      const response = await authResetPassword({ email, password });
      if (!response.success) throw response.message;

      setLoading(false);
      setAlertOption({ type: "success", message: response.message });
      setTimeout(() => navigate("/login"), 2000);
    } catch (error) {
      setLoading(false);
      setAlertOption({ type: "error", message: error });
      console.log("Error onSetNewPassword:", error);
    }
  }

  function renderSocialIcon() {
    return SocialIcons.map((item, index) => {
      return (
        <a key={index} href={item.link} target="_blank" rel="noreferrer">
          <img src={require(`../../assets/icon/login/${item.icon}`)} alt={item.alt} />
        </a>
      )
    })
  }

  return (
    <div className="h-screen lg:flex">
      <div className="hidden h-screen lg:w-[380px] lg:bg-[#27AE61] lg:flex lg:flex-col lg:justify-center lg:px-8">
        <img src={WebtokooImg} className="w-12" style={{ filter: "drop-shadow(0 0 10px rgba(255, 255, 255, 0.4))" }} alt="" />
        <SliderComponent />
        <div className="flex">{renderSocialIcon()}</div>
      </div>
      <div className="lg:w-[calc(100%-380px)] select-none">
        <AlertComponent
          type={alertOption.type}
          message={alertOption.message}
          onClose={() => setAlertOption({ message: "" })}
        />
        <div className="p-8">
          <Link to="/"><img src={LogoImg} alt="" className="w-24" /></Link>
        </div>
        <div className="h-[calc(100%-96.14px)] flex justify-center items-center">
          <form className="w-[480px] text-[#7D818B]" onSubmit={handleSubmit(onSubmit)}>
            <div className="text-center mb-6">
              <h3 className="text-[#0F1628] text-4xl">{!token ? "Reset Password" : "Your New Password"}</h3>
              <span className="text-base">Kami bantu pulihkan akunmu kembali</span>
            </div>
            {!token &&
              <div className="mb-5">
                <label className="text-sm">Email</label>
                <div className="relative">
                  <FaEnvelope className="absolute top-4 left-4 w-4 h-4 text-[#5557DB]" />
                  <input
                    placeholder="mail@website.com"
                    className="input w-full bg-transparent border-[#7D818B] pl-[50px] text-sm"
                    {...register("email", { required: "Email is required!", pattern: { value: /\S+@\S+\.\S+/, message: "Invalid email address!" } })}
                  />
                </div>
                {errors.email && <div className="text-red-500 -mb-5 py-1">{errors.email?.message}</div>}
              </div>
            }
            {token &&
              <Fragment>
                <div className="mb-5">
                  <label className="text-sm">New Password</label>
                  <div className="relative">
                    <FaLock className="absolute top-4 left-4 w-4 h-4 text-[#5557DB]" />
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="Input New Password"
                      className="input w-full bg-transparent border-[#7D818B] pl-[50px] text-sm"
                      {...register("password", {
                        required: "New password is required!",
                        validate: (val) => val === getValues("confirmPassword") || "New password do not match with Confirm password!",
                        onChange: () => { trigger("password"); trigger("confirmPassword"); }
                      })}
                    />
                    <span className="absolute top-3 right-4 p-1">
                      {showPassword ?
                        <FaEye className="w-4 h-4 text-[#5557DB] cursor-pointer" onClick={() => setShowPassword(false)} />
                        :
                        <FaEyeSlash className="w-4 h-4 text-[#5557DB] cursor-pointer" onClick={() => setShowPassword(true)} />
                      }
                    </span>
                  </div>
                  {errors.password && <div className="text-red-500 -mb-5 py-1">{errors.password?.message}</div>}
                </div>
                <div className="mb-5">
                  <label className="text-sm">Confirm Password</label>
                  <div className="relative">
                    <FaLock className="absolute top-4 left-4 w-4 h-4 text-[#5557DB]" />
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder="Confirm Password"
                      className="input w-full bg-transparent border-[#7D818B] pl-[50px] text-sm"
                      {...register("confirmPassword", {
                        required: "Confirm password is required!",
                        validate: (val) => val === getValues("password") || "Confirm password do not match with New password!",
                        onChange: () => { trigger("password"); trigger("confirmPassword"); }
                      })}
                    />
                    <span className="absolute top-3 right-4 p-1">
                      {showConfirmPassword ?
                        <FaEye className="w-4 h-4 text-[#5557DB] cursor-pointer" onClick={() => setShowConfirmPassword(false)} />
                        :
                        <FaEyeSlash className="w-4 h-4 text-[#5557DB] cursor-pointer" onClick={() => setShowConfirmPassword(true)} />
                      }
                    </span>
                  </div>
                  {errors.confirmPassword && <div className="text-red-500 -mb-3 py-1">{errors.confirmPassword?.message}</div>}
                </div>
              </Fragment>
            }
            <div className="mt-6">
              <button
                type="submit"
                className={`
                  btn w-full capitalize bg-[#5557DB] border-none text-sm
                  text-white font-normal hover:bg-[#5557DB] hover:opacity-75
                `}
              >
                {isLoading && <span className="loading loading-spinner loading-sm"></span>}
                {!token ? "Kirim email pemulihan" : "Continue"}
              </button>
            </div>
            {!token &&
              <div className="mt-4 text-sm text-center">
                <span>Kembali ke</span>&nbsp;
                <Link to="/login" className="text-[#4D4EC5]">Login</Link>
              </div>
            }
          </form>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;