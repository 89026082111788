import moment from "moment";

export const getBase64 = file => {
  return new Promise(resolve => {
    let baseURL = "";
    // Make new FileReader
    let reader = new FileReader();
    // Convert the file to base64 text
    reader.readAsDataURL(file);
    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
};

export const getAllDatesInMonth = (month, year = new Date().getFullYear()) => {
  const dates = [];
  const lastDay = new Date(year, month + 1, 0).getDate();

  for (let day = 1; day <= lastDay; day++) {
    const newDate = new Date(year, month - 1, day);
    dates.push({
      origin: newDate,
      formatted: newDate.getDate()
      // formatted: `${newDate.getDate()} ${newDate.toLocaleString('id-ID', { month: 'long' })}`
    })
  }

  return dates;
}

export const addedDays = (date, days) => {
  if (!date && !days) return;

  const initialDate = new Date(date);
  const newDate = new Date(initialDate.getTime() + (days * 24 * 60 * 60 * 1000));
  return newDate.toISOString().slice(0, 19).replace("T", " ");
}

export const periodLicenseValidator = (periodLicense) => {
  if (!periodLicense) return;

  moment.locale("id");
  const startDate = moment();
  const endDate = moment(periodLicense);
  const isAfterStartDate = endDate.isAfter(startDate);
  const differenceDay = moment.duration(endDate.diff(startDate)).asDays();
  const rangeOfDay = isAfterStartDate ? Math.abs(Math.round(differenceDay)) : 0;
  return rangeOfDay;
}

export const ChunksArray = (array, chunkSize) => {
  const chunks = [];

  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    chunks.push(chunk);
  }

  return chunks;
}