import { Carousel } from "flowbite-react";

function SliderComponent(props) {
  if (!props?.images?.length) return;

  const rounded = props?.rounded ? "rounded" : "";

  const customTheme = {
    scrollContainer: {
      base: `flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll scroll-smooth ${rounded}`,
      snap: "snap-x"
    }
  };

  return (
    <Carousel theme={customTheme} indicators={false}>
      {props?.images?.map((image, index) => (
        <img key={index} src={image} className="w-full h-full object-cover object-center" />
      ))}
    </Carousel>
  );
}

export default SliderComponent;
