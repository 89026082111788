import { Outlet } from "react-router-dom";
import Sider from "./Sider";
import Header from "./Header";

function MainLayout() {
  return (
    <div id="main" className="bg-white h-screen flex">
      <Sider />
      <Header>
        <Outlet />
      </Header>
    </div>
  )
}

export default MainLayout;