import { BrowserRouter } from 'react-router-dom';
import WebContextProvider from './app/Provider';
import RouteManager from './router/RouteManager';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  return (
    <WebContextProvider>
      <BrowserRouter>
        <RouteManager />
      </BrowserRouter>
    </WebContextProvider>
  );
}

export default App;
