import { useEffect, useState } from "react";
import { HiBuildingOffice, HiUsers } from "react-icons/hi2";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useWebContext } from "../../app/Provider";
import { getDashboard } from "../../app/service/api.service";
import CardCount from "../../app/components/CardCount";
import LoadingPageComponent from "../../app/components/LoadingPage";
import { getAllDatesInMonth } from "../../app/utils/utils";

const monthsDefault = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

function Dashboard() {
  const [isLoading, setIsLoading] = useState(false);
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [dashboard, setDashboard] = useState({});
  const { store } = useWebContext();
  const userDetail = store.userDetail;

  const options = {
    chart: {
      type: 'spline',
    },
    credits: {
      enabled: false
    },
    title: {
      text: undefined
    },
    xAxis: {
      categories: getAllDatesInMonth(month),
      labels: {
        formatter: function () {
          return this.value.formatted;
        }
      },
      rotation: 45, // Rotate the labels for better readability
      overflow: 'right'
    },
    yAxis: {
      title: {
        text: 'Number of Visit / Click'
      },
      min: 0,
      max: 1000,
      tickInterval: 200,
    },
    tooltip: {
      headerFormat: null
    },
    series: [
      { name: "Daily Visit", data: dashboard.dailyVisit },
      { name: "Daily Click", data: dashboard.dailyClick }
    ]
  };

  useEffect(() => {
    setMonth(new Date().getMonth() + 1);
  }, []);

  useEffect(() => {
    onGetDashboard();
  }, [month])

  const onGetDashboard = async () => {
    try {
      const request = {
        userId: userDetail.userId,
        months: month
      }

      setIsLoading(true);
      const response = await getDashboard(request);
      if (!response.success) throw response.message;

      setIsLoading(false);
      generateDailyData(response);
      setDashboard({
        dailyVisit: generateDailyData('visit', response.data),
        dailyVisitTotal: response.data.monthlyVisitTotal,
        dailyClick: generateDailyData('click', response.data),
        dailyClickTotal: response.data.monthlyClickTotal
      });
    } catch (error) {
      setIsLoading(false);
      console.log("Error on onGetDashboard: ", error);
    }
  }

  const generateDailyData = (type, data) => {
    const days = getAllDatesInMonth(month);
    if (type === "visit") {
      return days.map(({ origin }) => {
        const filter = data.monthlyVisit.filter(({ action_date }) => {
          return (new Date(origin).setHours(0, 0, 0, 0) === new Date(action_date).setHours(0, 0, 0, 0));
        });
        return filter.length;
      })
    } else if (type === "click") {
      return days.map(({ origin }) => {
        const filter = data.monthlyClick.filter(({ action_date }) => {
          return (new Date(origin).setHours(0, 0, 0, 0) === new Date(action_date).setHours(0, 0, 0, 0));
        });
        return filter.length;
      })
    }
    return days.map(() => 0);
  }

  return (
    <div className="p-6">
      <LoadingPageComponent isLoading={isLoading} />
      <div className="mb-5 text-black text-2xl font-bold">Dashboard</div>
      <div className="grid gap-6 md:grid-cols-4 grid-cols-1 grid-rows-1">
        <CardCount
          title="Total Visit"
          value={dashboard?.dailyVisitTotal || 0}
          icon={<HiBuildingOffice size={50} className="text-lg text-blue-500" />}
          color="blue"
          borderLeft="border-l-8 border-l-teal-600"
        />
        <CardCount
          title="Total Click"
          value={dashboard?.dailyClickTotal || 0}
          icon={<HiUsers size={50} className="text-lg text-blue-500" />}
          color="blue"
          borderLeft="border-l-8 border-l-yellow-400"
        />
      </div>
      <div className="w-full">
        <div className="flex justify-end items-center gap-3 mb-5">
          <div className="font-bold">Bulan:</div>
          <select value={month} className="select select-ghost w-36 bg-transparent border-gray-300" onChange={(e) => setMonth(e.target.value)}>
            {monthsDefault.map((label, index) => (
              <option key={index} value={index + 1}>{label}</option>
            ))}
          </select>
        </div>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
    </div>
  )
}

export default Dashboard;