import { useEffect, useState } from "react";
import { AiOutlineEdit, AiOutlineDelete, AiOutlineClose } from "react-icons/ai";
import { getLicenseList, createLicense, updateLicense, deleteLicense } from "../../app/service/api.service";
import TableComponent from "../../app/components/TableComponent";
import DialogComponent from "../../app/components/DialogComponent";
import AlertComponent from "../../app/components/Alert";

function License() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [alertOption, setAlertOption] = useState({});
  const [licenseList, setLicenseList] = useState([]);
  const [licenseId, setLicenseId] = useState();
  const [licenseName, setLicenseName] = useState();
  const [actionType, setActionType] = useState();

  const columns = [
    { key: "id", title: "id" },
    { key: "license", title: "license" },
    {
      key: "action",
      title: "action",
      render: (_, item) => (
        <div className="flex justify-center items-center gap-3">
          <div
            data-tip="Edit License"
            className={`
              tooltip tooltip-bottom py-2 px-2 border border-slate-300
              rounded-lg cursor-pointer hover:bg-slate-200 duration-200
            `}
            onClick={() => {
              window.licenseform_crud.showModal();
              setActionType("update");
              setLicenseId(item.id);
              setLicenseName(item.license);
            }}
          >
            <AiOutlineEdit size={16} />
          </div>
          <div
            data-tip="Delete License"
            className={`
              tooltip tooltip-bottom py-2 px-2 border border-slate-300
              rounded-lg cursor-pointer hover:bg-slate-200 duration-200
            `}
            onClick={() => {
              setOpenDialog(true);
              setLicenseId(item.id);
            }}
          >
            <AiOutlineDelete size={16} />
          </div>
        </div>
      )
    }
  ]

  useEffect(() => {
    onGetListLicense();
  }, []);

  const onGetListLicense = async () => {
    try {
      setIsLoading(true);
      const response = await getLicenseList();
      if (!response.success) throw response.message;

      setIsLoading(false);
      setLicenseList(response.data);
    } catch (error) {
      setIsLoading(false);
      console.log("Error on onGetListWebPage: ", error);
    }
  }

  const onActionCRUD = (e) => {
    e?.preventDefault();
    switch (actionType) {
      case "create":
        onCreateLicense();
        break;
      case "update":
        onUpdateLicense()
        break;
    }
  }

  const onCreateLicense = async () => {
    try {
      setIsSubmitLoading(true);
      const response = await createLicense({ licenseName });
      if (!response.success) throw response.message;

      setIsSubmitLoading(false);
      setAlertOption({ type: "success", message: response.message });
      window.licenseform_crud.close();
      onGetListLicense();
    } catch (error) {
      setIsSubmitLoading(false);
      setAlertOption({ type: "error", message: error });
      console.log("Error on onGetListWebPage: ", error);
    }
  }

  const onUpdateLicense = async () => {
    try {
      setIsSubmitLoading(true);
      const response = await updateLicense({ id: licenseId, licenseName });
      if (!response.success) throw response.message;

      setIsSubmitLoading(false);
      setAlertOption({ type: "success", message: response.message });
      window.licenseform_crud.close();
      onGetListLicense();
    } catch (error) {
      setIsSubmitLoading(false);
      setAlertOption({ type: "error", message: error });
      console.log("Error on onGetListWebPage: ", error);
    }
  }

  const onDeleteLicense = async () => {
    try {
      setIsSubmitLoading(true);
      const response = await deleteLicense({ id: licenseId });
      if (!response.success) throw response.message;

      setIsSubmitLoading(false);
      setOpenDialog(false);
      setAlertOption({ type: "success", message: response.message });
      onGetListLicense();
    } catch (error) {
      setIsSubmitLoading(false);
      setAlertOption({ type: "error", message: error });
      console.log("Error on onGetListWebPage: ", error);
    }
  }

  return (
    <div className="p-6">
      <AlertComponent
        type={alertOption.type}
        message={alertOption.message}
        onClose={() => setAlertOption({ message: "" })}
      />
      <div className="mb-5 text-black text-2xl font-bold">License</div>
      <div className="w-full text-right mb-5">
        <button
          className="btn px-10 bg-[#5557DB] border-none text-sm text-white font-normal hover:bg-[#5557DB] hover:opacity-75"
          onClick={() => {
            setActionType("create");
            setLicenseName('');
            window.licenseform_crud.showModal();
          }}
        >
          Create
        </button>
      </div>
      <TableComponent
        loading={isLoading}
        columns={columns}
        dataSource={licenseList}
      />
      <DialogComponent
        open={openDialog}
        isLoading={isSubmitLoading}
        onConfirm={onDeleteLicense}
        onClose={setOpenDialog}
      />
      <dialog id="licenseform_crud" className="modal">
        <form className="modal-box">
          <div className="flex justify-end p-2 cursor-pointer mb-4" onClick={() => window.licenseform_crud.close()}>
            <AiOutlineClose size={16} />
          </div>
          <input
            className="input w-full bg-transparent border-[#3375F5] text-black mb-5"
            placeholder="Input License Name"
            value={licenseName}
            onChange={(e) => setLicenseName(e.target.value)}
          />
          <button
            className={`btn w-full capitalize bg-[#5557DB] border-none text-sm text-white font-normal hover:bg-[#5557DB] hover:opacity-75`}
            onClick={onActionCRUD}
          >
            {isSubmitLoading && <span className="loading loading-spinner loading-sm"></span>}
            {actionType}
          </button>
        </form>
      </dialog>
    </div>
  )
}

export default License;