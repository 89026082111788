import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FaEnvelope, FaLock, FaEyeSlash, FaEye } from "react-icons/fa";
import { useWebContext } from "../../app/Provider";
import { oauthTokenSSO, authLogin } from "../../app/service/api.service";
import SliderComponent from "../../app/components/Slider";
import AlertComponent from './../../app/components/Alert';
import WebtokooImg from "../../assets/icon/logo/webtokoo-icon.svg";
import LogoImg from "../../assets/icon/logo/webtokoo-logo.svg";
import SocialIcons from "../../assets/json/social.json";
import CacheService from "../../app/service/jwt.service";
import { periodLicenseValidator } from "../../app/utils/utils";

function Login() {
  const navigate = useNavigate();
  const { store, updateStore } = useWebContext();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [alertOption, setAlertOption] = useState({});
  const { register, formState: { errors }, handleSubmit } = useForm();
  const { userDetail } = store;

  useEffect(() => {
    const ssoToken = CacheService.getSSOToken();
    const localToken = CacheService.getLocalToken();

    if (ssoToken && localToken) {
      if (userDetail?.levelName === "user") {
        navigate("/dashboard");
      } else {
        navigate("/user/all");
      }
    }
    
    if (userDetail?.levelName?.toLowerCase() !== "superadmin") {
      const rangeOfDay = periodLicenseValidator(userDetail?.periodLicense);
      if (rangeOfDay <= 0) {
        setAlertOption({
          type: "error",
          message: 'Your period license has ended. Please contact administrator to extend.'
        });
      }
    }
  }, [userDetail]);

  const onLoginClick = async (data) => {
    try {
      if (isLoading) return;

      const requestSSO = {
        username: data.email,
        password: data.password,
        grant_type: "password",
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
      }

      setLoading(true);
      const responseSSO = await oauthTokenSSO(requestSSO);
      if (!responseSSO?.data?.access_token) throw "Your email or password is incorrect.";

      const response = await authLogin(data);
      if (!response?.success) throw "Your email or password is incorrect.";

      setLoading(false);
      updateStore({ userDetail: response.data });
      CacheService.saveSSOToken(responseSSO.data.access_token);
      CacheService.saveLocalToken(response.data.token);
    } catch (error) {
      setLoading(false);
      setAlertOption({ type: "error", message: error });
      console.log("Error on onLoginClick:", error);
    }
  }

  function renderSocialIcon() {
    return SocialIcons.map((item, index) => {
      return (
        <a key={index} href={item.link} target="_blank" rel="noreferrer">
          <img src={require(`../../assets/icon/login/${item.icon}`)} alt={item.alt} />
        </a>
      )
    })
  }

  return (
    <div className="h-screen lg:flex">
      <div className="hidden h-screen lg:w-[380px] lg:bg-[#5557DB] lg:flex lg:flex-col lg:justify-center lg:px-8">
        <img src={WebtokooImg} className="w-12" style={{ filter: "drop-shadow(0 0 10px rgba(255, 255, 255, 0.4))" }} alt="" />
        <SliderComponent />
        <div className="flex">{renderSocialIcon()}</div>
      </div>
      <div className="lg:w-[calc(100%-380px)] select-none">
        <AlertComponent
          type={alertOption.type}
          message={alertOption.message}
          onClose={() => setAlertOption({ message: "" })}
        />
        <div className="p-8">
          <Link to="/"><img src={LogoImg} alt="" className="w-24" /></Link>
        </div>
        <div className="h-[calc(100%-96.14px)] flex justify-center items-center">
          <form className="w-[480px] text-[#7D818B]" onSubmit={handleSubmit(onLoginClick)}>
            <div className="text-center mb-6">
              <h3 className="text-[#0F1628] text-4xl">Login</h3>
              <span className="text-base">Selamat datang kembali di Webtokoo</span>
            </div>
            <div className="mb-5">
              <label className="text-sm">Email</label>
              <div className="relative">
                <FaEnvelope className="absolute top-4 left-4 w-4 h-4 text-[#5557DB]" />
                <input
                  placeholder="Masukkan Email"
                  className="input w-full bg-transparent border-[#7D818B] pl-[50px] text-sm"
                  {...register("email", { required: "Email wajib diisi!", pattern: { value: /\S+@\S+\.\S+/, message: "Alamat email tidak sesuai!" } })}
                />
              </div>
              {errors.email && <div className="text-red-500 -mb-5 py-1">{errors.email?.message}</div>}
            </div>
            <div className="mb-3">
              <label className="text-sm">Password</label>
              <div className="relative">
                <FaLock className="absolute top-4 left-4 w-4 h-4 text-[#5557DB]" />
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Masukkan Password"
                  className="input w-full bg-transparent border-[#7D818B] pl-[50px] text-sm"
                  {...register("password", { required: "Password wajib diisi!" })}
                />
                <span className="absolute top-3 right-4 p-1">
                  {showPassword ?
                    <FaEye className="w-4 h-4 text-[#5557DB] cursor-pointer" onClick={() => setShowPassword(false)} />
                    :
                    <FaEyeSlash className="w-4 h-4 text-[#5557DB] cursor-pointer" onClick={() => setShowPassword(true)} />
                  }
                </span>
              </div>
              {errors.password && <div className="text-red-500 -mb-5 py-1">{errors.password?.message}</div>}
            </div>
            <div className="text-right text-[#4D4EC5] text-sm font-bold">
              <Link to="/forgot-password">Lupa Password?</Link>
            </div>
            <div className="mt-6">
              <button
                type="submit"
                className={`
                  btn w-full capitalize bg-[#5557DB] hover:bg-[#5557DB] border-none
                  text-sm text-white font-normal hover:opacity-75
                `}
              >
                {isLoading && <span className="loading loading-spinner loading-sm"></span>}
                Login
              </button>
            </div>
            <div className="mt-4 text-sm text-center">
              <span>Belum punya akun?</span>&nbsp;
              <Link to="/register" className="text-[#4D4EC5]">Daftar Sekarang</Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;