import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineCopy, AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { getWebPageList, deleteWebPage } from "../../app/service/api.service";
import TableComponent from "../../app/components/TableComponent";
import DialogComponent from "../../app/components/DialogComponent";
import AlertComponent from "../../app/components/Alert";

function ActivePageList() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [alertOption, setAlertOption] = useState({});
  const [webPageList, setWebPageList] = useState([]);
  const [webPageDetail, setWebPageDetail] = useState({});
  const [openDialog, setOpenDialog] = useState(false);

  const columns = [
    { key: "id", title: "id", render: (val, item, index) => index + 1 },
    { key: "category", title: "category" },
    { key: "unique_link", title: "unique link" },
    {
      key: "long_url",
      title: "url",
      render: (value) => <a target="_blank" rel="noopener noreferrer" href={value} className="text-blue-400">{value}</a>
    },
    {
      key: "is_active",
      title: "active",
      render: (value) => (
        <div className={`py-1 px-3 rounded-md text-xs ${value ? 'bg-green-200 text-green-500' : 'bg-red-200 text-red-500'}`}>
          {value ? "Active" : "Inactive"}
        </div>
      )
    },
    {
      key: "action",
      title: "action",
      render: (_, item) => (
        <div className="flex justify-center items-center gap-3">
          <div
            data-tip="Copy Link"
            className={`tooltip tooltip-bottom py-2 px-2 border border-slate-300 rounded-lg cursor-pointer hover:bg-slate-200 duration-200`}
            onClick={(e) => onCopyLink(e, item.long_url)}
          >
            <AiOutlineCopy size={16} />
          </div>
          <div
            data-tip="Edit MenuTree"
            className={`tooltip tooltip-bottom py-2 px-2 border border-slate-300 rounded-lg cursor-pointer hover:bg-slate-200 duration-200`}
            onClick={() => navigate(`/design-page/${item.category}/${item.id}`)}
          >
            <AiOutlineEdit size={16} />
          </div>
          <div
            data-tip="Delete MenuTree"
            className={`tooltip tooltip-bottom py-2 px-2 border border-slate-300 rounded-lg cursor-pointer hover:bg-slate-200 duration-200`}
            onClick={() => {
              setOpenDialog(true);
              setWebPageDetail(item);
            }}
          >
            <AiOutlineDelete size={16} />
          </div>
        </div>
      )
    }
  ]

  useEffect(() => {
    onGetListWebPage();
  }, []);

  const onGetListWebPage = async () => {
    try {
      setIsLoading(true);
      const response = await getWebPageList();
      if (!response.success) throw response.message;

      setIsLoading(false);
      setWebPageList(response.data);
    } catch (error) {
      setIsLoading(false);
      console.log("Error on onGetListWebPage: ", error);
    }
  }

  const onCopyLink = (e, longUrl) => {
    if (!longUrl) return;
    navigator.clipboard.writeText(longUrl);
    e.target.parentNode.dataset.tip = "link copied!";
  }

  const onDeleteWebPage = async () => {
    try {
      setIsSubmitLoading(true);
      const response = await deleteWebPage(webPageDetail.id);
      if (!response.success) throw response.message;

      setIsSubmitLoading(false);
      setOpenDialog(false);
      setAlertOption({ type: "success", message: response.message });
      onGetListWebPage();
    } catch (error) {
      setIsSubmitLoading(false);
      setOpenDialog(false);
      setAlertOption({ type: "error", message: error });
      console.log("Error on onDeleteWebPage: ", error);
    }
  }

  return (
    <div className="p-6">
      <AlertComponent
        type={alertOption.type}
        message={alertOption.message}
        onClose={() => setAlertOption({ message: "" })}
      />
      <div className="mb-5 text-black text-2xl font-bold">Active Page List</div>
      <TableComponent
        loading={isLoading}
        columns={columns}
        dataSource={webPageList}
      />
      <DialogComponent
        open={openDialog}
        isLoading={isSubmitLoading}
        onConfirm={onDeleteWebPage}
        onClose={setOpenDialog}
      />
    </div>
  )
}

export default ActivePageList;