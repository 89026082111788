import Slider from "react-slick";

function SliderComponent() {
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    animation: false,
    cssEase: 'ease',
    fade: true
  };

  return (
    <div className="slider-wrapper">
      <Slider {...settings}>
        <div className="text-white">
          <h3 className="text-2xl mb-3">Satu Integrasi Untuk Semua Kebutuhan Anda</h3>
          <span className="text-sm">Berbagai jenis aplikasi yang mendukung bisnis skala umkm dan insitusi besar juga terintegrasi dengan platform Awan Digital lainnya</span>
        </div>
        <div className="text-white">
          <h3 className="text-2xl mb-3">Pengeluaran Untuk Solusi Teknologi yang Lebih Cerdas</h3>
          <span className="text-sm">Fokus kepada bisnis inti dan hemat biaya pengembangan aplikasi berulang melalui solusi cloud dan bundling platform Awan Digital</span>
        </div>
        <div className="text-white">
          <h3 className="text-2xl mb-3">Experience Pelanggan Yang Lebih Baik</h3>
          <span className="text-sm">Berikan pengalaman terbaik kepada pelanggan dengan berbagai solusi Awan Digital</span>
        </div>
      </Slider>
    </div>
  );
}

export default SliderComponent;