import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useWebContext } from "../app/Provider";
import {
  AiOutlineHome, AiOutlineGlobal, AiOutlineUser, AiOutlineFund,
  AiOutlineSolution, AiOutlineIdcard, AiOutlineLock, AiOutlineFileText
} from "react-icons/ai";
import LogoImg from "../assets/icon/logo/webtokoo-logo.svg";

const MenuOption = [
  {
    key: "dashboard",
    title: "Dashboard",
    icon: <AiOutlineHome size="20" />,
    type: "menu",
    level: "user",
    license: "standard",
    link: "dashboard"
  },
  {
    title: "Web Page",
    icon: <AiOutlineGlobal size="20" />,
    type: "submenu",
    level: "user",
    submenu: [
      {
        key: "design-page",
        title: "Design Page",
        icon: <AiOutlineSolution size="20" />,
        type: "menu",
        level: "user",
        license: "standard",
        link: "design-page"
      },
      {
        key: "active-page",
        title: "Active Page",
        icon: <AiOutlineSolution size="20" />,
        type: "menu",
        level: "user",
        license: "standard",
        link: "active-page"
      },
    ]
  },
  {
    title: "Manajemen User",
    icon: <AiOutlineUser size="20" />,
    type: "submenu",
    license: "trial",
    submenu: [
      {
        key: "user",
        title: "Users",
        icon: <AiOutlineSolution size="20" />,
        type: "menu",
        level: "superadmin",
        license: "standard",
        link: "user/all"
      },
      {
        key: "profile",
        title: "Profile",
        icon: <AiOutlineSolution size="20" />,
        type: "menu",
        level: "all",
        license: "standard",
        link: "profile"
      },
      {
        key: "leve",
        title: "Level",
        icon: <AiOutlineIdcard size="20" />,
        type: "menu",
        level: "superadmin",
        license: "standard",
        link: "level"
      },
      {
        key: "license",
        title: "License",
        icon: <AiOutlineIdcard size="20" />,
        type: "menu",
        level: "superadmin",
        license: "standard",
        link: "license"
      },
      {
        key: "reset-password",
        title: "Reset Password",
        icon: <AiOutlineLock size="20" />,
        type: "menu",
        level: "all",
        license: "standard",
        link: "reset-password"
      }
    ]
  },
  {
    key: "report-visit",
    title: "Laporan",
    icon: <AiOutlineFund size="20" />,
    type: "menu",
    level: "user",
    license: "standard",
    link: "report"
  },
  {
    key: "report-survey",
    title: "Laporan Survey",
    icon: <AiOutlineFileText size="20" />,
    type: "menu",
    level: "user",
    license: "standard",
    link: "report/survey"
  }
]

function Sider() {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState(location.pathname.slice(1));
  const { store } = useWebContext();
  const userDetail = store.userDetail;

  useEffect(() => {
    if (store.activeMenu && store.activeMenu !== activeMenu) {
      setActiveMenu(store.activeMenu);
    }
  }, [store.activeMenu])

  const updateClassNode = (node) => {
    if (node?.classList) {
      // class up down toggle
      if (node.classList.contains("menu-dropdown-show")) {
        node.classList.remove('menu-dropdown-show');
      } else {
        node.classList.add('menu-dropdown-show');
      }
      if (node?.nextSibling?.classList) {
        // class animation dropdown show/hide
        if (node.nextSibling.classList.contains("visible")) {
          node.nextSibling.classList.replace("visible", "invisible");
          node.nextSibling.classList.replace("max-h-80", "max-h-0");
        } else {
          node.nextSibling.classList.replace("invisible", "visible");
          node.nextSibling.classList.replace("max-h-0", "max-h-80");
        }
      }
    }
  }

  const onOpenCloseMenu = (e, menu) => {
    if (menu?.key) {
      setActiveMenu(menu.key);
      navigate(`/${menu.link}`);
    }
    if (e.target.id) {
      updateClassNode(e.target);
    } else if (e.target.parentNode.id) {
      updateClassNode(e.target.parentNode);
    }
  }

  function renderMenuOption(options = []) {
    return options.map((menu, index) => {
      if ((menu.level) && !["all", userDetail.levelName].includes(menu.level)) return "";

      const submenu = menu.type === "submenu";
      let className = activeMenu.includes(menu.key) ? "text-blue-500" : "";
      let subClassName = "invisible max-h-0";
      if (submenu) {
        className = "menu-dropdown-toggle";
        if (menu.submenu.some(data => activeMenu.includes(data.key))) {
          className += " menu-dropdown-show";
          subClassName = "visible max-h-80";
        }
      }

      return (
        <li key={index} className="py-1">
          <div id={menu.type} className={"" + className} onClick={(e) => onOpenCloseMenu(e, menu)}>
            {menu.icon}<span>{menu.title}</span>
          </div>
          {submenu &&
            <ul className={"overflow-hidden transition-all duration-200 " + subClassName}>
              {renderMenuOption(menu.submenu)}
            </ul>
          }
        </li>
      )
    })
  }

  return (
    <div id="sider" className="lg:w-[256px] lg:border lg:divide-y">
      <div className="h-[80px] lg:p-6">
        <a href="/"><img src={LogoImg} alt="" /></a>
      </div>
      <ul className="menu py-6">
        {renderMenuOption(MenuOption)}
      </ul>
    </div>
  )
}

export default Sider;