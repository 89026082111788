import { useEffect } from "react";

function AlertComponent({ type = "success", message = "", className = "w-[calc(100%-380px)]", onClose }) {
  const config = {
    error: {
      bgColor: "bg-[#ED5565]",
      iconCode: "M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
    },
    success: {
      bgColor: "bg-[#27AE61]",
      iconCode: "M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
    }
  }

  useEffect(() => {
    setTimeout(() => {
      if (message && onClose) onClose(false);
    }, 3000);
  }, [message, onClose]);

  return (
    <div className={`fixed z-10 flex justify-center transition-all duration-500 ${message ? 'top-12 opacity-100' : '-top-12 opacity-0'} ${className}`}>
      <div className={`flex gap-2 items-center ${config?.[type]?.bgColor || ""} w-auto p-2 rounded-full text-white text-sm`}>
        <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={config[type].iconCode} />
        </svg>
        {message && <span className="leading-none mr-1">{message}</span>}
      </div>
    </div>
  );
}

export default AlertComponent;