import { Fragment, useEffect, useRef, useState } from "react";
import { AiOutlineCloseCircle, AiOutlineHolder, AiOutlinePlus, AiOutlineMore } from "react-icons/ai";
import { useWebContext } from "../../../app/Provider";
import { getBase64 } from './../../../app/utils/utils';
import { CopyLinkIcon, DoubleBoxIcon, TrashIcon, EditIcon, ArrowRightIcon, NextRightIcon } from "../../../assets/icon/IndexSvgIcon";

const ACTION_ADD = "ADD";
const ACTION_EDIT = "EDIT";

function MenuTreeLink(props) {
  const inputFileRef = useRef(undefined);
  const [maxTotalThumbnail, setMaxTotalThumbnail] = useState(0);
  const [showHideHeaderForm, setShowHideHeaderForm] = useState(false);
  const [showHideHeaderDeleteForm, setShowHideHeaderDeleteForm] = useState();
  const [showHideLinkForm, setShowHideLinkForm] = useState(false);
  const [showHideLinkDeleteForm, setShowHideLinkDeleteForm] = useState();
  const [showUploadThumnailForm, setShowUploadThumnailForm] = useState();
  const [headerText, setHeaderText] = useState("");
  const [linkLabel, setLinkLabel] = useState("");
  const [linkURL, setLinkURL] = useState("");
  const [headerActionType, setHeaderActionType] = useState(ACTION_ADD);
  const [linkActionType, setLinkActionType] = useState(ACTION_ADD);
  const [headerEditIdx, setHeaderEditIdx] = useState(0);
  const [linkHeaderEditIdx, setLinkHeaderEditIdx] = useState(0);
  const [linkEditIdx, setLinkEditIdx] = useState(0);
  const [showHideOtherAction, setshowHideOtherAction] = useState();
  const [showHideSubOtherAction, setshowHideSubOtherAction] = useState();
  const [uniqueLink, setUniqueLink] = useState();
  const [inputUniqueLink, setInputUniqueLink] = useState("");
  const { store } = useWebContext();
  const userDetail = store.userDetail;

  useEffect(() => {
    if (!props.uniqueLink) {
      setUniqueLink("xxxxxxxxxxxxxxxx");
      return;
    }
    setUniqueLink(props.uniqueLink);
  }, [props.uniqueLink])

  const onCopyLink = (e) => {
    if (!uniqueLink) return;
    navigator.clipboard.writeText(`https://link.awandigital.id/${uniqueLink}`);
    e.target.parentNode.dataset.tip = "link copied!";
  }

  const onAddHeader = () => {
    let contents = props.theme.contents || [];
    const isExist = contents.some(item => item.header.toLowerCase() === headerText.toLowerCase());
    if (isExist) {
      console.log("Exist");
      setHeaderText("");
      setShowHideHeaderForm(false);
      return;
    }
    if (headerText) {
      contents.push({
        id: Math.floor(Math.random() * 101),
        header: headerText,
        isActive: true,
        links: []
      })
    }
    setHeaderText("");
    setShowHideHeaderForm(false);
    setHeaderActionType(ACTION_ADD);
    props.onSetTheme({ ...props.theme, contents });
  }

  const onEditHeader = () => {
    let contents = props.theme.contents || [];
    if (headerEditIdx < 0) return;

    contents[headerEditIdx].header = headerText;
    setHeaderText("");
    setShowHideHeaderForm(false);
    setHeaderActionType(ACTION_ADD);
    props.onSetTheme({ ...props.theme, contents });
  }

  const onHeaderAction = () => {
    if (headerActionType === ACTION_ADD) {
      onAddHeader();
    } else if (headerActionType === ACTION_EDIT) {
      onEditHeader();
    }
  }

  const onActiveHeader = (e, index) => {
    const checked = e.target.checked;
    e.target.style.setProperty("--tglbg", checked ? "#D5E3FE" : "#F1F1F4");

    let contents = props.theme.contents || [];
    if (contents[index]) {
      contents[index].isActive = checked;
    }
    props.onSetTheme({ ...props.theme, contents });
  }

  const onDeleteHeader = (index) => {
    let contents = props.theme.contents || [];
    if (index < 0) return;

    contents.splice(index, 1);
    props.onSetTheme({ ...props.theme, contents });
  }

  const onAddLink = () => {
    let contents = props.theme.contents || [];
    let lastIdx = (contents?.length) ? contents.length - 1 : 0;
    if (!contents[lastIdx]) {
      console.log("Input Header First");
      setLinkLabel("");
      setLinkURL("");
      setShowHideLinkForm(false);
      return;
    }
    if (linkLabel && linkURL) {
      let links = contents[lastIdx]?.links || [];
      links.push({
        id: Math.floor(Math.random() * 101),
        label: linkLabel,
        url: linkURL,
        thumbnail: "",
        isActive: true
      })
      contents[lastIdx].links = [...links];
    }
    setLinkLabel("");
    setLinkURL("");
    setShowHideLinkForm(false);
    props.onSetTheme({ ...props.theme, contents });
  }

  const onEditLink = () => {
    let contents = props.theme.contents || [];
    if (linkHeaderEditIdx < 0 && linkEditIdx < 0) return;

    contents[linkHeaderEditIdx].links[linkEditIdx].label = linkLabel;
    contents[linkHeaderEditIdx].links[linkEditIdx].url = linkURL;
    setLinkLabel("");
    setLinkURL("");
    setShowHideLinkForm(false);
    setLinkActionType(ACTION_ADD);
    props.onSetTheme({ ...props.theme, contents });
  }

  const onLinkAction = () => {
    if (linkActionType === ACTION_ADD) {
      onAddLink();
    } else if (linkActionType === ACTION_EDIT) {
      onEditLink();
    }
  }

  const onActiveLink = (e, index, parentIndex) => {
    const checked = e.target.checked;
    e.target.style.setProperty("--tglbg", checked ? "#D5E3FE" : "#F1F1F4");

    let contents = props.theme.contents || [];
    if (contents[parentIndex]?.links?.[index]) {
      contents[parentIndex].links[index].isActive = checked;
    }
    props.onSetTheme({ ...props.theme, contents });
  }

  const onDeleteLink = (index, parentIndex) => {
    let contents = props.theme.contents || [];
    if (parentIndex < 0 || index < 0) return;

    let links = contents[parentIndex].links || [];
    links.splice(index, 1);

    contents[parentIndex].links = [...links];
    props.onSetTheme({ ...props.theme, contents });
  }

  const onMoveLinkToHeader = (index, parentIdxFrom, parentIdxTo) => {
    let contents = props.theme.contents || [];
    if (index < 0 || parentIdxFrom < 0 || parentIdxTo < 0 || !contents[parentIdxFrom] || !contents[parentIdxTo]) return;
    if (parentIdxFrom === parentIdxTo) return;

    let links = contents[parentIdxFrom].links || [];
    const item = links?.[index];
    if (!item) return;
    contents[parentIdxTo].links.push(item);

    links.splice(index, 1);
    contents[parentIdxFrom].links = [...links];
    setshowHideOtherAction();
    setshowHideSubOtherAction();
    props.onSetTheme({ ...props.theme, contents });
  }

  const onClickUploadThumbnail = () => {
    inputFileRef.current.click();
  };

  const convertImageToBase64 = async (e) => {
    try {
      const file = e.target.files?.[0];
      if (!file) return;

      const maxSize = 100 * 1024; // 100kb in bytes
      if (file.size > maxSize) {
        alert('Thumbnail file size exceeds the limit of 100kb');
        inputFileRef.current.value = "";
        return;
      }

      const maxTotalCount = maxTotalThumbnail + file.size;
      const maxTotalSize = 10 * 1024 * 1024; // 10MB in bytes

      if (maxTotalCount > maxTotalSize) {
        alert('Total thumbnail file size exceeds the limit of 10Mb');
        inputFileRef.current.value = "";
        return;
      }

      setMaxTotalThumbnail(maxTotalCount);
      const result = await getBase64(file);
      onUploadThumbnail(linkHeaderEditIdx, linkEditIdx, result);
    } catch (error) {
      console.log("error convertImageToBase64: ", error);
    }
  };

  const onUploadThumbnail = (parentIndex, index, result) => {
    let contents = props.theme.contents || [];
    if (parentIndex < 0 || index < 0) return;

    contents[parentIndex].links[index].thumbnail = result;
    props.onSetTheme({ ...props.theme, contents });
  };

  const onRemoveThumbnail = (parentIndex, index) => {
    setShowUploadThumnailForm();
    onUploadThumbnail(parentIndex, index, "");
    inputFileRef.current.value = "";
  }

  const onScrollTop = () => {
    const element = document.getElementById("menutree-content");
    if (element) {
      element.scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  function renderOtherAction(item, index, parentIdxFrom) {
    const boxShadowStyle = {
      boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.08), 0px 2px 6px 0px rgba(0, 0, 0, 0.14)"
    }
    return (
      <Fragment>
        {showHideSubOtherAction === item.id &&
          <div className="min-w-[220px] absolute right-64 top-7 bg-white rounded-xl p-4 text-black text-base font-medium z-[2]" style={boxShadowStyle}>
            {props.theme.contents.map((item, parentIdxTo) => (
              <Fragment key={parentIdxTo}>
                {parentIdxTo > 0 && <div className="divider"></div>}
                <div className="cursor-pointer" onClick={() => onMoveLinkToHeader(index, parentIdxFrom, parentIdxTo)}>{item.header}</div>
              </Fragment>
            ))}
          </div>
        }
        <div className="w-max absolute right-5 top-0 bg-white rounded-xl p-4 text-black text-base font-medium z-[1]" style={boxShadowStyle}>
          <div className="flex items-center gap-2 cursor-pointer" onClick={() => setshowHideSubOtherAction(showHideSubOtherAction ? undefined : item.id)}>
            <ArrowRightIcon />
            <div>Pindah Ke Header</div>
            <NextRightIcon />
          </div>
          <div className="divider"></div>
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() => {
              setShowHideLinkDeleteForm(item.id);
              setshowHideOtherAction();
            }}
          >
            <TrashIcon size={24} /><div>Hapus</div>
          </div>
        </div>
      </Fragment>
    )
  }

  function renderLinkElement(links = [], parentIndex) {
    return links.map((item, index) => {
      if (!item?.id) return "";
      return (
        <div key={index} className="rounded-2xl shadow-lg mt-10">
          <div className="flex items-center gap-10 p-5">
            <div><AiOutlineHolder size={24} color="#3375F5" /></div>
            <div className="grid gap-3 overflow-hidden flex-1">
              <div
                className="flex items-center gap-3 text-xl text-black font-semibold cursor-pointer"
                onClick={() => {
                  setShowHideLinkForm(true);
                  setLinkActionType(ACTION_EDIT);
                  setLinkLabel(item.label);
                  setLinkURL(item.url);
                  setLinkEditIdx(index);
                  setLinkHeaderEditIdx(parentIndex);
                  onScrollTop();
                }}
              >
                <div className="truncate max-w-[calc(100vw-910px)]">{item.label}</div> <EditIcon />
              </div>
              <div
                className="flex items-center gap-3 cursor-pointer"
                onClick={() => {
                  setShowHideLinkForm(true);
                  setLinkActionType(ACTION_EDIT);
                  setLinkLabel(item.label);
                  setLinkURL(item.url);
                  setLinkEditIdx(index);
                  setLinkHeaderEditIdx(parentIndex);
                  onScrollTop();
                }}
              >
                <div className="text-xl truncate max-w-[calc(100vw-910px)]">
                  {item.url}
                </div>
                <EditIcon />
              </div>
              <div
                className="flex items-center gap-3 text-xs font-bold text-[#3375F5] cursor-pointer"
                onClick={() => {
                  setShowUploadThumnailForm(item.id);
                  setLinkHeaderEditIdx(parentIndex);
                  setLinkEditIdx(index);
                }}
              >
                <AiOutlinePlus size={16} />{!!item?.thumbnail ? "EDIT" : "TAMBAH"} THUMBNAIL
              </div>
            </div>
            <div className="grid justify-items-end gap-6">
              <input
                type="checkbox"
                className="toggle border-[#F1F1F4] checked:bg-[#2F80ED] checked:border-[#D5E3FE]"
                style={{ "--tglbg": "#D5E3FE" }}
                checked={item.isActive}
                onChange={(e) => onActiveLink(e, index, parentIndex)}
              />
              <div className="relative cursor-pointer">
                <AiOutlineMore size={24} color="#3375F5" onClick={() => setshowHideOtherAction(showHideOtherAction ? undefined : item.id)} />
                {showHideOtherAction === item.id && renderOtherAction(item, index, parentIndex)}
              </div>
            </div>
          </div>
          {showHideLinkDeleteForm === item.id &&
            <div className="bg-[#F6F9FF] py-7 px-5">
              <div className="w-full text-center text-base text-black font-semibold mb-5">Hapus Ini?</div>
              <div className="w-full flex gap-5">
                <button
                  className={`
                    btn btn-outline w-1/2 text-[#3375F5] bg-transparent hover:bg-transparent 
                    hover:border-[#3375F5] hover:text-[#3375F5] hover:opacity-80`
                  }
                  onClick={() => setShowHideLinkDeleteForm()}
                >
                  batal
                </button>
                <button
                  className="btn w-1/2 bg-[#3375F5] border-none text-white hover:bg-[#3375F5] hover:opacity-80"
                  onClick={() => onDeleteLink(index, parentIndex)}
                >
                  hapus
                </button>
              </div>
            </div>
          }
          <input ref={inputFileRef} type="file" className="hidden" onChange={convertImageToBase64} />
          {showUploadThumnailForm === item.id && !item?.thumbnail &&
            <div className="bg-[#F6F9FF] py-7 px-5">
              <div className="w-full text-center text-base text-black font-semibold mb-5">Tambah Thumbnail</div>
              <div className="flex justify-center gap-5">
                <button
                  className={`
                    btn btn-outline w-52 text-[#3375F5] bg-transparent hover:bg-transparent 
                    hover:border-[#3375F5] hover:text-[#3375F5] hover:opacity-80`
                  }
                  onClick={() => setShowUploadThumnailForm()}
                >
                  Tutup
                </button>
                <button
                  className="btn w-52 bg-[#3375F5] border-none text-white hover:bg-[#3375F5] hover:opacity-80"
                  onClick={onClickUploadThumbnail}
                >
                  Upload thumbnail
                </button>
              </div>
            </div>
          }
          {!!item?.thumbnail &&
            <div className="bg-[#F6F9FF] py-7 px-5">
              <div className="w-full text-center text-base text-black font-semibold mb-5">Ubah Thumbnail</div>
              <div className="flex justify-center gap-5">
                <div className="flex justify-center rounded-full overflow-hidden w-24 h-24">
                  <img src={item?.thumbnail} alt="thumbnail" className="w-full h-full object-cover" />
                </div>
                <div className="flex flex-col gap-5">
                  <button
                    className={`
                    btn btn-outline w-52 text-[#3375F5] bg-transparent hover:bg-transparent 
                    hover:border-[#3375F5] hover:text-[#3375F5] hover:opacity-80`
                    }
                    onClick={() => onRemoveThumbnail(parentIndex, index)}
                  >
                    Hapus
                  </button>
                  <button
                    className="btn w-52 bg-[#3375F5] border-none text-white hover:bg-[#3375F5] hover:opacity-80"
                    onClick={onClickUploadThumbnail}
                  >
                    Ganti
                  </button>
                </div>
              </div>
            </div>
          }
        </div>
      )
    })
  }

  function renderContent() {
    const contents = props.theme.contents || [];
    return contents.map((item, index) => {
      if (!item?.id) return "";
      return (
        <Fragment key={index}>
          <div className="rounded-2xl shadow-lg mt-10">
            <div className="flex justify-between items-center p-5 gap-4">
              <AiOutlineHolder size={24} color="#3375F5" />
              <div
                className="flex justify-center items-center gap-3 text-xl text-black font-semibold cursor-pointer"
                onClick={() => {
                  setShowHideHeaderForm(true);
                  setHeaderActionType(ACTION_EDIT);
                  setHeaderText(item.header);
                  setHeaderEditIdx(index);
                  onScrollTop();
                }}
              >
                <div className="truncate max-w-[calc(100vw-860px)]">{item.header}</div> <EditIcon />
              </div>
              <div className="flex flex-col items-end gap-6">
                <input
                  type="checkbox"
                  className="toggle bg-[#878787] border-[#CFCFCF] checked:bg-[#2F80ED] checked:border-[#D5E3FE]"
                  style={{ "--tglbg": "#D5E3FE" }}
                  checked={item.isActive}
                  onChange={(e) => onActiveHeader(e, index)}
                />
                <TrashIcon size={24} className="cursor-pointer" onClick={() => setShowHideHeaderDeleteForm(item.id)} />
              </div>
            </div>
            {showHideHeaderDeleteForm === item.id &&
              <div className="bg-[#F6F9FF] py-7 px-5">
                <div className="w-full text-center text-base text-black font-semibold mb-5">Hapus Ini?</div>
                <div className="w-full flex gap-5">
                  <button
                    className={`
                    btn btn-outline w-1/2 text-[#3375F5] bg-transparent hover:bg-transparent 
                    hover:border-[#3375F5] hover:text-[#3375F5] hover:opacity-80`
                    }
                    onClick={() => setShowHideHeaderDeleteForm()}
                  >
                    batal
                  </button>
                  <button
                    className="btn w-1/2 bg-[#3375F5] border-none text-white hover:bg-[#3375F5] hover:opacity-80"
                    onClick={() => onDeleteHeader(index)}
                  >
                    hapus
                  </button>
                </div>
              </div>
            }
          </div>
          {renderLinkElement(item.links, index)}
        </Fragment>
      )
    })
  }

  function renderGeneratedLinkButton() {
    if (uniqueLink !== "xxxxxxxxxxxxxxxx") {
      return (
        <div className="tooltip before:bg-[#F9FAFB] before:border before:text-black" data-tip="copy link">
          <button className="btn btn-link text-[10px] text-blue-500 decoration-transparent" onClick={onCopyLink}>
            <CopyLinkIcon /> SALIN LINK
          </button>
        </div>
      )
    }
    return (
      <div className="tooltip before:bg-[#F9FAFB] before:border before:text-black" data-tip="generate link">
        <button className="btn btn-link text-[10px] text-blue-500 decoration-transparent" onClick={props.onGeneratedLink}>
          {props.generatedLoading && <span className="loading loading-spinner loading-xs"></span>}
          {!props.generatedLoading && <CopyLinkIcon />}
          GENERATE LINK
        </button>
      </div>
    )
  }

  function renderGeneratedLinkForm() {
    if ((userDetail.licenseName !== "business" && uniqueLink === "xxxxxxxxxxxxxxxx") || uniqueLink !== "xxxxxxxxxxxxxxxx") {
      return (
        <div className="flex justify-between items-center rounded-2xl shadow-lg p-5 mt-10">
          <div className="text-black font-semibold">
            Link Tree Anda : <span className="font-normal text-gray-400">https://link.awandigital.id/{uniqueLink}</span>
          </div>
          {renderGeneratedLinkButton()}
        </div>
      )
    }
  }

  function renderSelfGeneratedLinkForm() {
    if (userDetail.licenseName === "business" && uniqueLink === "xxxxxxxxxxxxxxxx") {
      return (
        <div className="rounded-2xl shadow-lg p-5 mt-10">
          <div className="text-black font-semibold mb-3">Link Tree Anda</div>
          <div className="join w-full mb-6">
            <div className="px-5 py-3 bg-gray-200 rounded-r-none">link.awandigital.id</div>
            <input
              type="text"
              placeholder="Cth : tokosaya"
              className="input input-bordered w-full rounded-l-none focus:outline-none"
              value={inputUniqueLink}
              onChange={(e) => setInputUniqueLink(e.target.value)}
            />
          </div>
          <button
            className={`
              btn btn-outline w-full text-xs text-[#3375F5] hover:bg-white hover:border-[#3375F5] hover:text-[#3375F5]
              disabled:bg-transparent disabled:text-gray-500
            `}
            disabled={!inputUniqueLink}
            onClick={() => props.onGeneratedLink(inputUniqueLink)}
          >
            <div className="flex justify-center items-center gap-1 w-full h-full hover:opacity-80">
              {props.generatedLoading && <span className="loading loading-spinner loading-xs"></span>}
              generate link
            </div>
          </button>
        </div>
      )
    }
  }

  return (
    <Fragment>
      {renderGeneratedLinkForm()}
      {renderSelfGeneratedLinkForm()}
      <div className="flex flex-col gap-5 mt-10">
        {!showHideHeaderForm &&
          <button
            className="btn btn-outline w-full text-xs text-[#3375F5] hover:bg-white hover:border-[#3375F5] hover:text-[#3375F5]"
            onClick={() => {
              setShowHideHeaderForm(true);
              setHeaderActionType(ACTION_ADD);
            }}
          >
            <div className="flex justify-center items-center gap-1 w-full h-full hover:opacity-80">
              <DoubleBoxIcon /> TAMBAH HEADER
            </div>
          </button>
        }
        {showHideHeaderForm &&
          <div className="rounded-2xl shadow-lg p-5">
            <div className="flex justify-between items-center text-black font-semibold">
              <div>Masukan Header</div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  setShowHideHeaderForm(false);
                  setHeaderActionType(ACTION_ADD);
                }}
              >
                <AiOutlineCloseCircle size={24} style={{ color: "#3F4042" }} />
              </div>
            </div>
            <div className="mt-5">
              <label className="text-xs">Header</label>
              <div className="flex gap-4">
                <input
                  type="text"
                  placeholder="0/35"
                  className="input w-full bg-transparent border-[#3375F5] text-black"
                  value={headerText}
                  onChange={(e) => setHeaderText(e.target.value)}
                />
                <button className="btn border-none text-xs text-white bg-[#3375F5] hover:bg-[#3375F5] hover:opacity-90" onClick={onHeaderAction}>
                  {headerActionType === ACTION_ADD ? "TAMBAH" : "UBAH"}
                </button>
              </div>
            </div>
          </div>
        }
        {!showHideLinkForm && props.theme.contents?.length > 0 &&
          <button className="btn w-full bg-[#3375F5] border-none text-xs text-white hover:bg-[#3375F5] hover:opacity-90" onClick={() => setShowHideLinkForm(true)}>
            <CopyLinkIcon color="#FFFFFF" /> TAMBAH TAUTAN
          </button>
        }
        {showHideLinkForm &&
          <div className="rounded-2xl shadow-lg p-5">
            <div className="flex justify-between items-center text-black font-semibold">
              <div>Masukan URL</div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  setShowHideLinkForm(false);
                  setHeaderActionType(ACTION_ADD);
                }}
              >
                <AiOutlineCloseCircle size={24} style={{ color: "#3F4042" }} />
              </div>
            </div>
            <div className="mt-5 flex items-end gap-4">
              <div className="w-1/2">
                <label className="w-full text-xs">Label</label>
                <input
                  type="text"
                  placeholder="Cth : Tokopedia Official Store"
                  className="input w-full bg-transparent border-[#3375F5] text-black"
                  value={linkLabel}
                  onChange={(e) => setLinkLabel(e.target.value)}
                />
              </div>
              <div className="w-1/2">
                <label className="w-full text-xs">URL</label>
                <input
                  type="text"
                  placeholder="Cth : https://web.whatsapp.com/"
                  className="input w-full bg-transparent border-[#3375F5] text-black"
                  value={linkURL}
                  onChange={(e) => setLinkURL(e.target.value)}
                />
              </div>
              <button className="btn border-none text-xs text-white bg-[#3375F5] hover:bg-[#3375F5] hover:opacity-90" onClick={onLinkAction}>
                {linkActionType === ACTION_ADD ? "TAMBAH" : "UBAH"}
              </button>
            </div>
          </div>
        }
      </div>
      {renderContent()}
    </Fragment>
  )
}

export default MenuTreeLink;