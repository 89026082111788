import axios from "axios";

export const userAvailabilitySSO = async (email) => {
  return await axios.post(`${process.env.REACT_APP_SSO_API_DOMAIN}/api/user-available`, { email });
}

export const registerSSO = async (requestData = {}) => {
  const headers = {
    ApiSource: process.env.REACT_APP_API_DOMAIN
  }
  return await axios.post(`${process.env.REACT_APP_SSO_API_DOMAIN}/api/register_sso`, requestData, { headers: headers });
}

export const oauthTokenSSO = async (requestData = {}) => {
  return await axios.post(`${process.env.REACT_APP_SSO_API_DOMAIN}/oauth/token`, requestData);
}

export const forgotPasswordSSO = async (email) => {
  return await axios.post(`${process.env.REACT_APP_SSO_API_DOMAIN}/api/forgot-password`, { email });
}

export const resetPasswordSSO = async (requestData = {}) => {
  return await axios.post(`${process.env.REACT_APP_SSO_API_DOMAIN}/api/reset-password`, requestData);
}

export const updatePasswordSSO = async (requestData = {}) => {
  return await axios.put(`${process.env.REACT_APP_SSO_API_DOMAIN}/api/update-password`, requestData);
}

export const authLogin = async (requestData = {}) => {
  return await axios.post("auth/login", requestData);
}

export const authLogout = async () => {
  return await axios.post("auth/logout");
}

export const authResetPassword = async (requestData = {}) => {
  return await axios.post("auth/reset-password", requestData);
}

export const getProvinceList = async () => {
  return await axios.get("province");
}

export const getCityList = async (provinceId) => {
  return await axios.get(`city/${provinceId}`);
}

/* ANALYTICS */
export const getDashboard = async (requestData = {}) => {
  return await axios.post("analytics/dashboard", requestData);
}

export const getReports = async (requestData = {}) => {
  return await axios.post("analytics/reports", requestData);
}

export const getSurveyReports = async (requestData = {}) => {
  return await axios.post("surveys/reports", requestData);
}

/* WEBPAGE */
export const getWebPageList = async () => {
  return await axios.get("webpage/list");
}

export const deleteWebPage = async (id) => {
  return await axios.delete(`webpage/delete/${id}`);
}

export const getDetailMenuTree = async (id) => {
  return await axios.get(`webpage/menutree/detail/${id}`);
}

export const generatedUniqueLink = async (requestData = {}) => {
  return await axios.post("webpage/menutree/generated", requestData);
}

export const updateMenuTree = async (requestData = {}) => {
  return await axios.put("webpage/menutree/update", requestData);
}

export const deleteMenuTree = async (id) => {
  return await axios.delete(`webpage/menutree/delete/${id}`);
}

/* USER MANAGEMENT */
export const getUserList = async () => {
  return await axios.get("user-management/user");
}

export const getLevelList = async () => {
  return await axios.get("user-management/level");
}

export const createLevel = async (requestData = {}) => {
  return await axios.post("user-management/level/create", requestData);
}

export const updateLevel = async (requestData = {}) => {
  return await axios.put("user-management/level/update", requestData);
}

export const deleteLevel = async (requestData = {}) => {
  return await axios.put("user-management/level/delete", requestData);
}

export const getLicenseList = async () => {
  return await axios.get("user-management/license");
}

export const createLicense = async (requestData = {}) => {
  return await axios.post("user-management/license/create", requestData);
}

export const updateLicense = async (requestData = {}) => {
  return await axios.put("user-management/license/update", requestData);
}

export const deleteLicense = async (requestData = {}) => {
  return await axios.put("user-management/license/delete", requestData);
}

export const updateProfile = async (requestData = {}) => {
  return await axios.put("user-management/profile", requestData);
}

export const resetPassword = async (requestData = {}) => {
  return await axios.put("user-management/reset-password", requestData);
}

export const updateUserAccess = async (requestData = {}) => {
  return await axios.put("user-management/user/update", requestData);
}