import { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AiOutlineEdit, AiOutlineClose } from "react-icons/ai";
import { useWebContext } from "../../app/Provider";
import { authLogout, getLevelList, getLicenseList, getUserList, updateUserAccess } from "../../app/service/api.service";
import CacheService from "../../app/service/jwt.service";
import { addedDays } from './../../app/utils/utils';
import TableComponent from "../../app/components/TableComponent";
import DialogComponent from "../../app/components/DialogComponent";
import AlertComponent from "../../app/components/Alert";
import PaginationComponent from "../../app/components/PaginationComponent";

const pageSize = 10;

function Users() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [levelOption, setLevelOption] = useState([]);
  const [licenseOption, setLicenseOption] = useState([]);
  const [userList, setUserList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [alertOption, setAlertOption] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [dataDetail, setDataDetail] = useState({});
  const { store } = useWebContext();
  const userDetail = store.userDetail;
  const { register, formState: { errors }, setValue, watch, handleSubmit } = useForm();

  const columns = [
    { key: "id", title: "id" },
    { key: "name", title: "full name" },
    { key: "email", title: "email" },
    { key: "phone_number", title: "phone number" },
    {
      key: "level",
      title: "level",
      render: (value) => value.level
    },
    {
      key: "license",
      title: "license",
      render: (value) => {
        const className = value.license === "business" ? 'bg-green-400 text-white' : 'bg-orange-400 text-white';
        return (
          <div className={`py-1 px-3 rounded-md text-sm capitalize ${className}`}>
            {value.license}
          </div>
        )
      }
    },
    {
      key: "action",
      title: "action",
      render: (_, item) => (
        <div className="flex justify-center items-center gap-3">
          <div
            data-tip="Edit Access"
            className={`
              tooltip tooltip-bottom py-2 px-2 border border-slate-300
              rounded-lg cursor-pointer hover:bg-slate-200 duration-200
            `}
            onClick={() => {
              window.userform_update.showModal();
              setValue("userId", item.id);
              setValue("level", item.level_id);
              setValue("license", item.license_id);
              setValue("last_period", moment(item.period_license).format("DD-MM-YYYY"))
              setDataDetail(item);
            }}
          >
            <AiOutlineEdit size={16} />
          </div>
        </div>
      )
    }
  ]

  useEffect(() => {
    onGetLevel();
    onGetLicense();
    onGetListUser();
  }, []);

  const onGetLevel = async () => {
    try {
      const response = await getLevelList();
      if (!response.success) throw response.message;

      setLevelOption(response.data);
    } catch (error) {
      console.log("Error on onGetLevel: ", error);
    }
  }

  const onGetLicense = async () => {
    try {
      const response = await getLicenseList();
      if (!response.success) throw response.message;

      setLicenseOption(response.data);
    } catch (error) {
      console.log("Error on onGetLicense: ", error);
    }
  }

  const onGetListUser = async () => {
    try {
      setIsLoading(true);
      const response = await getUserList();
      if (!response.success) throw response.message;

      setIsLoading(false);
      setUserList(response.data);
    } catch (error) {
      setIsLoading(false);
      console.log("Error on onGetListUser: ", error);
    }
  }

  const onLogout = async () => {
    try {
      const response = await authLogout();
      if (!response.success) return response.message;

      CacheService.destroyToken();
      CacheService.removeLocalStorage("user");
      navigate("/login");
    } catch (error) {
      console.log("Error onLogout:", error);
    }
  }

  const onUpdateUserAccess = async (data) => {
    try {
      const request = {
        id: data.userId,
        levelId: data.level,
        licenseId: data.license,
        periodLicense: addedDays(dataDetail?.period_license, data.days) || dataDetail?.period_license
      }

      setIsSubmitLoading(true);
      const response = await updateUserAccess(request);
      if (!response.success) throw response.message;

      setIsSubmitLoading(false);
      setAlertOption({ type: "success", message: response.message });
      setValue("days", null);
      window.userform_update.close();

      if (userDetail.userId !== data.userId) {
        onGetListUser();
        return;
      }

      onLogout();
    } catch (error) {
      setIsSubmitLoading(false);
      setAlertOption({ type: "error", message: error });
      window.userform_update.close();
      console.log("Error on onUpdateUserAccess: ", error);
    }
  }

  const generateDataSource = () => {
    if (!userList?.length) return [];

    const startIndex = (currentPage * pageSize) - pageSize;
    const lastIndex = currentPage * pageSize;
    return userList.slice(startIndex, lastIndex);
  }

  function renderLevelOptions() {
    return levelOption.map((item, index) => (
      <option key={index} value={item.id}>{item.level}</option>
    ))
  }

  function renderLicenseOptions() {
    return licenseOption.map((item, index) => (
      <option key={index} value={item.id}>{item.license}</option>
    ))
  }

  return (
    <div className="h-full overflow-y-auto p-6">
      <AlertComponent
        type={alertOption.type}
        message={alertOption.message}
        onClose={() => setAlertOption({ message: "" })}
      />
      <div className="mb-5 text-black text-2xl font-bold">Users</div>
      <TableComponent
        loading={isLoading}
        columns={columns}
        dataSource={generateDataSource()}
      />
      <PaginationComponent
        currentPage={currentPage}
        pageSize={pageSize}
        total={!isLoading ? userList?.length : 0}
        onPageChange={(page) => setCurrentPage(page)}
      />
      <DialogComponent open={openDialog} onClose={setOpenDialog} />
      <dialog id="userform_update" className="modal">
        <form className="modal-box" onSubmit={handleSubmit(onUpdateUserAccess)}>
          <div className="flex justify-between items-center mb-6">
            <div className="text-xl font-semibold">{dataDetail.name}</div>
            <div className="p-2 cursor-pointer" onClick={() => window.userform_update.close()}>
              <AiOutlineClose size={16} />
            </div>
          </div>
          <input type="hidden" {...register("userId")} />
          <div className="flex mb-5">
            <label className="w-1/3 flex items-center text-sm">Level</label>
            <select
              className="select w-full bg-transparent border-[#7D818B] text-sm font-normal"
              {...register("level", { required: "Level is required!" })}
            >
              <option value="" hidden>Choose Level</option>
              {renderLevelOptions()}
            </select>
          </div>
          {errors.level &&
            <div className="flex items-center -mt-5 mb-2">
              <div className="w-1/3"></div>
              <div className="w-full text-red-500 py-1">{errors.level?.message}</div>
            </div>
          }
          <div className="flex mb-5">
            <label className="w-1/3 flex items-center text-sm">License</label>
            <select
              className="select w-full bg-transparent border-[#7D818B] text-sm font-normal"
              {...register("license", { required: "License is required!" })}
            >
              <option value="" hidden>Choose License</option>
              {renderLicenseOptions()}
            </select>
          </div>
          {errors.license &&
            <div className="flex items-center -mt-5 mb-2">
              <div className="w-1/3"></div>
              <div className="w-full text-red-500 py-1">{errors.license?.message}</div>
            </div>
          }
          <div className="flex mb-5">
            <label className="w-1/3 flex items-center text-sm">Last Period License</label>
            <input {...register("last_period")} className="input w-full" disabled />
          </div>
          <div className="flex mb-5">
            <label className="w-1/3 flex items-center text-sm">Period License</label>
            <input
              {...register("days", { pattern: { value: /^(0|[1-9]\d*)(\.\d+)?$/, message: 'Please enter a valid number.' }})}
              placeholder="Masa Trial (Hari)"
              className="input w-full bg-transparent border-[#3375F5] text-black"
            />
          </div>
          {errors.days &&
            <div className="flex items-center -mt-4 mb-5">
              <div className="w-1/3"></div>
              <div className="w-full text-red-500 py-1">{errors.days?.message}</div>
            </div>
          }
          <button
            type="submit"
            className={`btn w-full capitalize bg-[#5557DB] border-none text-sm text-white font-normal hover:bg-[#5557DB] hover:opacity-75`}
          >
            {isSubmitLoading && <span className="loading loading-spinner loading-sm"></span>}
            Update
          </button>
        </form>
      </dialog>
    </div>
  )
}

export default Users;