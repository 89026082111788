import { Fragment, useEffect, useState, useRef } from "react";
import { ChromePicker } from 'react-color';
import { AiFillLock } from "react-icons/ai";
import { useWebContext } from "../../../app/Provider";
import { EditIcon } from "../../../assets/icon/IndexSvgIcon";
import ThemeJson from "../../../assets/json/theme.json";
import FontFamily from "../../../assets/json/fontFamily.json";
import { getBase64 } from './../../../app/utils/utils';

function MenuTreeTheme(props) {
  const inputFileRef = useRef(undefined);
  const [fontColor, setFontColor] = useState("#000000");
  const [buttonBg, setButtonBg] = useState("#000000");
  const [buttonBorder, setButtonBorder] = useState("#000000");
  const [showFontColor, setShowFontColor] = useState(false);
  const [showButtonBg, setShowButtonBg] = useState(false);
  const [showButtonBorder, setShowButtonBorder] = useState(false);
  const { store } = useWebContext();
  const userDetail = store.userDetail;

  useEffect(() => {
    const { theme } = props;
    if (theme?.fontColor !== fontColor) {
      setFontColor(theme.fontColor);
    }
    if (theme?.buttonBgColor !== buttonBg) {
      setButtonBg(theme.buttonBgColor);
    }
    if (theme?.buttonBorder !== buttonBorder) {
      setButtonBorder(theme.buttonBorder);
    }
  }, [JSON.stringify(props.theme)])

  const onSetTheme = (key, value) => {
    props.onSetTheme({ ...props.theme, [key]: value });
  }

  const onSetMultiDataTheme = (value) => {
    props.onSetTheme({ ...props.theme, ...value });
  }

  const convertImageToBase64 = async (e) => {
    try {
      const file = e.target.files?.[0];
      if (!file) return;

      const maxSize = 3 * 1024 * 1024; // 3MB in bytes
      if (file.size > maxSize) {
        alert('File size exceeds the limit of 3MB');
        return;
      }

      const result = await getBase64(file);
      onSetTheme("picture", result);
    } catch (error) {
      console.log("error convertImageToBase64: ", error);
    }
  };

  const onUploadProfile = () => {
    inputFileRef.current.click();
  };

  const onRemoveProfile = () => {
    onSetTheme("picture", "");
    inputFileRef.current.value = undefined;
  }

  function renderFontFamily() {
    return FontFamily.map((item, index) => (
      <option key={index} value={item} style={{ fontFamily: item }}>
        {item}
      </option>
    ))
  }

  return (
    <Fragment>
      <div id="profile" className="rounded-2xl shadow-lg p-5 mt-10">
        <div className="text-black font-semibold">Profile</div>
        <div className="flex gap-11 mt-5">
          <div className="flex flex-col gap-5">
            <div className="relative w-28 h-28 grid place-items-center bg-[#F8F8F8] rounded-full text-5xl font-bold cursor-pointer" onClick={onUploadProfile}>
              {props.theme.picture &&
                <img src={props.theme.picture} alt={props.theme.picture} className="w-full h-full object-cover rounded-full overflow-hidden" />
              }
              {!props.theme.picture && "AD"}
              <div className="absolute bottom-1 right-1 bg-white p-2 rounded-full drop-shadow">
                <EditIcon size={14} />
              </div>
            </div>
            <input ref={inputFileRef} type="file" className="hidden" onChange={convertImageToBase64} />
            <button className="btn btn-outline btn-sm text-[8px] text-[#3375F5] hover:bg-white hover:border-[#3375F5] hover:text-[#3375F5]" onClick={onRemoveProfile}>
              HAPUS FOTO
            </button>
          </div>
          <div className="w-full flex flex-col gap-5">
            <div>
              <label className="text-xs">Nama Profil</label>
              <input
                type="text"
                placeholder="Masukkan Nama Profil"
                className="input w-full bg-transparent border-[#3375F5] text-black"
                value={props.theme.profileName}
                onChange={(e) => onSetTheme("profileName", e.target.value)}
              />
            </div>
            <div>
              <label className="text-xs">Bio</label>
              <input
                type="text"
                placeholder="masukkan Bio"
                className="input w-full bg-transparent border-[#3375F5] text-black"
                value={props.theme.bioDetail}
                onChange={(e) => onSetTheme("bioDetail", e.target.value)}
              />
            </div>
            <div className="flex justify-between items-center">
              <div className="text-xs font-bold text-blue-500 decoration-transparent">
                IKON BAGIKAN
              </div>
              <input
                type="checkbox"
                className="toggle border-[#F1F1F4] checked:bg-[#2F80ED] checked:border-[#D5E3FE]"
                style={{ "--tglbg": "#D5E3FE" }}
                defaultChecked
                onChange={(e) => {
                  const checked = e.target.checked;
                  e.target.style.setProperty("--tglbg", checked ? "#D5E3FE" : "#F1F1F4");
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div id="theme" className="rounded-2xl shadow-lg p-5 mt-10">
        <div className="text-black font-semibold mb-5">Tema</div>
        <div className="flex flex-wrap gap-x-7 gap-y-10">
          {ThemeJson.map((data, index) => (
            <div
              key={index}
              className="w-36 h-72 rounded-md cursor-pointer relative"
              style={{ background: data.bgColor, border: index > 0 ? "" : "1px solid #505050" }}
              onClick={() => onSetMultiDataTheme({ bgColor: data.bgColor, buttonBgColor: data.buttonBgColor, buttonBorder: data.buttonBorder, buttonTextColor: data.buttonTextColor })}
            >
              <div className="flex justify-center mt-[85%] px-5">
                <div className="w-full flex flex-col gap-3">
                  <div className="w-full h-7 border rounded-md" style={{ borderColor: data.buttonBorder, background: data.buttonBgColor }}></div>
                  <div className="w-full h-7 border rounded-md" style={{ borderColor: data.buttonBorder, background: data.buttonBgColor }}></div>
                  <div className="w-full h-7 border rounded-md" style={{ borderColor: data.buttonBorder, background: data.buttonBgColor }}></div>
                </div>
              </div>
              {/* {!data?.permission.includes(userDetail.licenseName) &&
                <Fragment>
                  <div className="absolute inset-0 w-full h-full bg-black opacity-75 rounded-md" onClick={(e) => e.stopPropagation()}></div>
                  <AiFillLock className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white text-2xl" onClick={(e) => e.stopPropagation()} />
                </Fragment>
              } */}
            </div>
          ))}
        </div>
      </div>
      <div id="font" className="rounded-2xl shadow-lg p-5 mt-10 text-black">
        <div className="font-semibold mb-5">Font</div>
        <div className="my-5">
          <label className="text-sm">Font</label>
          <select
            name="fontFamily"
            className="select bg-transparent border-[#2F80ED] w-full text-sm"
            style={{ fontFamily: props.theme.fontFamily }}
            value={props.theme.fontFamily}
            onChange={(e) => onSetTheme("fontFamily", e.target.value)}
          >
            <option value="" hidden>Choose Font</option>
            {renderFontFamily()}
          </select>
        </div>
        <div className="relative flex items-center gap-5">
          <div className="w-12 h-11 rounded border border-gray-300 cursor-pointer" style={{ background: fontColor }} onClick={() => setShowFontColor(!showFontColor)} />
          {showFontColor &&
            <div className="absolute top-11 z-[2]">
              <div className="fixed top-0 right-0 bottom-0 left-0" onClick={() => setShowFontColor(false)}></div>
              <ChromePicker
                color={fontColor}
                onChange={(color) => setFontColor(color.hex)}
                onChangeComplete={(color) => onSetTheme("fontColor", color.hex)}
              />
            </div>
          }
          <input type="text" value={fontColor} onChange={(e) => setFontColor(e.target.value)} className="input w-full bg-transparent border-[#3375F5] text-black" />
        </div>
      </div>
      <div id="background" className="rounded-2xl shadow-lg p-5 mt-10 text-black">
        <div className="font-semibold mb-5">Button Background</div>
        <label className="text-sm">Color</label>
        <div className="relative flex items-center gap-5 mb-10">
          <div className="w-12 h-11 rounded border border-gray-300 cursor-pointer" style={{ background: buttonBg }} onClick={() => setShowButtonBg(!showButtonBg)} />
          {showButtonBg &&
            <div className="absolute top-11 z-[2]">
              <div className="fixed top-0 right-0 bottom-0 left-0" onClick={() => setShowButtonBg(false)}></div>
              <ChromePicker
                color={buttonBg}
                onChange={(color) => setButtonBg(color.hex)}
                onChangeComplete={(color) => onSetTheme("buttonBgColor", color.hex)}
              />
            </div>
          }
          <input type="text" value={buttonBg} onChange={(e) => setButtonBg(e.target.value)} className="input w-full bg-transparent border-[#3375F5] text-black" />
        </div>
        <div className="text-black font-semibold mb-5">Button Border</div>
        <label className="text-sm">Color</label>
        <div className="relative flex items-center gap-5">
          <div className="w-12 h-11 rounded border border-gray-300 cursor-pointer" style={{ background: buttonBorder }} onClick={() => setShowButtonBorder(!showButtonBorder)} />
          {showButtonBorder &&
            <div className="absolute -top-60 z-[2]">
              <div className="fixed top-0 right-0 bottom-0 left-0" onClick={() => setShowButtonBorder(false)}></div>
              <ChromePicker
                color={buttonBorder}
                onChange={(color) => setButtonBorder(color.hex)}
                onChangeComplete={(color) => onSetTheme("buttonBorder", color.hex)}
              />
            </div>
          }
          <input type="text" value={buttonBorder} onChange={(e) => setButtonBorder(e.target.value)} className="input w-full bg-transparent border-[#3375F5] text-black" />
        </div>
      </div>
    </Fragment>
  )
}

export default MenuTreeTheme;