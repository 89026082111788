import { Fragment, useEffect, useState, useRef } from "react";
import {
    DefaultImage,
    CopyLinkIcon,
    EditIcon,
    TrashIcon,
    UploadIcon
} from "../../../assets/icon/IndexSvgIcon";
import { ChromePicker } from "react-color";
import { getBase64 } from "./../../../app/utils/utils";
import DefaultProduct from '../../../assets/json/catalog/productTheme.json';
import SliderComponent from "../../../app/components/SliderComponent";

function CatalogProductDetail(props) {
    const inputFileRef = useRef(undefined);

    const [product, setProduct] = useState({});
    const [showBackgroundColor, setShowBackgroundColor] = useState(false);
    const [showNameColor, setShowNameColor] = useState(false);
    const [showSpecificationColor, setShowSpecificationColor] = useState(false);
    const [showDescriptionColor, setShowDescriptionColor] = useState(false);
    const [showPriceColor, setShowPriceColor] = useState(false);
    const [showButtonColor, setShowButtonColor] = useState(false);
    const [showLink, setShowLink] = useState(false);
    const [showResultLink, setShowResultLink] = useState(false);

    useEffect(() => {
        if (JSON.stringify(props.product) !== JSON.stringify(product)) {
            if (props.product) return setProduct(props.product);
            props.setSelectedProduct({...DefaultProduct});
            return setProduct({...DefaultProduct})
        }

    }, [JSON.stringify(props.product)]);

    useEffect(() => {
        if (Object.keys(product).length && JSON.stringify(product) !== JSON.stringify(props.product)) {
            props.setSelectedProduct(product);
        }
    }, [JSON.stringify(product)])
  

    const onRemoveImage = (index) => {
        const images = product.images;
        images.splice(index, 1);

        setProduct({ ...product, images: images })
    };

    const convertImageToBase64 = async (e, type) => {
        try {
            const files = e.target.files;
            const lengthFile = files.length;

            if (lengthFile > 3) return alert("Maximum upload is 3 file");

            const base64ConverterPromises = Array.from({ length: lengthFile }).map(
                async (_, index) => {
                    const file = files[index];
                    const maxSize = 3 * 1024 * 1024; // 3MB in bytes
                    if (file.size > maxSize) {
                        alert("File size exceeds the limit of 3MB");
                        return;
                    }

                    return await getBase64(file);
                }
            );

            const images = await Promise.all(base64ConverterPromises);

            if (!images.length) return;

            setProduct({ ...product, images: [...product.images, ...images] })

        } catch (error) {
            console.log("error convertImageToBase64: ", error);
        }
    };

    function renderPrice() {
        const isDiscount = product.is_discount;
        return (
            <div className="flex gap-2 items-center">
                <span className="text-md font-semibold capitalize" style={{ color: `${product.price_color}` }}>{`Rp. ${parseInt(product?.discount_price)?.toLocaleString()}`}</span>
                {isDiscount && <span className="text-xs font-semibold capitalize line-through text-[#6F6B6B]">{`Rp. ${parseInt(product?.normal_price)?.toLocaleString()}`}</span>}
            </div>
        )
    }

    function renderWebDisplay() {
        return (
            <div className="p-2 w-full">
                <div
                    id="product-section-content"
                    className={`w-full flex items-center justify-between p-5 gap-1`}
                    style={{ backgroundColor: product.background_color }}
                >
                    <div className="flex w-96 h-64 m-2 bg-[#EEF1F6] border border-[#EEF1F6] rounded-lg">
                        {product?.images?.length > 0 && (<SliderComponent images={product.images} />)}
                        {product?.images?.length <= 0 && <DefaultImage size={30} className="w-full h-full object-cover overflow-hidden" />}
                    </div>
                    <div className="w-3/4 grid gap-1">
                        <span className="text-base font-semibold uppercase" style={{ color: `${product.product_name_color}` }}>{product.product_name}</span>
                        <div className="grid gap-1">
                            <span className="text-sm font-medium capitalize text-[#6F6B6B]">Ukuran</span>
                            <span className="text-sm font-medium capitalize" style={{ color: `${product.specification_color}` }}>{product.specification}</span>
                        </div>
                        <div className="grid gap-1">
                            <span className="text-sm font-medium capitalize text-[#6F6B6B]">Deskripsi</span>
                            <span className="text-sm font-medium capitalize" style={{ color: `${product.description_color}` }}>{product.description}</span>
                        </div>
                        <div className="grid gap-1">
                            <span className="text-sm font-medium capitalize text-[#6F6B6B]">Harga</span>
                            {renderPrice()}
                        </div>
                        <a href={product.button_url} className="text-white hover:opacity-90 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 uppercase text-center w-1/2" style={{ backgroundColor: `${product.button_color}` }}>{product.button_label}</a>
                    </div>

                </div>

                <div
                    id="product-section-footer"
                    className={`w-full grid py-7 px-5`}
                    style={{ backgroundColor: props.theme.footer.background_color }}
                >
                    <div
                        className={`font-semibold text-md uppercase tracking-wider`}
                        style={{
                            color: props.theme.footer.title_color,
                            fontFamily: props.theme.footer.title_font,
                        }}
                    >
                        {props.theme.footer.title}
                    </div>
                    <div
                        className={`font-normal text-xs my-1`}
                        style={{
                            color: props.theme.footer.description_color,
                            fontFamily: props.theme.footer.description_font,
                        }}
                    >
                        {props.theme.footer.description}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Fragment>
            <div id="left-content" className="flex w-2/3">
                {renderWebDisplay()}
            </div>
            <div
                id="right-content"
                className="flex-1 p-3 bg-[#F8FBFF] rounded-ss-2xl h-screen"
            >
                <div id="catalog-action-menus" className="flex items-center justify-between">
                    <button
                        className="h-10 w-1/2 px-2 m-1 text-sm text-white bg-[#3375F5] rounded focus:shadow-outline hover:bg-[#3375F5] hover:opacity-90 uppercase"
                        onClick={props.onSubmitProduct}
                    >
                        Save product
                    </button>
                    <button
                        className="flex-1 h-10 w-1/2 px-2 m-1 text-sm text-[#3375F5] transition-colors duration-150 border border-[#3375F5] rounded focus:shadow-outline hover:border-[#3375F5]/90 hover:opacity-90 uppercase"
                        onClick={() => props.onClickPreview("product")}
                    >
                        Preview Design
                    </button>
                </div>

                <div className="mt-5 pb-60">
                    <div id="catalog-active-option" className="p-1">
                        <span className="text-[#1e1e1e]/[.50]">Section : </span>
                        <b className="capitalize">Produk</b>
                    </div>

                    {/* Background */}
                    <div
                        id="catalog-active-option-product-background"
                        className="rounded-2xl shadow-lg p-5 mt-5 grid gap-4 bg-[#FFF]"
                    >
                        <div className="text-[#1E1E1E] font-semibold text-md tracking-wider capitalize">
                            Background
                        </div>

                        {/* Color Background Section */}
                        <div className="grid gap-2">
                            <div className="text-[#000] text-xs font-normal">
                                Color Background
                            </div>
                            <div className="relative flex items-center gap-1">
                                <div
                                    className="w-12 h-11 rounded border border-gray-300 cursor-pointer"
                                    style={{
                                        background: product.background_color,
                                    }}
                                    onClick={() =>
                                        setShowBackgroundColor(!showBackgroundColor)
                                    }
                                />
                                {showBackgroundColor && (
                                    <div className="absolute top-11 z-[2]">
                                        <div
                                            className="fixed top-0 right-0 bottom-0 left-0"
                                            onClick={() => setShowBackgroundColor(false)}
                                        >
                                        </div>
                                        <ChromePicker
                                            color={product.background_color}
                                            onChange={(color) => setProduct({ ...product, background_color: color.hex })}
                                            onChangeComplete={(color) => setProduct({ ...product, background_color: color.hex })}
                                        />
                                    </div>
                                )}
                                <input
                                    type="text"
                                    value={product.background_color}
                                    onChange={(e) => setProduct({ ...product, background_color: e.target.value })}
                                    className="input w-full bg-transparent border-[#3375F5] text-black"
                                />
                            </div>
                        </div>
                    </div>

                    {/* Image Resource */}
                    <div
                        id="catalog-active-option-logo"
                        className="rounded-2xl shadow-lg p-5 mt-5 grid gap-4 bg-[#FFF]"
                    >
                        <div className="text-[#1E1E1E] font-semibold text-md tracking-wider">
                            Image Resource
                        </div>
                        <div className="flex gap-1">
                            {product?.images?.length > 0 && (
                                product.images.map((url, index) => (
                                    <div className="relative w-24 h-24 grid place-items-center bg-[#EEF1F6]" key={`resurce-image-${index}`}>
                                        <img
                                            src={url}
                                            alt={url}
                                            className="w-full h-full object-cover overflow-hidden"
                                        />
                                        <div
                                            className="absolute top-0 right-0 bg-black rounded-full drop-shadow cursor-pointer"
                                            onClick={() => onRemoveImage(index)}
                                        >
                                            <span className="text-[#FFF] text-xs p-2">X</span>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                        <div
                            className="flex items-center justify-center gap-1 lw-full h-10 cursor-pointer border border-[#3375F5] rounded"
                            onClick={() => inputFileRef.current.click()}
                        >
                            <input
                                ref={inputFileRef}
                                type="file"
                                className="hidden"
                                onChange={(e) => convertImageToBase64(e)}
                                accept="image/png, image/jpg, image/jpeg"
                                multiple
                            />
                            <UploadIcon />
                            <span className="text-m font-bold text-[#3375F5]">Upload Image</span>
                        </div>
                        <span className="text-xs font-normal text-[#3375F5]">
                            Format yang didukung: jpeg, .jpg, .png.
                        </span>
                    </div>

                    {/* Product Name */}
                    <div
                        id="catalog-active-option-footer-description"
                        className="rounded-2xl shadow-lg p-5 mt-5 grid gap-4 bg-[#FFF]"
                    >
                        <div className="text-[#1E1E1E] font-semibold text-md tracking-wider capitalize">
                            Nama Produk
                        </div>

                        {/* Title Text Section */}
                        <div className="grid gap-2">
                            <span className="text-[#000] text-xs font-normal">Nama Produk</span>

                            <input
                                type="text"
                                value={product.product_name}
                                onChange={(e) => setProduct({ ...product, product_name: e.target.value })}
                                className="input w-full bg-transparent border-[#3375F5] text-black"
                            />
                        </div>

                        {/* Color Text Section */}
                        <div className="grid gap-2">
                            <div className="text-[#000] text-xs font-normal">
                                Color Nama Produk
                            </div>
                            <div className="relative flex items-center gap-1">
                                <div
                                    className="w-12 h-11 rounded border border-gray-300 cursor-pointer"
                                    style={{
                                        background: product.product_name_color,
                                    }}
                                    onClick={() =>
                                        setShowNameColor(!showNameColor)
                                    }
                                />
                                {showNameColor && (
                                    <div className="absolute top-11 z-[2]">
                                        <div
                                            className="fixed top-0 right-0 bottom-0 left-0"
                                            onClick={() => setShowNameColor(false)}
                                        ></div>
                                        <ChromePicker
                                            color={product.product_name_color}
                                            onChange={(color) =>
                                                setProduct({ ...product, product_name_color: color.hex })
                                            }
                                            onChangeComplete={(color) =>
                                                setProduct({ ...product, product_name_color: color.hex })
                                            }
                                        />
                                    </div>
                                )}
                                <input
                                    type="text"
                                    value={product.product_name_color}
                                    onChange={(e) =>
                                        setProduct({ ...product, product_name_color: e.target.value })
                                    }
                                    className="input w-full bg-transparent border-[#3375F5] text-black"
                                />
                            </div>
                        </div>
                    </div>

                    {/* Specification */}
                    <div
                        id="catalog-active-option-footer-description"
                        className="rounded-2xl shadow-lg p-5 mt-5 grid gap-4 bg-[#FFF]"
                    >
                        <div className="text-[#1E1E1E] font-semibold text-md tracking-wider capitalize">
                            Spesifikasi
                        </div>

                        {/* Title Text Section */}
                        <div className="grid gap-2">
                            <span className="text-[#000] text-xs font-normal">Spesifikasi Produk</span>

                            <input
                                type="text"
                                value={product.specification}
                                onChange={(e) => setProduct({ ...product, specification: e.target.value })}
                                className="input w-full bg-transparent border-[#3375F5] text-black"
                            />
                        </div>

                        {/* Color Text Section */}
                        <div className="grid gap-2">
                            <div className="text-[#000] text-xs font-normal">
                                Color Spesifikasi Produk
                            </div>
                            <div className="relative flex items-center gap-1">
                                <div
                                    className="w-12 h-11 rounded border border-gray-300 cursor-pointer"
                                    style={{
                                        background: product.specification_color,
                                    }}
                                    onClick={() =>
                                        setShowSpecificationColor(!showSpecificationColor)
                                    }
                                />
                                {showSpecificationColor && (
                                    <div className="absolute top-11 z-[2]">
                                        <div
                                            className="fixed top-0 right-0 bottom-0 left-0"
                                            onClick={() => setShowSpecificationColor(false)}
                                        ></div>
                                        <ChromePicker
                                            color={product.specification_color}
                                            onChange={(color) =>
                                                setProduct({ ...product, specification_color: color.hex })
                                            }
                                            onChangeComplete={(color) =>
                                                setProduct({ ...product, specification_color: color.hex })
                                            }
                                        />
                                    </div>
                                )}
                                <input
                                    type="text"
                                    value={product.specification_color}
                                    onChange={(e) =>
                                        setProduct({ ...product, specification_color: e.target.value })
                                    }
                                    className="input w-full bg-transparent border-[#3375F5] text-black"
                                />
                            </div>
                        </div>
                    </div>

                    {/* Description */}
                    <div
                        id="catalog-active-option-footer-description"
                        className="rounded-2xl shadow-lg p-5 mt-5 grid gap-4 bg-[#FFF]"
                    >
                        <div className="text-[#1E1E1E] font-semibold text-md tracking-wider capitalize">
                            Deskripsi
                        </div>

                        {/* Title Text Section */}
                        <div className="grid gap-2">
                            <span className="text-[#000] text-xs font-normal">Deskripsi Produk</span>

                            <textarea
                                type="text"
                                value={product.description}
                                onChange={(e) => setProduct({ ...product, description: e.target.value })}
                                className="input w-full h-20 bg-transparent border-[#3375F5] text-black"
                                row={10}
                            />
                        </div>

                        {/* Color Text Section */}
                        <div className="grid gap-2">
                            <div className="text-[#000] text-xs font-normal">
                                Color Spesifikasi Produk
                            </div>
                            <div className="relative flex items-center gap-1">
                                <div
                                    className="w-12 h-11 rounded border border-gray-300 cursor-pointer"
                                    style={{
                                        background: product.description_color,
                                    }}
                                    onClick={() =>
                                        setShowDescriptionColor(!showDescriptionColor)
                                    }
                                />
                                {showDescriptionColor && (
                                    <div className="absolute top-11 z-[2]">
                                        <div
                                            className="fixed top-0 right-0 bottom-0 left-0"
                                            onClick={() => setShowDescriptionColor(false)}
                                        ></div>
                                        <ChromePicker
                                            color={product.description_color}
                                            onChange={(color) =>
                                                setProduct({ ...product, description_color: color.hex })
                                            }
                                            onChangeComplete={(color) =>
                                                setProduct({ ...product, description_color: color.hex })
                                            }
                                        />
                                    </div>
                                )}
                                <input
                                    type="text"
                                    value={product.description_color}
                                    onChange={(e) =>
                                        setProduct({ ...product, description_color: e.target.value })
                                    }
                                    className="input w-full bg-transparent border-[#3375F5] text-black"
                                />
                            </div>
                        </div>
                    </div>

                    {/* Price */}
                    <div
                        id="catalog-active-option-product-background"
                        className="rounded-2xl shadow-lg p-5 mt-5 grid gap-4 bg-[#FFF]"
                    >
                        <div className="flex justify-between items-center">
                            <span className="text-[#1E1E1E] font-semibold text-md tracking-wider capitalize">
                                harga
                            </span>
                            <div className="flex jestify-between items-center gap-2">
                                <span className="uppercase font-normal text-xs text-[#1A67FB]">Sedang Diskon</span>
                                <label className='flex cursor-pointer select-none items-center'>
                                    <div className='relative'>
                                        <input
                                            type='checkbox'
                                            checked={product.is_discount}
                                            onChange={() => setProduct({ ...product, is_discount: !product.is_discount })}
                                            className='sr-only'
                                        />
                                        <div className='h-3 w-8 rounded-full bg-gray-200 shadow-inner'></div>
                                        <div className='dot shadow-switch-1 absolute left-0 -top-1 h-5 w-5 rounded-full bg-gray-200 transition'></div>
                                    </div>
                                </label>
                            </div>
                        </div>

                        {/* Price Section */}
                        <div className="grid gap-2">
                            <label htmlFor="website-admin" className="block mb-2 text-sm font-medium text-gray-900 capitalize">harga</label>
                            <div className="flex">
                                <span className="inline-flex items-center px-3 text-sm text-[#58585B] bg-[#E8F0FF] border rounded-e-0 border-[#3375F5] rounded-s-md">Rp.</span>
                                <input
                                    type="text"
                                    value={product.normal_price}
                                    onChange={(e) => setProduct({ ...product, normal_price: e.target.value })}
                                    className="rounded-none rounded-e-md border flex-1 min-w-0 w-full text-sm bg-transparent border-[#3375F5] text-black p-2.5 focus:outline-none focus:ring focus:ring-[#6682A9]/0.2"
                                    placeholder="Masukan Nominal" />
                            </div>
                        </div>

                        {product.is_discount && (
                            <div className="flex gap-2">
                                <div className="grid gap-1 w-1/2">
                                    <label htmlFor="website-admin" className="block mb-2 text-sm font-medium text-gray-900 capitalize">discount</label>
                                    <div className="flex">
                                        <input
                                            type="text"
                                            value={product.discount}
                                            onChange={(e) => {
                                                const discountPrice = product.normal_price - (product.normal_price * e.target.value / 100)
                                                setProduct({ ...product, discount: e.target.value, discount_price: discountPrice })
                                            }}
                                            className="rounded-e-0 rounded-s-md border flex-1 min-w-0 w-full text-sm bg-transparent border-[#3375F5] text-black p-2.5 focus:outline-none focus:ring focus:ring-[#6682A9]/0.2"
                                            placeholder="Masukan Nominal" />
                                        <span className="inline-flex items-center px-3 text-sm text-[#58585B] bg-[#E8F0FF] border  border-[#3375F5] rounded-none rounded-e-md">%</span>
                                    </div>
                                </div>
                                <div className="grid gap-1">
                                    <label htmlFor="website-admin" className="block mb-2 text-sm font-medium text-gray-900 capitalize">Harga Promosi</label>
                                    <div className="flex">
                                        <span className="inline-flex items-center px-3 text-sm text-[#58585B] bg-[#E8F0FF] border rounded-e-0 border-[#3375F5] rounded-s-md">Rp.</span>
                                        <input
                                            type="text"
                                            value={product.discount_price}
                                            onChange={(e) => {
                                                if (parseInt(e.target.value) > parseInt(product.normal_price)) {
                                                    return props.setAlertOption({ type: "error", message: "Invalid discount price", });
                                                }
                                                return setProduct({ ...product, discount_price: e.target.value })
                                            }}
                                            className="rounded-none rounded-e-md border flex-1 min-w-0 w-full text-sm bg-transparent border-[#3375F5] text-black p-2.5 focus:outline-none focus:ring focus:ring-[#6682A9]/0.2"
                                            placeholder="Masukan Nominal" />
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Color Background Section */}
                        <div className="grid gap-2">
                            <div className="text-[#000] text-xs font-normal">
                                Color Harga
                            </div>
                            <div className="relative flex items-center gap-1">
                                <div
                                    className="w-12 h-11 rounded border border-gray-300 cursor-pointer"
                                    style={{
                                        background: product.price_color,
                                    }}
                                    onClick={() =>
                                        setShowPriceColor(!showPriceColor)
                                    }
                                />
                                {showPriceColor && (
                                    <div className="absolute top-11 z-[2]">
                                        <div
                                            className="fixed top-0 right-0 bottom-0 left-0"
                                            onClick={() => setShowPriceColor(false)}
                                        >
                                        </div>
                                        <ChromePicker
                                            color={product.price_color}
                                            onChange={(color) => setProduct({ ...product, price_color: color.hex })}
                                            onChangeComplete={(color) => setProduct({ ...product, price_color: color.hex })}
                                        />
                                    </div>
                                )}
                                <input
                                    type="text"
                                    value={product.price_color}
                                    onChange={(e) => setProduct({ ...product, price_color: e.target.value })}
                                    className="input w-full bg-transparent border-[#3375F5] text-black"
                                />
                            </div>
                        </div>
                    </div>

                    {/* Button */}
                    <div
                        id="catalog-active-option-footer-description"
                        className="rounded-2xl shadow-lg p-5 mt-5 grid gap-4 bg-[#FFF]"
                    >
                        <div className="text-[#1E1E1E] font-semibold text-md tracking-wider capitalize">
                            Button
                        </div>

                        {/* Title Text Section */}
                        <div className="grid gap-2">
                            <span className="text-[#000] text-xs font-normal">Deskripsi Produk</span>

                            <input
                                type="text"
                                value={product.button_label}
                                onChange={(e) => setProduct({ ...product, button_label: e.target.value })}
                                className="input w-full bg-transparent border-[#3375F5] text-black"
                            />
                        </div>

                        {/* Color Text Section */}
                        <div className="grid gap-2">
                            <div className="text-[#000] text-xs font-normal">
                                Color Button
                            </div>
                            <div className="relative flex items-center gap-1">
                                <div
                                    className="w-12 h-11 rounded border border-gray-300 cursor-pointer"
                                    style={{
                                        background: product.button_color,
                                    }}
                                    onClick={() =>
                                        setShowButtonColor(!showButtonColor)
                                    }
                                />
                                {showButtonColor && (
                                    <div className="absolute top-11 z-[2]">
                                        <div
                                            className="fixed top-0 right-0 bottom-0 left-0"
                                            onClick={() => setShowButtonColor(false)}
                                        ></div>
                                        <ChromePicker
                                            color={product.button_color}
                                            onChange={(color) =>
                                                setProduct({ ...product, button_color: color.hex })
                                            }
                                            onChangeComplete={(color) =>
                                                setProduct({ ...product, button_color: color.hex })
                                            }
                                        />
                                    </div>
                                )}
                                <input
                                    type="text"
                                    value={product.button_color}
                                    onChange={(e) =>
                                        setProduct({ ...product, button_color: e.target.value })
                                    }
                                    className="input w-full bg-transparent border-[#3375F5] text-black"
                                />
                            </div>
                        </div>

                        {/* Link Section */}
                        <div className="grid gap-2">
                            {!showLink && (!product.button_url || product.button_url === "#") && (
                                <button
                                    className="flex items-center justify-center gap-2 h-10 w-full px-2 text-sm text-[#3375F5] transition-colors duration-150 border border-[#3375F5] rounded focus:shadow-outline hover:border-[#3375F5]/90 hover:opacity-90 uppercase"
                                    onClick={() => setShowLink(!showLink)}
                                >
                                    <CopyLinkIcon /> Tambah tautan
                                </button>
                            )}

                            {showLink && (
                                <>
                                    <span className="text-[#000] text-xs font-normal">URL</span>

                                    <div className="flex gap-2 items-center justify-between">
                                        <input
                                            type="text"
                                            value={product.button_url}
                                            onChange={(e) => setProduct({ ...product, button_url: e.target.value })}
                                            className="input w-full bg-transparent border-[#3375F5] text-black"
                                            placeholder="cth : https://web.whatsapp.com/"
                                        />
                                        <button
                                            type="button"
                                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 h-full"
                                            onClick={() => {
                                                if (!product.button_url || product.button_url === "#") return props.setAlertOption({ type: "error", message: "Tautan tidak boleh kosong" })
                                                setShowResultLink(!showResultLink)
                                                setShowLink(!showLink)
                                                return
                                            }}
                                        >
                                            Tambah
                                        </button>
                                    </div>
                                </>
                            )}

                            {showResultLink && (product.button_url || product.button_url !== "#") && (
                                <div className="p-5 grid gap-5 border border-[#3375F5] rounded">
                                    <div className="flex items-center justify-between">
                                        <a href={product.button_url} target="_blank" rel="noreferrer" className="text-[#3375F5]">{product.button_url}</a>
                                        <div className="flex items-center justify-between gap-3">
                                            <EditIcon color="#292D32" onClick={() => {
                                                setShowLink(!showLink);
                                                setShowResultLink(!showResultLink);
                                            }} className="cursor-pointer" />
                                            <TrashIcon color="#292D32" onClick={() => {
                                                setProduct({ ...product, button_url: null });
                                                setShowResultLink(!showResultLink)
                                            }} className="cursor-pointer" />
                                        </div>
                                    </div>
                                    <div className="flex jestify-between items-center gap-2">
                                        <span className="uppercase font-normal text-xs text-[#1A67FB]">buka di tab baru</span>
                                        <label className='flex cursor-pointer select-none items-center'>
                                            <div className='relative'>
                                                <input
                                                    type='checkbox'
                                                    checked={product.button_new_tab}
                                                    onChange={() => setProduct({ ...product, button_new_tab: !product.button_new_tab })}
                                                    className='sr-only'
                                                />
                                                <div className='h-3 w-8 rounded-full bg-gray-200 shadow-inner'></div>
                                                <div className='dot shadow-switch-1 absolute left-0 -top-1 h-5 w-5 rounded-full bg-gray-200 transition'></div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                </div>
            </div>
        </Fragment>
    );
}

export default CatalogProductDetail;