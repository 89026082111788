import React from "react";

function PaginationComponent(props) {
  const { currentPage = 1, pageSize = 10, total, onPageChange } = props;

  function renderPageBtn() {
    const pages = total > pageSize ? Math.ceil(total / pageSize) : 1;
    return Array.from({ length: pages }).map((_, index) => {
      const number = index + 1;
      const activeClass = currentPage === number ? ' bg-blue-100 text-blue-500' : '';
      return (
        <button key={index} className={`join-item btn${activeClass}`} onClick={() => onPageChange?.(number)}>
          {number}
        </button>
      )
    })
  }

  if (!total) return;

  return (
    <div className="text-center mt-10">
      <div class="join">
        {renderPageBtn()}
      </div>
    </div>
  );
}

export default PaginationComponent;