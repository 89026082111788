import { useEffect, useState } from "react";
import { AiOutlineEdit, AiOutlineDelete, AiOutlineClose } from "react-icons/ai";
import { getLevelList, createLevel, updateLevel, deleteLevel } from "../../app/service/api.service";
import TableComponent from "../../app/components/TableComponent";
import DialogComponent from "../../app/components/DialogComponent";
import AlertComponent from "../../app/components/Alert";

function Level() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [alertOption, setAlertOption] = useState({});
  const [levelList, setLevelList] = useState([]);
  const [levelId, setLevelId] = useState();
  const [levelName, setLevelName] = useState();
  const [actionType, setActionType] = useState();

  const columns = [
    { key: "id", title: "id" },
    { key: "level", title: "level" },
    {
      key: "action",
      title: "action",
      render: (_, item) => (
        <div className="flex justify-center items-center gap-3">
          <div
            data-tip="Edit Level"
            className={`
              tooltip tooltip-bottom py-2 px-2 border border-slate-300
              rounded-lg cursor-pointer hover:bg-slate-200 duration-200
            `}
            onClick={() => {
              window.levelform_crud.showModal();
              setActionType("update");
              setLevelId(item.id);
              setLevelName(item.level);
            }}
          >
            <AiOutlineEdit size={16} />
          </div>
          <div
            data-tip="Delete Level"
            className={`
              tooltip tooltip-bottom py-2 px-2 border border-slate-300
              rounded-lg cursor-pointer hover:bg-slate-200 duration-200
            `}
            onClick={() => {
              setOpenDialog(true);
              setLevelId(item.id);
            }}
          >
            <AiOutlineDelete size={16} />
          </div>
        </div>
      )
    }
  ]

  useEffect(() => {
    onGetListLevel();
  }, []);

  const onGetListLevel = async () => {
    try {
      setIsLoading(true);
      const response = await getLevelList();
      if (!response.success) throw response.message;

      setIsLoading(false);
      setLevelList(response.data);
    } catch (error) {
      setIsLoading(false);
      console.log("Error on onGetListWebPage: ", error);
    }
  }

  const onActionCRUD = (e) => {
    e?.preventDefault();
    switch (actionType) {
      case "create":
        onCreateLevel();
        break;
      case "update":
        onUpdateLevel()
        break;
    }
  }

  const onCreateLevel = async () => {
    try {
      setIsSubmitLoading(true);
      const response = await createLevel({ levelName });
      if (!response.success) throw response.message;

      setIsSubmitLoading(false);
      setAlertOption({ type: "success", message: response.message });
      window.levelform_crud.close();
      onGetListLevel();
    } catch (error) {
      setIsSubmitLoading(false);
      setAlertOption({ type: "error", message: error });
      console.log("Error on onGetListWebPage: ", error);
    }
  }

  const onUpdateLevel = async () => {
    try {
      setIsSubmitLoading(true);
      const response = await updateLevel({ id: levelId, levelName });
      if (!response.success) throw response.message;

      setIsSubmitLoading(false);
      setAlertOption({ type: "success", message: response.message });
      window.levelform_crud.close();
      onGetListLevel();
    } catch (error) {
      setIsSubmitLoading(false);
      setAlertOption({ type: "error", message: error });
      console.log("Error on onGetListWebPage: ", error);
    }
  }

  const onDeleteLevel = async () => {
    try {
      setIsSubmitLoading(true);
      const response = await deleteLevel({ id: levelId });
      if (!response.success) throw response.message;

      setIsSubmitLoading(false);
      setOpenDialog(false);
      setAlertOption({ type: "success", message: response.message });
      onGetListLevel();
    } catch (error) {
      setIsSubmitLoading(false);
      setAlertOption({ type: "error", message: error });
      console.log("Error on onGetListWebPage: ", error);
    }
  }

  return (
    <div className="p-6">
      <AlertComponent
        type={alertOption.type}
        message={alertOption.message}
        onClose={() => setAlertOption({ message: "" })}
      />
      <div className="text-black text-2xl font-bold">Level</div>
      <div className="w-full text-right mb-5">
        <button
          className="btn px-10 bg-[#5557DB] border-none text-sm text-white font-normal hover:bg-[#5557DB] hover:opacity-75"
          onClick={() => {
            setActionType("create");
            setLevelName('');
            window.levelform_crud.showModal();
          }}
        >
          Create
        </button>
      </div>
      <TableComponent
        loading={isLoading}
        columns={columns}
        dataSource={levelList}
      />
      <DialogComponent
        open={openDialog}
        isLoading={isSubmitLoading}
        onConfirm={onDeleteLevel}
        onClose={setOpenDialog}
      />
      <dialog id="levelform_crud" className="modal">
        <form className="modal-box">
          <div className="flex justify-end p-2 cursor-pointer mb-4" onClick={() => window.levelform_crud.close()}>
            <AiOutlineClose size={16} />
          </div>
          <input
            className="input w-full bg-transparent border-[#3375F5] text-black mb-5"
            placeholder="Input Level Name"
            value={levelName}
            onChange={(e) => setLevelName(e.target.value)}
          />
          <button
            className={`btn w-full capitalize bg-[#5557DB] border-none text-sm text-white font-normal hover:bg-[#5557DB] hover:opacity-75`}
            onClick={onActionCRUD}
          >
            {isSubmitLoading && <span className="loading loading-spinner loading-sm"></span>}
            {actionType}
          </button>
        </form>
      </dialog>
    </div>
  )
}

export default Level;