import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { authLogout } from "../app/service/api.service";
import CacheService from "../app/service/jwt.service";
import { periodLicenseValidator } from "../app/utils/utils";
import { useWebContext } from "../app/Provider";
import DialogComponent from "../app/components/DialogComponent";

function Header({ children }) {
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const [isLogoutLoading, setIsLogoutLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [showBanner, setShowbanner] = useState(true);
  const [restOfDay, setRestOfDay] = useState(false);
  const { store } = useWebContext();
  const userDetail = store.userDetail;

  useEffect(() => {
    // period license validation
    if (userDetail?.levelName?.toLowerCase() === "superadmin") return setShowbanner(false);

    const rangeOfDay = periodLicenseValidator(userDetail?.periodLicense);
    setRestOfDay(rangeOfDay || 0);
    setShowbanner(true);
  })

  useEffect(() => {
    window.addEventListener('click', onCloseDropdownClickOutside);
    return () => {
      window.removeEventListener('click', onCloseDropdownClickOutside);
    }
  }, [])

  const onCloseDropdownClickOutside = (e) => {
    if (!dropdownRef?.current) return;

    if (!dropdownRef.current.contains(e.target)) {
      dropdownRef.current.removeAttribute("open");
    }
  }

  const onLogout = async () => {
    try {
      setIsLogoutLoading(true);
      const response = await authLogout();
      setIsLogoutLoading(false);

      if (!response.success) return;

      CacheService.destroyToken();
      CacheService.removeLocalStorage("user");
      navigate("/login");
    } catch (error) {
      setIsLogoutLoading(false);
      console.log("Error onLogout:", error);
    }
  }

  function renderDropdown() {
    return (
      <details ref={dropdownRef} className="dropdown dropdown-bottom dropdown-end">
        <summary className="w-max flex items-center gap-3 cursor-pointer">
          <div>{userDetail?.name || ""}</div>
          {userDetail?.photoBase64 &&
            <img src={userDetail?.photoBase64} alt="user" className="w-10 h-10 rounded-full object-cover" />
          }
          {!userDetail?.photoBase64 &&
            <div className="flex items-center justify-center w-10 h-10 bg-slate-300 rounded-full text-sm text-white">
              {userDetail?.initial || ""}
            </div>
          }
        </summary>
        <ul className="dropdown-content z-[1] menu p-2 shadow bg-white rounded-xl w-52 border mt-3">
          <li onClick={() => setOpenDialog(true)}><a>Logout</a></li>
        </ul>
      </details>
    )
  }

  return (
    <div id="header-content" className="flex-1">
      <div className="h-[80px] flex justify-end items-center gap-3 p-6">
        {showBanner &&
          <div className="alert bg-yellow-500 border-none text-white shadow-sm !rounded-md !w-full py-2 flex justify-between">
            <span className="text-sm">
              Masa percobaan Anda akan berakhir dalam {restOfDay} hari.
              Upgrade akun Anda agar dapat kembali menikmati layanan Webtokoo
            </span>
            <button
              className={`
              btn btn-sm w-full md:w-fit bg-transparent border-white text-white
              px-10 hover:bg-yellow-600 hover:border-yellow-600 mt-4 lg:mt-0
            `}
              onClick={() => window.location.href = "mailto:support@awandigital.id"}
            >
              upgrade
            </button>
          </div>
        }
        {renderDropdown()}
      </div>
      <div className="h-[calc(100%-80px)]">
        {children}
      </div>
      <DialogComponent
        confirmText="Ingin Keluar Dari Akun ini?"
        confirmTextBtn="Keluar"
        open={openDialog}
        isLoading={isLogoutLoading}
        onConfirm={onLogout}
        onClose={setOpenDialog}
      />
    </div>
  )
}

export default Header;