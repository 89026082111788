import { useEffect, useState } from "react";
import {
  TrashIcon,
  ArrowRightIconDefault,
  NextRightIcon,
  DefaultImage,
  SearchIcon,
  EditIcon,
} from "../../../assets/icon/IndexSvgIcon";
import { ChunksArray } from "../../../app/utils/utils";

const listColumn = ["nama", "harga", "deskripsi", "spesifikasi"];
const limit = 5
function CatalogProduct(props) {
  const [column, setColumn] = useState([
    "nama",
    "harga",
    "deskripsi",
    "spesifikasi",
  ]);
  const [page, setPage] = useState(0);
  const [checked, setChecked] = useState([]);
  const [products, setProducts] = useState(props.products);
  const [showDrawer, setShowDrawer] = useState(false);

  useEffect(() => {
    if (JSON.stringify(props.products) !== JSON.stringify(products)) {
      setProducts(props.products);
    }
  }, [JSON.stringify(props.products)]);

  const onClickArrow = (type) => {
    if (type === "next") {
      const nextPage = page + 1;
      const maxPage = ChunksArray(products, limit).length;
      if (nextPage > maxPage - 1) return;

      return setPage(nextPage);
    }

    const prevPage = page - 1;
    if (prevPage < 0) return;

    return setPage(prevPage);
  };

  const setProductsToThem = (products) => {
    props.setTheme({ ...props.theme, product_list: products });
  };

  const renderContentTable = () => {
    const items = ChunksArray(products, limit);
    if (!items.length || !items.length > page) {
      return (
        <tr className="bg-white border-b hover:bg-gray-50 text-center">
          <td
            colSpan={column.length + 3}
            className="text-md font-medium px-6 py-4"
          >
            No Data!
          </td>
        </tr>
      );
    }

    return items[page].map((item, index) => {
      const image = !item.images.length ? (
        <DefaultImage className="w-full h-full object-cover overflow-hidden" />
      ) : (
        <img
          src={item.images[0]}
          alt={item.images[0]}
          className="w-full h-full object-cover overflow-hidden"
        />
      );
      const price = item.discount_price
        ? item.discount_price
        : item.normal_price;
      const isChecked = checked.includes(item.id);
      return (
        <tr
          className="bg-white border-b hover:bg-gray-50"
          key={`row-product-${index}`}
        >
          <td className="w-4 p-2">
            <div className="flex items-center">
              <input
                id="checkbox-table-search-1"
                type="checkbox"
                className="w-3 h-3 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                checked={isChecked}
                onChange={(e) => onCheck(e, item.id)}
              />
              <label htmlFor="checkbox-table-search-1" className="sr-only">
                checkbox
              </label>
            </div>
          </td>
          <th scope="row" className="px-6 py-4">
            <div className="w-24 h-24">{image}</div>
          </th>

          {column.map((col, index) => {
            switch (col) {
              case "nama":
                return (
                  <td
                    className="px-6 py-4"
                    key={`column-product_name-${index}`}
                  >
                    {item.product_name}
                  </td>
                );
              case "harga":
                return (
                  <td className="px-6 py-4" key={`column-price-${index}`}>
                    {price}
                  </td>
                );
              case "deskripsi":
                return (
                  <td className="px-6 py-4" key={`column-description-${index}`}>
                    {item.description}
                  </td>
                );
              case "spesifikasi":
                return (
                  <td
                    className="px-6 py-4"
                    key={`column-specification-${index}`}
                  >
                    {item.specification}
                  </td>
                );
              default:
                return;
            }
          })}
          <td className="px-6 py-4">
            <div
              className="cursor-pointer"
              onClick={() => {
                props.setSelectedProduct(item);
                return props.onChangePage("productDetail");
              }}
            >
              <ArrowRightIconDefault />
            </div>
          </td>
        </tr>
      );
    });
  };

  const onCheckAll = (e) => {
    if (!e.target.checked) return setChecked([]);
    const items = ChunksArray(products, limit);
    if (!items.length || !items.length > page) {
      return;
    }
    const ids = items[page].map((item) => item.id);

    return setChecked(ids);
  };

  const onCheck = (e, value) => {
    if (e.target.checked) return setChecked([...checked, value]);

    const newColumn = checked.filter((col) => col !== value);
    return setColumn(newColumn);
  };

  const onSearch = (e) => {
    const value = e.target.value;
    if (!value || value === "") {
      setProducts(props.products);
    } else {
      const filteredProduct = products.filter(
        (product) =>
          product.product_name &&
          product.product_name.toLowerCase().includes(value.toLowerCase())
      );
      setProducts(filteredProduct);
    }

    return setPage(0);
  };

  const onClickRemove = () => {
    setProductsToThem(products.filter((item) => !checked.includes(item.id)));
    setChecked([]);
    setPage(0)
  };

  const actionShowDrawer = (value) => {
    setShowDrawer(value);
  };

  const onCheckColumn = (e, value) => {
    if (e.target.checked) return setColumn([...column, value]);

    const newColumn = column.filter((col) => col !== value);
    return setColumn(newColumn);
  };

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg w-full">
      <div className="py-4 bg-white flex gap-5 items-center">
        <div className="relative w-full">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <SearchIcon />
          </div>
          <input
            type="text"
            id="search"
            className="border-y border-gray-300 text-gray-500 text-sm w-full ps-8 p-2 focus:outline-none"
            placeholder="Cari..."
            onChange={onSearch}
          />
        </div>
        <button type="button" onClick={() => actionShowDrawer(true)}>
          <EditIcon />
        </button>
        <button
          type="button"
          className="text-white bg-[#3375F5] hover:bg-[#3375F5]/90 focus:ring-4 focus:outline-none focus:ring-[#3375F5]/50 font-medium rounded text-sm px-3 py-2 text-center me-2 mb-2 w-1/4"
          onClick={() => props.onChangePage("productDetail")}
        >
          + Tambah Produk
        </button>
      </div>

      {showDrawer && (
        <div
          id="drawer-right-column"
          className="fixed top-0 right-0 z-40 h-screen p-4 overflow-y-auto bg-white w-1/5"
        >
          <h5
            id="drawer-right-label"
            className="inline-flex items-center mx-3 my-3 text-base font-semibold"
          >
            Edit Kolom
          </h5>
          <hr />
          <button
            type="button"
            className="bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 inline-flex items-center justify-center"
            onClick={() => actionShowDrawer(false)}
          >
            X <span className="sr-only">Close menu</span>
          </button>
          <div className="mx-3 my-6 text-sm text-gray-500">
            {listColumn.map((col) => {
              const isCheck = column.includes(col);
              return (
                <div
                  className="flex items-center gap-2 my-4 text-base"
                  key={`check-box-list-${col}`}
                >
                  <input
                    id={`checkbox-column-${col}`}
                    type="checkbox"
                    className="w-4 h-4 rounded"
                    checked={isCheck}
                    onChange={(e) => onCheckColumn(e, col)}
                  />
                  <label
                    htmlFor={`checkbox-column-${col}`}
                    className="text-m font-normal capitalize"
                  >
                    {col}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      )}

      <table className="w-full text-xs text-mdeft rtl:text-right text-[#292D32]">
        <thead className="font-light text-[#3375F5] uppercase bg-[#3375f5]/[.04]">
          <tr>
            <th scope="col" className="p-2">
              <div className="flex items-center">
                <input
                  id="checkbox-all-search"
                  type="checkbox"
                  className="w-3 h-3 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                  onChange={onCheckAll}
                  checked={checked.length}
                />
                <label htmlFor="checkbox-all-search" className="sr-only">
                  checkbox
                </label>
              </div>
            </th>
            <th scope="col" className="px-6 py-3"></th>
            {column.map((item, index) => (
              <th
                scope="col"
                className="px-6 py-3 uppercase"
                key={`header-column-${index}`}
              >
                {item}
              </th>
            ))}
            <th scope="col" className="px-6 py-3"></th>
          </tr>
        </thead>
        <tbody>{renderContentTable()}</tbody>
      </table>
      <nav className="flex items-center flex-column flex-wrap md:flex-row justify-between px-1 py-2 bg-[#3375f5]/[.04]">
        <span
          className="mb-4 md:mb-0 block w-full md:inline md:w-auto cursor-pointer"
          onClick={onClickRemove}
        >
          <TrashIcon />
        </span>
        <ul className="inline-flex items-center -space-x-px rtl:space-x-reverse text-sm h-8 font-normal text-[#3375F5]">
          <li>
            <span className="mx-3">
              {page * limit + 1} - {(page + 1) * limit} dari {products.length}
            </span>
          </li>
          <li>
            <div className="cursor-pointer" onClick={() => onClickArrow("prev")}>
              <NextRightIcon style={{ rotate: "180deg" }} />
            </div>
          </li>
          <li>
            <div className="cursor-pointer" onClick={() => onClickArrow("next")}>
              <NextRightIcon />
            </div>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default CatalogProduct;
