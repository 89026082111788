import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useWebContext } from "../../../app/Provider";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { BreadcrumbArrowIcon } from "../../../assets/icon/IndexSvgIcon";
import { updateMenuTree } from "../../../app/service/api.service";
import { v4 as uuidv4 } from "uuid";
import {
  getDetailMenuTree,
  generatedUniqueLink,
} from "../../../app/service/api.service";

import CatalogHome from "./CatalogHome";
import CatalogProduct from "./CatalogProduct";
import AlertComponent from "../../../app/components/Alert";
import CatalogProductDetail from "./CatalogProductDetail";
import LoadingPageComponent from "../../../app/components/LoadingPage";
import TemplateList from "../../../assets/json/catalog/homepageTheme.json";

const REACT_APP_ENV = process.env.REACT_APP_ENV;

const activeClass = "bg-[#F2A42C] text-white";

function Catalog() {
  const navigate = useNavigate();

  const { updateActiveMenuByNavigate } = useWebContext();
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  const [alertOption, setAlertOption] = useState({});
  const [isMenuLoading, setIsMenuLoading] = useState(false);
  const [catalog, setCatalog] = useState({ template: {...TemplateList.white} });
  const [activeTab, setActiveTab] = useState({ homepage: activeClass });
  const [theme, setTheme] = useState({...TemplateList.white});
  const [breadcrumb, setBreadcrumb] = useState(["home"]);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [generatedLoading, setGeneratedLoading] = useState(false);

  useEffect(() => {
    if (id) onGetDetailCatalog();
  }, [id]);

  const onGetDetailCatalog = async () => {
    try {
      setIsMenuLoading(true);
      const response = await getDetailMenuTree(id);
      if (!response.success) throw response.message;

      setIsMenuLoading(false);
      setCatalog(response.data);
      setTheme(response.data.template);

      const activeProductID = searchParams.get("product");
      const product = response.data.template.product_list.find((item) => item.id === activeProductID);

      if (product) {
        setSelectedProduct(product)
        onChangePage("productDetail")
        
        navigate("/design-page/catalog/62");
      }
    } catch (error) {
      setIsMenuLoading(false);
      console.log("Error on onGetDetailCatalog:", error);
    }
  };

  const onGeneratedLink = async (generateLink = "") => {
    try {
      const request = {
        category: "catalog",
        generateLink: typeof generateLink === "string" ? generateLink : "",
      };

      setGeneratedLoading(true);
      const response = await generatedUniqueLink(request);
      if (!response.success) throw response.message;

      setGeneratedLoading(false);
      setCatalog(response.data);
      setAlertOption({ type: "success", message: response.message });
    } catch (error) {
      setGeneratedLoading(false);
      setAlertOption({ type: "error", message: error });
      console.log("Error on onGeneratedLink:", error);
    }
  };

  const onChangePage = (key) => {
    const activeBreadcrumb =
      key === "productDetail" ? ["product", "product detail"] : [key];
    setActiveTab({ [key]: activeClass });
    setBreadcrumb(activeBreadcrumb);

    if(key === "product") {
      setSelectedProduct(null);
    }
  };

  const onForceUpdateProduct = async () => {
    try {
      setIsMenuLoading(true);
      const products = upsertProduct();
      const publishTheme = { ...theme, product_list: products }

      const response = await updateMenuTree({
        id: catalog.id,
        theme: publishTheme,
      });
      if (!response.success) throw response.message;

      setIsMenuLoading(false);
    } catch (error) {
      console.log("error on Force Update Product", error.message)
      setIsMenuLoading(false);
    }
  }

  const onSubmitConfig = async (isRedirect = true) => {
    try {
      setIsMenuLoading(true);
      
      const response = await updateMenuTree({
        id: catalog.id,
        theme: theme,
      });
      if (!response.success) throw response.message;

      setIsMenuLoading(false);
      if(isRedirect) {
        navigate("/active-page");
        updateActiveMenuByNavigate("active-page");
      }
    } catch (error) {
      setIsMenuLoading(false);
      setAlertOption({ type: "error", message: error });
      console.log("Error on onSubmitConfig:", error);
    }
  };

  const upsertProduct = () => {
    const product = selectedProduct;
    const products = theme.product_list;

    if (!product) return products;
    if (!product.id) product.id = uuidv4();

    const index = products.findIndex((item) => item.id === product.id);
    if (index === -1) {
      return [...theme.product_list, product]
    }
    products[index] = product;
    return products
  }

  const onSubmitProduct = () => {
    setIsMenuLoading(true);

    const products = upsertProduct();

    setTheme({ ...theme, product_list: products });
    setSelectedProduct(null);
    setIsMenuLoading(false);
    return onChangePage("product");
  };

  const onClickPreview = async (type) => {
    const uniqueLink = catalog.unique_link;
    if (uniqueLink === "xxxxxxxxxxxxxxxx" || !uniqueLink) {
      return setAlertOption({
        type: "error",
        message: "Generate your link first",
      });
    }
    
    const baseURL = REACT_APP_ENV === "production" ? "https://link.awandigital.id" : process.env.REACT_APP_API_DOMAIN;
    
    await onForceUpdateProduct();

    const url =
      type === "home"
        ? `${baseURL}/${uniqueLink}?preview=true`
        : `${baseURL}/${uniqueLink}/detail/${selectedProduct.id}?preview=true`;
    window.location.replace(url);
  };

  return (
    <div className="h-full px-6 pb-6">
      <AlertComponent
        type={alertOption.type}
        message={alertOption.message}
        onClose={() => setAlertOption({ message: "", type: alertOption.type })}
      />
      <LoadingPageComponent isLoading={isMenuLoading} />
      <div
        id="back-btn"
        className="flex items-center gap-2 cursor-pointer"
        onClick={() => navigate("/design-page")}
      >
        <AiOutlineArrowLeft size={20} className="text-blue-500" />
        <div className="ml-2 text-black text-2xl font-bold">Catalog</div>
      </div>

      <div
        id="catalog-menu"
        className="flex-1 p-2 overflow-x-hidden overflow-y-auto"
      >
        <div className="flex items-center justify- w-full">
          <div className="flex w-2/3 items-center rounded-full shadow-lg p-1 text-center">
            <div
              className={`basis-1/2 ${
                activeTab.homepage || "bg-[#F9FAFB] text-[#667085]"
              } rounded-full p-2 cursor-pointer`}
              onClick={() => onChangePage("homepage")}
            >
              Homepage
            </div>
            <div
              className={`basis-1/2 ${
                activeTab.product ||
                activeTab.productDetail ||
                "bg-[#F9FAFB] text-[#667085]"
              } rounded-full p-2 cursor-pointer`}
              onClick={() => onChangePage("product")}
            >
              Produk
            </div>
          </div>

          {(activeTab.homepage || activeTab.product) && (
            <div className="flex-1 w-1/2 items-center text-right">
              <button
                className="h-10 w-1/2 px-2 m-1 text-sm text-white bg-[#3375F5] rounded focus:shadow-outline hover:bg-[#3375F5] hover:opacity-90 uppercase"
                onClick={onSubmitConfig}
              >
                Published
              </button>
            </div>
          )}
        </div>

        <nav className="flex mt-3" aria-label="Breadcrumb">
          <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
            <li className="inline-flex items-center">
              <div
                className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 cursor-pointer"
                onClick={() => onChangePage("homepage")}
              >
                Catalog
              </div>
            </li>
            {breadcrumb.map((item, index) => {
              const label =
                breadcrumb.length - 1 === index ? (
                  <span className="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400 capitalize">
                    {item}
                  </span>
                ) : (
                  <div
                    className="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 capitalize cursor-pointer"
                    onClick={() => onChangePage("product")}
                  >
                    {item}
                  </div>
                );
              return (
                <li key={`breadcrumb-level-${index}`}>
                  <div className="flex items-center">
                    <BreadcrumbArrowIcon />
                    {label}
                  </div>
                </li>
              );
            })}
          </ol>
        </nav>
      </div>

      <div
        id="catalog-content"
        className="h-[calc(100%-100px)] flex overflow-x-hidden overflow-y-auto"
      >
        {activeTab.homepage && (
          <CatalogHome
            uniqueLink={catalog.unique_link}
            generatedLoading={generatedLoading}
            listTheme={TemplateList}
            theme={theme}
            setTheme={setTheme}
            onClickPreview={onClickPreview}
            onGeneratedLink={onGeneratedLink}
            setBreadcrumb={setBreadcrumb}
          />
        )}

        {activeTab.product && (
          <CatalogProduct
            theme={theme}
            products={theme.product_list}
            setTheme={setTheme}
            onChangePage={onChangePage}
            setSelectedProduct={setSelectedProduct}
          />
        )}

        {activeTab.productDetail && (
          <CatalogProductDetail
            product={selectedProduct}
            theme={theme}
            setAlertOption={setAlertOption}
            setSelectedProduct={setSelectedProduct}
            onSubmitProduct={onSubmitProduct}
            onClickPreview={onClickPreview}
          />
        )}
      </div>
    </div>
  );
}

export default Catalog;
