import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useWebContext } from "../../app/Provider";
import { updatePasswordSSO, resetPassword, authLogout } from "../../app/service/api.service";
import AlertComponent from "../../app/components/Alert";
import CacheService from "../../app/service/jwt.service";

function ResetPassword() {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [alertOption, setAlertOption] = useState({});
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { store } = useWebContext();
  const userDetail = store.userDetail;
  const { register, trigger, formState: { errors }, getValues, handleSubmit } = useForm();

  const onLogout = async () => {
    try {
      const response = await authLogout();
      if (!response.success) return response.message;

      CacheService.destroyToken();
      CacheService.removeLocalStorage("user");
      navigate("/login");
    } catch (error) {
      console.log("Error onLogout:", error);
    }
  }

  const onResetPassword = async (data) => {
    try {
      if (isLoading) return;

      const email = userDetail?.email || "";
      const currentPassword = data?.currentPassword || "";
      const password = data?.password || "";
      const confirmPassword = data?.confirmPassword || "";

      const requestSSO = {
        current_password: currentPassword,
        new_password: password,
        new_password_confirmation: confirmPassword,
      }

      setLoading(true);
      await updatePasswordSSO(requestSSO);

      const response = await resetPassword({ email, password });
      if (!response.success) throw response.message;

      setLoading(false);
      setAlertOption({ type: "success", message: response.message });
      onLogout();
    } catch (error) {
      setLoading(false);
      setAlertOption({ type: "error", message: error });
      console.log("Error onResetPassword:", error);
    }
  };

  return (
    <div className="h-full p-6">
      <AlertComponent
        type={alertOption.type}
        message={alertOption.message}
        onClose={() => setAlertOption({ message: "" })}
      />
      <div className="mb-5 text-black text-2xl font-bold">Reset Password</div>
      <form onSubmit={handleSubmit(onResetPassword)}>
        <div className="flex mb-4">
          <label className="flex items-center w-1/4 text-sm">Current Password</label>
          <div className="relative w-1/3">
            <input
              type={showCurrentPassword ? "text" : "password"}
              placeholder="Input Current Password"
              className="input w-full bg-transparent border-[#3375F5] text-black"
              {...register("currentPassword", { required: "Current password is required!" })}
            />
            {showCurrentPassword ?
              <FaEye className="absolute top-4 right-4 w-4 h-4 text-[#5557DB] cursor-pointer" onClick={() => setShowCurrentPassword(false)} />
              :
              <FaEyeSlash className="absolute top-4 right-4 w-4 h-4 text-[#5557DB] cursor-pointer" onClick={() => setShowCurrentPassword(true)} />
            }
            {errors.currentPassword && <div className="text-red-500 py-1">{errors.currentPassword?.message}</div>}
          </div>
        </div>
        <div className="flex mb-4">
          <label className="flex items-center w-1/4 text-sm">New Password</label>
          <div className="relative w-1/3">
            <input
              type={showNewPassword ? "text" : "password"}
              placeholder="Input New Password"
              className="input w-full bg-transparent border-[#3375F5] text-black"
              {...register("password", {
                required: "New password is required!",
                validate: (val) => val === getValues("confirmPassword") || "New password do not match with Confirm password!",
                onChange: () => { trigger("password"); trigger("confirmPassword"); }
              })}
            />
            {showNewPassword ?
              <FaEye className="absolute top-4 right-4 w-4 h-4 text-[#5557DB] cursor-pointer" onClick={() => setShowNewPassword(false)} />
              :
              <FaEyeSlash className="absolute top-4 right-4 w-4 h-4 text-[#5557DB] cursor-pointer" onClick={() => setShowNewPassword(true)} />
            }
            {errors.password && <div className="text-red-500 py-1">{errors.password?.message}</div>}
          </div>
        </div>
        <div className="flex mb-4">
          <label className="flex items-center w-1/4 text-sm">Confirm Password</label>
          <div className="relative w-1/3">
            <input
              type={showConfirmPassword ? "text" : "password"}
              placeholder="Input Confirm Password"
              className="input w-full bg-transparent border-[#3375F5] text-black"
              {...register("confirmPassword", {
                required: "Confirm password is required!",
                validate: (val) => val === getValues("password") || "Confirm password do not match with New password!",
                onChange: () => { trigger("password"); trigger("confirmPassword"); }
              })}
            />
            {showConfirmPassword ?
              <FaEye className="absolute top-4 right-4 w-4 h-4 text-[#5557DB] cursor-pointer" onClick={() => setShowConfirmPassword(false)} />
              :
              <FaEyeSlash className="absolute top-4 right-4 w-4 h-4 text-[#5557DB] cursor-pointer" onClick={() => setShowConfirmPassword(true)} />
            }
            {errors.confirmPassword && <div className="text-red-500 py-1">{errors.confirmPassword?.message}</div>}
          </div>
        </div>
        <div className="flex">
          <div className="w-1/4"></div>
          <div className="w-1/6">
            <button
              type="submit"
              className={`
                btn w-full capitalize bg-[#5557DB] border-none text-sm text-white
                font-normal col-span-12 hover:bg-[#5557DB] hover:opacity-75
              `}
            >
              {isLoading && <span className="loading loading-spinner loading-sm"></span>}
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ResetPassword;