import { useEffect, useState } from "react";
import moment from "moment";
import { useWebContext } from "../../app/Provider";
import { getWebPageList, getSurveyReports } from "../../app/service/api.service";
import TableComponent from "../../app/components/TableComponent";
import DialogComponent from "../../app/components/DialogComponent";

function SurveyReport() {
  const [isLoading, setIsLoading] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [webPageOption, setWebPageOption] = useState([]);
  const [webPageId, setWebPageId] = useState(1);
  const [surveyType, setSurveyType] = useState(null);
  const formatInputDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const [dateFrom, setDateFrom] = useState(formatInputDate(new Date()));
  const [dateTo, setDateTo] = useState(formatInputDate(new Date()));
  const { store } = useWebContext();
  const userDetail = store.userDetail;

  const csatColumns = [
    { key: "id", title: "id", render: (val, item, index) => index + 1 },
    {
      key: "question",
      title: "Pertanyaan",
      align: "left",
      render: (item) => item.text
    },
    {
      key: "response_values",
      title: "Jumlah Responden",
      render: (item) => item?.reduce((total, item) => total + item.count, 0)
    },
    {
      key: "response_values",
      title: "Skor Rata-rata",
      render: (item) => {
        const count = item?.reduce((total, item) => total + item.count, 0)
        const total = item?.reduce((total, item) => total + (item.value * item.count), 0)
        return (total / count).toFixed(2)
      }
    },
  ]

  const npsColumns = [
    { key: "id", title: "id", render: (val, item, index) => index + 1 },
    {
      key: "question",
      title: "Pertanyaan",
      align: "left",
      width: '350px',
      render: (item) => item.text
    },
    {
      key: "response_values",
      title: "Jumlah Responden",
      render: (item) => item?.reduce((total, item) => total + item.count, 0)
    },
    {
      key: "response_values",
      title: "Detractor",
      subColumns: [
        {
          key: "detractor_respondent",
          title: "Responden",
          render: (item) => item?.reduce((total, item) => total + (item.value >= 0 && item.value <= 6 ? item.count : 0), 0)
        },
        {
          key: "promoter_percentage",
          title: "%",
          render: (item) => item?.reduce((total, item) => total + (item.value >= 0 && item.value <= 6 ? item.count : 0), 0),
          render: (item, _, index) => {
            const totalCount = item.reduce((total, item) => total + item.count, 0);
            const countInRange = item.reduce((total, item) => total + (item.value >= 0 && item.value <= 6 ? item.count : 0), 0);
            const percentage = totalCount > 0 ? (countInRange / totalCount) * 100 : 0;
            return percentage % 1 === 0 ? `${percentage}%` : `${percentage.toFixed(2)}%`;
          }
        }
      ],
    },
    {
      key: "response_values",
      title: "Passive",
      subColumns: [
        {
          key: "passive_respondent",
          title: "Responden",
          render: (item) => item?.reduce((total, item) => total + (item.value >= 7 && item.value <= 8 ? item.count : 0), 0)
        },
        {
          key: "promoter_percentage",
          title: "%",
          render: (item) => item?.reduce((total, item) => total + (item.value >= 7 && item.value <= 8 ? item.count : 0), 0),
          render: (item) => {
            const totalCount = item.reduce((total, i) => total + i.count, 0);
            const countInRange = item.reduce((total, item) => total + (item.value >= 7 && item.value <= 8 ? item.count : 0), 0);
            const percentage = totalCount > 0 ? (countInRange / totalCount) * 100 : 0;
            return percentage % 1 === 0 ? `${percentage}%` : `${percentage.toFixed(2)}%`;
          }
        }
      ],
    },
    {
      key: "response_values",
      title: "Promoter",
      subColumns: [
        {
          key: "promoter_respondent",
          title: "Responden",
          render: (item) => item?.reduce((total, item) => total + (item.value >= 9 && item.value <= 10 ? item.count : 0), 0)
        },
        {
          key: "promoter_percentage",
          title: "%",
          render: (item) => item?.reduce((total, item) => total + (item.value >= 9 && item.value <= 10 ? item.count : 0), 0),
          render: (item) => {
            const totalCount = item.reduce((total, i) => total + i.count, 0);
            const countInRange = item.reduce((total, item) => total + (item.value >= 9 && item.value <= 10 ? item.count : 0), 0);
            const percentage = totalCount > 0 ? (countInRange / totalCount) * 100 : 0;
            return percentage % 1 === 0 ? `${percentage}%` : `${percentage.toFixed(2)}%`;
          }
        }
      ],
    },
    {
      key: "response_values",
      title: "Skor Rata-rata",
      render: (item) => {
        const promoterTotalcount = item.reduce((total, i) => total + i.count, 0);
        const promoterCountinrange = item.reduce((total, item) => total + (item.value >= 9 && item.value <= 10 ? item.count : 0), 0);
        const promoterPercentage = (promoterTotalcount > 0 ? (promoterCountinrange / promoterTotalcount) * 100 : 0);
        const promoter = promoterPercentage % 1 === 0 ? promoterPercentage : promoterPercentage.toFixed(2)

        const detractorTotalcount = item.reduce((total, item) => total + item.count, 0);
        const detractorCountinrange = item.reduce((total, item) => total + (item.value >= 0 && item.value <= 6 ? item.count : 0), 0);
        const detractorPercentage = (detractorTotalcount > 0 ? (detractorCountinrange / detractorTotalcount) * 100 : 0);
        const detractor = detractorPercentage % 1 === 0 ? detractorPercentage : detractorPercentage.toFixed(2)

        return promoter - detractor;
      }
    },
  ]

  // useEffect(() => {
  //   console.log(surveyType)
  // }, [surveyType]);

  useEffect(() => {
    onGetWebPageOptions();
  }, []);

  useEffect(() => {
    onGetReports();
  }, [dateFrom, dateTo, webPageId]);

  const onGetWebPageOptions = async () => {
    try {
      const response = await getWebPageList();
      if (!response.success) throw response.message;

      const surveyWebPageOption = response.data
        .filter(item => item.category === 'survey')
        .map(item => ({
          id: item.id,
          title: JSON.parse(item.template).title,
          surveyType: JSON.parse(item.template).surveyType
        }));

      setWebPageOption(surveyWebPageOption);
    } catch (error) {
      setIsLoading(false);
      console.log("Error on onGetWebPageOptions: ", error);
    }
  }

  const onGetReports = async () => {
    try {
      const request = {
        userId: userDetail.userId,
        webPageId: webPageId,
        dateFrom: dateFrom,
        dateTo: dateTo
      }

      setIsLoading(true);
      const response = await getSurveyReports(request);
      if (!response.success) throw response.message;

      setIsLoading(false);
      setReportData(response.data);
    } catch (error) {
      setIsLoading(false);
      console.log("Error on onGetReports: ", error);
    }
  }

  return (
    <div className="p-6">
      <div className="mb-5 text-black text-2xl font-bold">Report Survey</div>
      <div>
        <div className="flex items-center gap-3 mb-5">
          <div className="flex items-center gap-4 me-6">
            <div className="text-sm font-bold">Tanggal Survey :</div>
            <input
              type="date"
              defaultValue={dateFrom}
              onChange={(e) => setDateFrom(e.target.value)}
              className="w-36 bg-transparent border-gray-300 text-sm" />
            <div className="text-sm font-bold">Sampai</div>
            <input
              type="date"
              defaultValue={dateTo}
              onChange={(e) => setDateTo(e.target.value)}
              className="w-36 bg-transparent border-gray-300 text-sm" />
          </div>
          <div className="text-sm font-bold">Webpage Survey :</div>
          <select className="select select-ghost bg-transparent border-gray-300 text-sm" value={webPageId} onChange={(e) => {
            setWebPageId(e.target.value)
            setSurveyType(webPageOption.find((item) => item.id == e.target.value)?.surveyType)
          }}>
            <option value="" hidden>Choose Survey Webpage</option>
            {webPageOption.map((page, index) => (
              <option key={index} value={page.id}>{page.title}</option>
            ))}
          </select>
        </div>
      </div>
      <TableComponent
        loading={isLoading}
        columns={surveyType === 'nps' ? npsColumns : csatColumns}
        dataSource={reportData}
      />
      <DialogComponent open={openDialog} onClose={setOpenDialog} />
    </div>
  )
}

export default SurveyReport;